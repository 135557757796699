import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardImg,
  Button,
  Collapse,
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { Breadcrumb } from 'antd';
import { useState,useEffect } from 'react';
import {fetchFirebaseConfig} from '../../firebase';
import FinancialInfo from './financialInfo';
import UserDataCharts from './userDataCharts';
import { useParams,Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Widget from '../../components/Widget/Widget';

export default function ProfilePage() {
  const history = useHistory();
  const { uid } = useParams();
    const tabContent = {
    financial: <FinancialInfo uid={uid} />,
    charts: <UserDataCharts uid={uid} />,
  };
   // Get the UID from the URL
  const [activeTab, setActiveTab] = useState('financial');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [profile, setProfile] = useState(null);
  const [address, setAddress] = useState('');
  const [ref,setRef] = useState();
  const [role,setRole]= useState();
  const [joined,setJoined] = useState();
  const [religion, setReligion] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [id, setUid] = useState('');
  const [loanCommitee, setLoanCommitee] = useState('');
  const [showMore, setShowMore] = useState(false);
  const { db } = fetchFirebaseConfig();

    const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore); // Toggle the showMore state
  };

    useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!uid) {
          console.error('UID is undefined');
          return;
        }

        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setName(userData.display_name || '');
          setProfile(userData.photo_url || ''); // Set profile picture URL
          setEmail(userData.email || '');
          setAddress(userData.location || '');
          setReligion(userData.religion || '');
          setGender(userData.gender || '');
          setPhone(userData.phone_number || '');
          setLoanCommitee(userData.loanCommitee || '');
          setRef(userData.refNo || '');
          setRole(userData.position || '');
          setJoined(userData.created_time || '')
          setUid(userData.uid || '')
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [db, uid]);

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  return (
    <section>
      <Container className="py-5">
        <Row>
          <Col lg="4">
          <Card className="mb-4">
            <CardBody className="text-center">
              {profile ? (
                <CardImg
                  src={profile}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '70px' }}
                />
              ) : (
                <i className="fa fa-user-circle" style={{ fontSize: '5em', color: '#c0c0c0' }}></i>
              )}
              <CardText className=" mb-1 mt-2" style={{fontWeight:'bold'}}><h4>{name}</h4></CardText>
              <CardText className="text-muted mb-4"><h6><i className="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp;{role}</h6></CardText>
              <div className="d-flex justify-content-center mb-2">
                <Button color="primary" size='sm' outline onClick={() => history.push(`/app/members/members-list/edit-user/${id}`)}><i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Edit</Button>
                {/* <Button outline className="ms-1">Message</Button> */}
              </div>
            </CardBody>
            </Card>
              <Card className="mb-4 mb-lg-0">
                <CardBody className="p-0 d-flex flex-column justify-content-between">
                  <ul className="list-group list-group-flush rounded-3">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight:'bold' }}>Email</span>
                      <span style={{ color: '#333333' }}>{email}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight:'bold' }}>Mobile</span>
                      <span style={{ color: '#333333' }}>{phone}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight:'bold' }}>Address</span>
                      <span style={{ color: '#333333' }}>{address}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                        <span style={{ color: '#333333', fontWeight:'bold' }}>Joined</span>
                        <span style={{ color: '#333333' }}>{renderDateRequested(joined)}</span>
                    </li>
                  </ul>

                  {/* Collapse starts here */}
                  <Collapse isOpen={showMore}>
                  <h6 className="text-center mb-3"><span style={{fontWeight:'bold',color:"#aba9a9"}}><i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;More Details</span></h6>
                    <ul className="list-group list-group-flush rounded-3">
                      <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                        <span style={{ color: '#333333', fontWeight:'bold' }}>Reference</span>
                        <span style={{ color: '#333333' }}>{ref}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                        <span style={{ color: '#333333', fontWeight:'bold' }}>Commitee</span>
                        <span style={{ color: '#333333' }}>{loanCommitee ? "Yes" : "No"}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                        <span style={{ color: '#333333', fontWeight:'bold' }}>Gender</span>
                        <span style={{ color: '#333333' }}>{gender}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                        <span style={{ color: '#333333', fontWeight:'bold' }}>Religion</span>
                        <span style={{ color: '#333333' }}>{religion}</span>
                      </li>
                    </ul>
                  </Collapse>
                  {/* Collapse ends here */}

                  {/* Button for toggle */}
                  <Button onClick={toggleShowMore} className="align-self-center mt-3" style={{backgroundColor:'#dcdce0',border:'none' }} size='sm'>
                    {showMore ? <span><i class="fa fa-minus" aria-hidden="true"></i>&nbsp;Show less</span> : <span><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Show more</span>}
                  </Button>
                </CardBody>
              </Card>
            </Col>
              <Col lg="8">
              <Row>
              <Col md="12">
                 <Card className="mb-4 mb-md-0">
                <CardBody>
                <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 'financial' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('financial')}
                    style={activeTab === 'financial' ? { backgroundColor: '#3754a5', color: 'white', borderColor: '#3754a5' } : {}}
                  >
                    <span style={{ fontSize: '12.5px', fontWeight: 'bold' }}>Financial Details</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'charts' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('charts')}
                    style={activeTab === 'charts' ? { backgroundColor: '#3754a5', color: 'white', borderColor: '#3754a5' } : {}}
                  >
                    <span style={{ fontSize: '12.5px', fontWeight: 'bold' }}>Charts</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="financial">
                  <Widget>
                    <div className="align-items-center">{tabContent.financial}</div>
                  </Widget>
                </TabPane>
                <TabPane tabId="charts">
                  <Widget>
                    <div className="align-items-center">{tabContent.charts}</div>
                  </Widget>
                </TabPane>
              </TabContent>      
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Col>
        </Row>
      </Container>
    </section>
  );
}


















