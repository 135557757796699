import React, { useEffect, useState, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { FormGroup, Input, Label,Button,ButtonGroup} from 'reactstrap';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';

const AreaPayDis = () => {
  const [loanData, setLoanData] = useState([]);
  const [maxAmount, setMaxAmount] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const { db } = fetchFirebaseConfig();
      const transactionCollection = collection(db, 'allTransaction2');
      const paymentCollection = collection(db, 'PaymentData');

      const transactionQuerySnapshot = await getDocs(query(transactionCollection, where('transactionType', '==', 'Saving Deposit')));
      const paymentQuerySnapshot = await getDocs(query(paymentCollection, where('transaction', '==', 'Savings Payout')));

      const processedData = {};
      let maxSavingsAmount = 0;

      const allMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      allMonths.forEach(month => {
        processedData[month] = { month, savingsPayments: 0, savingsDisbursements: 0 };
      });

      transactionQuerySnapshot.forEach(doc => {
        const { transactionAmount, transactionDate } = doc.data();
        const date = transactionDate.toDate();
        const year = date.getFullYear().toString();
        const month = date.toLocaleString('default', { month: 'long' });

        if (year === selectedYear) {
          processedData[month].savingsPayments += parseFloat(transactionAmount);
          if (parseFloat(transactionAmount) > maxSavingsAmount) {
            maxSavingsAmount = parseFloat(transactionAmount);
          }
        }
      });

      paymentQuerySnapshot.forEach(doc => {
        const { Amount, PayoutDate } = doc.data();
        const date = PayoutDate.toDate();
        const year = date.getFullYear().toString();
        const month = date.toLocaleString('default', { month: 'long' });

        if (year === selectedYear) {
          processedData[month].savingsDisbursements += parseFloat(Amount);
          if (parseFloat(Amount) > maxSavingsAmount) {
            maxSavingsAmount = parseFloat(Amount);
          }
        }
      });

      setMaxAmount(maxSavingsAmount);
      const dataArray = Object.values(processedData);
      setLoanData(dataArray);
      setLoading(false);
    };

    fetchData();
  }, [selectedYear]);

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const formatTooltip = (value, name, props) => {
    return [value.toLocaleString(), name];
  };

  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then(canvas => {
        canvas.toBlob(blob => {
          FileSaver.saveAs(blob, 'savings_area.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(loanData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'savings_area.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'savings_area.svg');
    }
  };

  return (
    <div>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <FormGroup className="me-3">
          <Label for="yearSelect">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <span style={{ fontSize: '13px', marginLeft: '3px' }}> Year</span>
          </Label>
          <Input
            type="select"
            id="yearSelect"
            onChange={handleChangeYear}
            value={selectedYear}
            size="sm"
            style={{ fontSize: '12px' }}
          >
            <option value="">Select Year</option>
            {generateYearOptions(2000, new Date().getFullYear() + 10).map(year => (
              <option key={year} value={year.toString()}>{year}</option>
            ))}
          </Input>
        </FormGroup>
        <ButtonGroup size='sm'>
          <Button color="success" size='sm' onClick={handleDownloadImage}>
              PNG
          </Button>
          <Button color="success" size='sm' onClick={handleDownloadSVG}>
              SVG
          </Button>
          <Button color="success" size='sm' onClick={handleDownloadCSV}>
              CSV
          </Button>
        </ButtonGroup>
      </div>
      {!loading && (
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={loanData}
            margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
            ref={chartRef}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tick={{ fontSize: 12 }} />
            <YAxis domain={[0, maxAmount]} tickFormatter={formatYAxisTick} tick={{ fontSize: 12 }} />
            <Tooltip formatter={formatTooltip} wrapperStyle={{ fontSize: '10px' }} contentStyle={{ fontSize: '13px' }} />
            <Area type="monotone" dataKey="savingsPayments" stackId="1" stroke="#ffc658" fill="#ffc658" name="Savings Payments" />
            <Area type="monotone" dataKey="savingsDisbursements" stackId="2" stroke="#82ca9d" fill="#82ca9d" name="Savings Disbursements" />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );  
};

export default AreaPayDis;
