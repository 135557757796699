import React, { useRef, useState,useEffect } from 'react';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input as InputData, Breadcrumb, BreadcrumbItem,Form,Col,Row,Table,Collapse } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { collection, getDocs } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';

const ProfitLoss = () => {
  const { db } = fetchFirebaseConfig();
  const [incomeData, setIncomeData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [netIncome, setNetIncome] = useState(0);
  const [grossProfitLoss, setGrossProfitLoss] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [visible, setVisible] = useState(false);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const componentRef = useRef(null);

  const filterLatestDocuments = (data) => {
    const filteredData = data.reduce((accumulator, currentValue) => {
      const existingIndex = accumulator.findIndex(
        (item) => item.account_name === currentValue.account_name && item.account_type === currentValue.account_type
      );

      if (existingIndex === -1) {
        accumulator.push(currentValue);
      } else {
        const existingDate = new Date(accumulator[existingIndex].date.replace(/,/g, '')); // Remove commas
        const currentDate = new Date(currentValue.date.replace(/,/g, ''));

        if (currentDate > existingDate) {
          accumulator[existingIndex] = currentValue;
        }
      }

      return accumulator;
    }, []);

    return filteredData;
  };

  const fetchData = async () => {
    try {
      // Fetch income data
      const incomeCollection = collection(db, 'IncomeData');
      const incomeSnapshot = await getDocs(incomeCollection);
      let incomeData = incomeSnapshot.docs.map((doc) => doc.data());

      // Filter income data based on start and end dates
      if (startDate && endDate) {
        incomeData = incomeData.filter((item) => {
          const date = new Date(item.date.replace(/,/g, ''));
          return date >= new Date(startDate) && date <= new Date(endDate);
        });
      } else if (startDate) {
        incomeData = incomeData.filter((item) => {
          const date = new Date(item.date.replace(/,/g, ''));
          return date >= new Date(startDate);
        });
      } else if (endDate) {
        incomeData = incomeData.filter((item) => {
          const date = new Date(item.date.replace(/,/g, ''));
          return date <= new Date(endDate);
        });
      }

      const filteredIncomeData = filterLatestDocuments(incomeData);
      setIncomeData(filteredIncomeData);

      // Fetch expenses data
      const expensesCollection = collection(db, 'ExpensesData');
      const expensesSnapshot = await getDocs(expensesCollection);
      let expensesData = expensesSnapshot.docs.map((doc) => doc.data());

      // Filter expenses data based on start and end dates
      if (startDate && endDate) {
        expensesData = expensesData.filter((item) => {
          const date = new Date(item.date.replace(/,/g, ''));
          return date >= new Date(startDate) && date <= new Date(endDate);
        });
      } else if (startDate) {
        expensesData = expensesData.filter((item) => {
          const date = new Date(item.date.replace(/,/g, ''));
          return date >= new Date(startDate);
        });
      } else if (endDate) {
        expensesData = expensesData.filter((item) => {
          const date = new Date(item.date.replace(/,/g, ''));
          return date <= new Date(endDate);
        });
      }

      const filteredExpensesData = filterLatestDocuments(expensesData);
      setExpensesData(filteredExpensesData);
    } catch (error) {
      console.error('Error fetching data from Firebase:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]); // Fetch data when start or end date changes

  useEffect(() => {
    const totalIncome = incomeData.reduce((total, item) => total + item.balance, 0);
    const totalExpenses = expensesData.reduce((total, item) => total + item.balance, 0);  
    // Calculate gross profit/loss
    const grossProfitLoss = totalIncome - totalExpenses;

     // Calculate tax amount
     const taxAmount = (grossProfitLoss * taxPercentage) / 100;

     //calcate netIncome
     const netIncome = grossProfitLoss-taxAmount;

    setTotalIncome(totalIncome);
    setTotalExpenses(totalExpenses);
    setNetIncome(netIncome);
    setGrossProfitLoss(grossProfitLoss);
    setTaxAmount(taxAmount);

  }, [incomeData, expensesData, taxPercentage]);

  const formatValue = (value) => {
    return value !== undefined && value !== 0 ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '--';
  };

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString('default', { month: 'short' });
  const year = String(currentDate.getFullYear()).slice(2); // Get last two digits of the year
  
  // Function to add ordinal suffix to the day
  const addOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
  
  const formattedDate = `${addOrdinalSuffix(day)} ${month} ${year}`;

  const toggleCollapse = () => {
    setVisible(!visible);
  };

  return(
    <div>
    <Breadcrumb>
      <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
      <BreadcrumbItem active>Profit & Loss</BreadcrumbItem>
    </Breadcrumb>
    <h4 className="mb-lg">Profit & Loss</h4>
    <Widget
      title={<h7><span className="fw-semi-bold">Data Range</span></h7>}>
                <Row  style={{marginTop:'15px'}}>
                <Col md={6}>
                  <FormGroup style={{ marginBottom: '10px' }}>
                  <label><span style={{fontSize:'14px'}}>Start Date:</span></label> 
                    <InputData
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{ marginRight: '10px' }}
                      size='sm'
                    />
                  </FormGroup>
                  </Col>
                   <Col md={6}>
                   <FormGroup style={{ marginBottom: '10px' }}>
                     <label><span style={{fontSize:'14px'}}>End Date:</span></label> 
                    <InputData
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      size='sm'
                    />
                  </FormGroup>
                  </Col>
                  </Row>
              <div className="mb-1">
              <Widget>
              <>
                <Click color="link" size='sm' style={{ marginBottom: '15px',border:'none'}} onClick={toggleCollapse}>
                  Set Tax rate(%)
                </Click>
                <Collapse isOpen={visible}>
                  <div>
                    <FormGroup className="mb-3">
                      <label>Tax percentage</label>
                      <InputData
                        placeholder="%"
                        onChange={(e) => setTaxPercentage(parseFloat(e.target.value))}
                        size="sm"
                      />
                    </FormGroup>
                  </div>
                </Collapse>
              </>
            </Widget>
              </div>
              <div ref={componentRef}>
              <h5 className="mb-lg">Profit & Loss</h5>
              <Table size='sm' borderless hover>
              <th colSpan="2" style={{backgroundColor:'#f5f7fa'}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px' }}>Income</span>
                    <span><strong>{formattedDate}</strong></span>
                  </div>
                  </th>
                <tbody>
                  {incomeData.map((item, index) => (
                    <tr key={index}>
                      <td><span style={{marginLeft:'15px'}}>{item.account_name}</span></td>
                      <td style={{ width: '100px' }}>{formatValue(item.balance)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                      Total Income
                    </td>
                    <td style={{ width: '100px',backgroundColor:'#fcfcfc' }}>
                      <strong>{formatValue(totalIncome)}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table size='sm' borderless className="table">
              <th colSpan="2" style={{backgroundColor:'#f5f7fa'}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px' }}>Expenses</span>
                  </div>
                  </th>
                <tbody>
                  {expensesData.map((item, index) => (
                    <tr key={index}>
                      <td><span style={{marginLeft:'15px'}}>{item.account_name}</span></td>
                      <td style={{ width: '100px' }}>{formatValue(item.balance)}</td>
                    </tr>
                  ))}
                  {/* Total Expenses row */}
                  <tr>
                    <th style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                        Total Expenses
                      </th>
                    <td style={{ width: '100px',backgroundColor:'#fcfcfc' }}>
                      <strong>{formatValue(totalExpenses)}</strong>
                    </td>
                  </tr>
                  {/* New row for Gross profit/loss */}
                  <tr>
                  <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                      Gross profit/loss
                    </td>
                    <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                      {formatValue(grossProfitLoss)}
                    </td>
                  </tr>
                   {/* New row for Tax Amount (empty for now) */}
                  <tr>
                    <td >
                      <strong>Tax Amount</strong>
                    </td>
                    <td style={{ width: '100px'}}>
                    <strong>{formatValue(taxAmount)}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Table size='sm' borderless hover>
                <tbody>
                  <tr>
                  <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                      Net Income
                    </td>
                    <td style={{ width: '100px',backgroundColor:'#fcfcfc' }}>
                      <strong>{formatValue(netIncome)}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
              </div>
    </Widget>
    </div>
  )
};

export default ProfitLoss;
