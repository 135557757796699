import React, { useState, useEffect } from 'react';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import {Breadcrumb} from 'antd';
import {fetchFirebaseConfig} from '../../firebase';
import {
    doc,
    getDoc,
    onSnapshot
  } from 'firebase/firestore';
  import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
  import CountUp from 'react-countup';
import SharesDeposits from './transactions';
import SharesPayoutUser from './payout';

// const { TabPane } = Tabs;

const SharesTabs = () => {

  const { id } = useParams(); // Get the UID from the URL

  const [activeTab, setActiveTab] = useState('paymentsRecords');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    paymentsRecords: <SharesDeposits savingsId={id} refreshTable={refreshDepositsTable}/>,
    payoutsRecords: <SharesPayoutUser savingsId={id} refreshTable={refreshDepositsTable}/>
  };
  const [name, setName] = useState("");
  const [savingsData, setSavingsData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [savingsDetails, setSavingsDetails] = useState(null);

  const {db}=fetchFirebaseConfig();

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'paymentsRecords' || key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  useEffect(() => {
    const fetchSavingsData = async () => {
      try {
        const savingsRef = doc(db, 'Savings', id);
        const savingsSnapshot = await getDoc(savingsRef);
  
        if (savingsSnapshot.exists()) {
          const savingsData = savingsSnapshot.data();
          setSavingsData(savingsData);
  
          const userRef = savingsData.userSavings;
          const userSnapshot = await getDoc(userRef);
  
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);
  
            setSavingsData((prevLoanDetails) => ({
              ...prevLoanDetails,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userInterest: userData.interestRate,
              userid: userData.uid,
            }));
  
            // Apply onSnapshot to listen for real-time changes in savings data
            const savingsUnsubscribe = onSnapshot(savingsRef, (updatedSavingSnapshot) => {
              if (updatedSavingSnapshot.exists()) {
                const updatedSavingData = updatedSavingSnapshot.data();
                setSavingsDetails(updatedSavingData);
              }
            });
  
            // Apply onSnapshot to listen for real-time changes in user data
            const userUnsubscribe = onSnapshot(userRef, (updatedUserSnapshot) => {
              if (updatedUserSnapshot.exists()) {
                const updatedUserData = updatedUserSnapshot.data();
                setUserDetails(updatedUserData);
              }
            });
  
            // Clean up the listeners when the component unmounts
            return () => {
              savingsUnsubscribe();
              userUnsubscribe();
            };
          } else {
            setUserDetails(null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchSavingsData();
  }, [db, id]);
  


  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        {/* <Breadcrumb.Item><Link to={`/app/companies/company-view/${id}`}>View Stakeholders</Link></Breadcrumb.Item> */}
        <Breadcrumb.Item>Shares Tab</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">{userDetails?.display_name}'s  Shares Panel</h5>
          <Widget> {/* Add margin-left for separation */}
            <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
              <NavItem>
                <NavLink
                  className={activeTab === 'paymentsRecords' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('paymentsRecords')}
                  style={activeTab === 'paymentsRecords' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Transactions</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === 'payoutsRecords' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('payoutsRecords')}
                  style={activeTab === 'payoutsRecords' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Previous Payouts</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="paymentsRecords">
                <Widget>
                  <div className="align-items-center" >{tabContent.paymentsRecords}</div>
                </Widget>
              </TabPane>
              <TabPane tabId="payoutsRecords">
                <Widget>
                  <div className="align-items-center">{tabContent.payoutsRecords}</div>
                </Widget>
              </TabPane>
            </TabContent>
          </Widget>
      </div>
  );
};

export default SharesTabs;
