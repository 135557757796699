import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button as Click,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { Button, Space, Table,Spin,Select } from 'antd';
import { useState,useEffect } from 'react';
import { collection,doc,addDoc,updateDoc,serverTimestamp,getDocs,query,getDoc,where } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';


const AddEntry = () => {
    const { db } = fetchFirebaseConfig();
    const [newEntry, setNewEntry] = useState({
      Amount: '',
      Description: '',
      Date: '',
    });
  
    const [accountRows, setAccountRows] = useState([
      {
        Account: '',
        Debit: '',
        Credit: '',
        Description: '',
        Amount: '',
      },
    ]);
  
    const [accountOptions, setAccountOptions] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
  
    const history = useHistory();
  
    const calculateTotals = () => {
      let debitTotal = 0;
      let creditTotal = 0;
  
      accountRows.forEach((row) => {
        if (row.Debit) {
          debitTotal += parseFloat(row.Debit);
        }
  
        if (row.Credit) {
          creditTotal += parseFloat(row.Credit);
        }
      });
  
      setTotalDebit(debitTotal);
      setTotalCredit(creditTotal);
    };
  
        // Helper function to check if an account belongs to the Assets collection
        const checkIfAssetAccount = async (accountName) => {
          const assetsSnapshot = await getDocs(collection(db, 'Assets'));
          return assetsSnapshot.docs.some((doc) => doc.data().account_name === accountName);
        };
  
        // Helper function to check if an account belongs to the Liabilities collection
      const checkIfLiabilityAccount = async (accountName) => {
        const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
        return liabilitiesSnapshot.docs.some((doc) => doc.data().account_name === accountName);
      };
  
        // Helper function to check if an account belongs to the Income collection
        const checkIfIncomeAccount = async (accountName) => {
          const incomeSnapshot = await getDocs(collection(db, 'Income'));
          return incomeSnapshot.docs.some((doc) => doc.data().account_name === accountName);
        };
  
      // Helper function to check if an account belongs to the Equity collection
        const checkIfEquityAccount = async (accountName) => {
          const equitySnapshot = await getDocs(collection(db, 'Equity'));
          return equitySnapshot.docs.some((doc) => doc.data().account_name === accountName);
        };
  
        // Helper function to check if an account belongs to the Expenses collection
        const checkIfExpensesAccount = async (accountName) => {
          const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
          return expensesSnapshot.docs.some((doc) => doc.data().account_name === accountName);
        };
  
    const updateAssetBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Assets' collection
        const assetsCollectionRef = collection(db, 'Assets');
        const querySnapshot = await getDocs(query(assetsCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Assets collection.`);
          return;
        }  
        const assetsDoc = querySnapshot.docs[0];
        const currentBalance = assetsDoc.data().balance || 0;
        const newBalance = currentBalance + debitAmount - creditAmount;
    
        // Fetch the account_type from the Assets collection
        const accountType = assetsDoc.data().account_type;
    
        // Create a new document in the AssetData collection
        const assetDataCollectionRef = collection(db, 'AssetData');
        const newAssetData = {
          account_name: accountName,
          account_type: accountType, // Use the account_type from the Assets collection
          balance: newBalance,
          date: new Date().toLocaleString(), // Use the current date in a readable format
        };
    
        await addDoc(assetDataCollectionRef, newAssetData);
        // Update the balance field in the Assets collection
        const assetToUpdateRef = doc(db, 'Assets', assetsDoc.id);
        await updateDoc(assetToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating asset balance: ', error);
      }
    };
  
    const updateLiabilityBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Liabilities' collection
        const liabilitiesCollectionRef = collection(db, 'Liabilities');
        const querySnapshot = await getDocs(query(liabilitiesCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Liabilities collection.`);
          return;
        }  
        const liabilityDoc = querySnapshot.docs[0];
        const currentBalance = liabilityDoc.data().balance || 0;
        const newBalance = currentBalance + creditAmount - debitAmount;
    
        // Fetch the account_type from the Liabilities collection
        const accountType = liabilityDoc.data().account_type;
    
        // Create a new document in the LiabilitiesData collection
        const liabilitiesDataCollectionRef = collection(db, 'LiabilitiesData');
        const newLiabilitiesData = {
          account_name: accountName,
          account_type: accountType, // Use the account_type from the Liabilities collection
          balance: newBalance,
          date: new Date().toLocaleString(), // Use the current date in a readable format
        };
        await addDoc(liabilitiesDataCollectionRef, newLiabilitiesData);
        // Update the balance field in the Liabilities collection
        const liabilityToUpdateRef = doc(db, 'Liabilities', liabilityDoc.id);
        await updateDoc(liabilityToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating liability balance: ', error);
      }
    };
  
    const updateIncomeBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Income' collection
        const incomeCollectionRef = collection(db, 'Income');
        const querySnapshot = await getDocs(query(incomeCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Income collection.`);
          return;
        }
      // Create a new document in the IncomeData collection
      const incomeDoc = querySnapshot.docs[0];
      const currentBalance = incomeDoc.data().balance || 0;
      const newBalance = currentBalance + creditAmount - debitAmount;
  
      // Fetch the account_type from the Income collection
      const accountType = incomeDoc.data().account_type;
  
      // Create a new document in the IncomeData collection
      const incomeDataCollectionRef = collection(db, 'IncomeData');
      const newIncomeData = {
        account_name: accountName,
        account_type: accountType, // Use the account_type from the Income collection
        balance: newBalance,
        date: new Date().toLocaleString(), // Use the current date in a readable format
      };
  
      await addDoc(incomeDataCollectionRef, newIncomeData);
        // Update the balance field in the Income collection
        const incomeToUpdateRef = doc(db, 'Income', incomeDoc.id);
        await updateDoc(incomeToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating income balance: ', error);
      }
    };
    
  
    const updateEquityBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Equity' collection
        const equityCollectionRef = collection(db, 'Equity');
        const querySnapshot = await getDocs(query(equityCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Equity collection.`);
          return;
        }
      // Create a new document in the EquityData collection
  
      const equityDoc = querySnapshot.docs[0];
      const currentBalance = equityDoc.data().balance || 0;
      const newBalance = currentBalance + creditAmount - debitAmount;
  
      // Fetch the account_type from the Equity collection
      const accountType = equityDoc.data().account_type;
  
      // Create a new document in the EquityData collection
      const equityDataCollectionRef = collection(db, 'EquityData');
      const newEquityData = {
        account_name: accountName,
        account_type: accountType, // Use the account_type from the Equity collection
        balance: newBalance,
        date: new Date().toLocaleString(), // Use the current date in a readable format
      };
  
      await addDoc(equityDataCollectionRef, newEquityData);
        const equityToUpdateRef = doc(db, 'Equity', equityDoc.id);
        await updateDoc(equityToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating equity balance: ', error);
      }
    };
  
    const updateExpensesBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Expenses' collection
        const expensesCollectionRef = collection(db, 'Expenses');
        const querySnapshot = await getDocs(query(expensesCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Expenses collection.`);
          return;
        }
      // Create a new document in the ExpensesData collection
      const expensesDoc = querySnapshot.docs[0];
      const currentBalance = expensesDoc.data().balance || 0;
      const newBalance = currentBalance + debitAmount - creditAmount;
  
      // Fetch the account_type from the Expenses collection
      const accountType = expensesDoc.data().account_type;
  
      // Create a new document in the ExpensesData collection
      const expensesDataCollectionRef = collection(db, 'ExpensesData');
      const newExpensesData = {
        account_name: accountName,
        account_type: accountType, // Use the account_type from the Expenses collection
        balance: newBalance,
        date: new Date().toLocaleString(), // Use the current date in a readable format
      };
  
      await addDoc(expensesDataCollectionRef, newExpensesData);
        const expensesToUpdateRef = doc(db, 'Expenses', expensesDoc.id);
        await updateDoc(expensesToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating expenses balance: ', error);
      }
    };
  
    const handleAddEntry = async () => {
      if (newEntry.Description && newEntry.Date) {
        try {
          const journalData = {
            description: newEntry.Description,
            date: newEntry.Date,
            total_credit: totalCredit,
            total_debit: totalDebit,
            entries: accountRows.map((row) => ({
              debit_account: row.Debit > 0 ? row.Account : '',
              credit_account: row.Credit > 0 ? row.Account : '',
              debit_amount: row.Debit ? parseFloat(row.Debit) : 0,
              credit_amount: row.Credit ? parseFloat(row.Credit) : 0,
              double_entry_description: row.Description,
            })),
          };
          accountRows.forEach(async (row) => {
            const debitAmount = parseFloat(row.Debit);
            const creditAmount = parseFloat(row.Credit);
  
            const entry = {
              debit_account: debitAmount > 0 ? row.Account : '',
              credit_account: creditAmount > 0 ? row.Account : '',
              debit_amount: debitAmount > 0 ? debitAmount : 0,
              credit_amount: creditAmount > 0 ? creditAmount : 0,
              double_entry_description: row.Description,
            };
         // Check if the selected account belongs to the Assets, Liabilities, Income, Equity, or Expenses collection
         const isAssetAccount = await checkIfAssetAccount(row.Account);
         const isLiabilityAccount = await checkIfLiabilityAccount(row.Account);
         const isIncomeAccount = await checkIfIncomeAccount(row.Account);
         const isEquityAccount = await checkIfEquityAccount(row.Account);
         const isExpensesAccount = await checkIfExpensesAccount(row.Account);
   
         if (isAssetAccount) {
           // Update the balance in the Assets collection based on Debit/Credit
           await updateAssetBalance(row.Account, debitAmount, creditAmount);
         } else if (isLiabilityAccount) {
           // Update the balance in the Liabilities collection based on Debit/Credit
           await updateLiabilityBalance(row.Account, debitAmount, creditAmount);
         } else if (isIncomeAccount) {
           // Update the balance in the Income collection based on Debit/Credit
           await updateIncomeBalance(row.Account, debitAmount, creditAmount);
         } else if (isEquityAccount) {
           // Update the balance in the Equity collection based on Debit/Credit
           await updateEquityBalance(row.Account, debitAmount, creditAmount);
         } else if (isExpensesAccount) {
           // Update the balance in the Expenses collection based on Debit/Credit
           await updateExpensesBalance(row.Account, debitAmount, creditAmount);
         }
   
         journalData.entries.push(entry);
       });
   
  
          await addDoc(collection(db, 'Journal'), journalData);
          // Redirect back to the Journal page after adding the entry
          history.push("/app/accounting/account-journals");
        } catch (error) {
          console.error('Error adding document: ', error);
        }
      }
    };
  
    const handleAddAccount = () => {
      setAccountRows((prevRows) => [
        ...prevRows,
        {
          Account: '',
          Debit: '',
          Credit: '',
          Description: '',
          Amount: '',
        },
      ]);
    };

    const handleRemoveRow = (index) => {
      const updatedRows = [...accountRows];
      updatedRows.splice(index, 1);
      setAccountRows(updatedRows);
    };

    useEffect(() => {
      // Fetch account names from Firestore collections
      const fetchAccountNames = async () => {
        try {
          const assetsSnapshot = await getDocs(collection(db, 'Assets'));
          const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
          const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
          const incomeSnapshot = await getDocs(collection(db, 'Income'));
          const equitySnapshot = await getDocs(collection(db, 'Equity'));
  
          const assetsNames = assetsSnapshot.docs.map((doc) => doc.data().account_name);
          const liabilitiesNames = liabilitiesSnapshot.docs.map((doc) => doc.data().account_name);
          const expensesNames = expensesSnapshot.docs.map((doc) => doc.data().account_name);
          const incomeNames = incomeSnapshot.docs.map((doc) => doc.data().account_name);
          const equityNames = equitySnapshot.docs.map((doc) => doc.data().account_name);
  
          // Combine all account names into one array
          const allAccountNames = [
            ...assetsNames,
            ...liabilitiesNames,
            ...expensesNames,
            ...incomeNames,
            ...equityNames,
          ];
          // Remove duplicates and set as options for the dropdown
          setAccountOptions([...new Set(allAccountNames)]);
        } catch (error) {
          console.error('Error fetching account names: ', error);
        }
      };
      // Call the function to fetch account names
      fetchAccountNames();
    }, [db]);
  
    useEffect(() => {
      // Recalculate totals whenever accountRows changes
      calculateTotals();
    }, [accountRows]);


    const columns = [
      {
        title: 'Account',
        dataIndex: 'Account',
        key: 'Account',
        align: 'center',
        render: (text, record, index) => (
          <Select
            style={{width:"100%"}}
            value={text}
            onChange={(value) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Account = value;
              setAccountRows(updatedRows);
            }}
          >
            <option value="">Select Account</option>
            {accountOptions.map((accountName) => (
              <option key={accountName} value={accountName}>
                {accountName}
              </option>
            ))}
          </Select>
        ),
      },
      {
        title: 'Debit',
        dataIndex: 'Debit',
        key: 'Debit',
        align: 'center',
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Debit = e.target.value;
              updatedRows[index].Credit = e.target.value ? '0' : '';
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
      {
        title: 'Credit',
        dataIndex: 'Credit',
        key: 'Credit',
        align: 'center',
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Credit = e.target.value;
              updatedRows[index].Debit = e.target.value ? '0' : '';
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
        align: 'center',
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Description = e.target.value;
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
    ];
  

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Add Journal Entry</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Add Journal Entry</h4>
        <Widget>
          <Form>
            <FormGroup>
                <Label for="loanGuarantors">Date</Label>
                <Input
                value={newEntry.Date}
                onChange={(e) => setNewEntry({ ...newEntry, Date: e.target.value })}
                type="date"
                size='sm'
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanCommitee"> Description </Label>
                <Input
                value={newEntry.Description}
                onChange={(e) => setNewEntry({ ...newEntry, Description: e.target.value })}
                placeholder='Description'
                type="textarea"
                size='sm'
                />
            </FormGroup>
            </Form>
          </Widget>

        <Widget>
        <ButtonGroup className='mb-3'>
        <Click color="success" size="sm" onClick={handleAddAccount}>
        <i className="fa fa-plus" aria-hidden="true"></i> Add Row
        </Click>
        <Click color="danger" size="sm" onClick={handleRemoveRow}style={{marginLeft:'1px'}}>
          <i className="fa fa-times" aria-hidden="true"></i> Delete Row
        </Click>
        </ButtonGroup>
        <Table
          columns={columns}
          dataSource={accountRows}
          pagination={false}
          bordered
          rowKey={(record, index) => index}
          size='small'
        />
        <div style={{ justifyContent: 'flex-start' }} className='mt-3'>
          <p style={{ fontSize: '14px', margin: '5px 0' }}>
            <span style={{ fontWeight: 'bold' }}>Total Credit: </span>
            {totalCredit.toFixed(2)}
          </p>
          <p style={{ fontSize: '14px', margin: '5px 0' }}>
            <span style={{ fontWeight: 'bold' }}>Total Debit: </span>
            {totalDebit.toFixed(2)}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Click
            color="success"
            onClick={handleAddEntry}
            style={{ border: 'none', fontSize: '14px' }}
          >
            <span style={{ color: 'white' }}>Create</span>
          </Click>
        </div>
      </Widget>
    </div>
  );
};

export default AddEntry;

