import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { collection, doc, addDoc, serverTimestamp, getDoc, query, where } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import firebase from 'firebase/compat/app';
import { useHistory, useParams } from 'react-router-dom';

const TransactionFormPayment = () => {
  const [enteredShares, setEnteredShares] = useState('');
  const [transactionType, setTransactionType] = useState('Shares');
  const [amountPaid, setAmountPaid] = useState(0);
  const [CompanyId, setCompanyId] = useState('');
  const [amountPerShares, setAmountPerShares] = useState('');
  const { id } = useParams(); // Get id from URL

  const currentAuth = firebase.auth().currentUser;
  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyDoc = doc(db, 'Companies', id);
        const companySnapshot = await getDoc(companyDoc);
        if (companySnapshot.exists()) {
          const companyData = companySnapshot.data();
          setCompanyId(companyData.company_id);
          setAmountPerShares(companyData.AmountPerShares)
        } else {
          console.error('Company not found');
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [db, id]);


  const handleTransactionTypeChange = (e) => {
    setTransactionType(e.target.value);
    setAmountPaid(0);
  };

  const handleSharesChange = (e) => {
    const shares = parseInt(e.target.value);
    setEnteredShares(shares.toString());

    if (transactionType === 'Shares') {
      const calculatedAmount = shares * amountPerShares;
      setAmountPaid(calculatedAmount.toString());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (!currentAuth) {
        console.error('No authenticated user found');
        return;
      }
  
      const userDocRef = doc(db, 'users', currentAuth.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      const company_id = userDocSnapshot.data()?.company_id;
  
      const commonData = {
        company_id: CompanyId,
        receipt: '',
        user: userDocRef,  // Set user field as DocumentReference
      };
  
      if (transactionType === 'Funds') {
        const transactionData = {
          ...commonData,
          approvalStatus: 'pending',
          transactionAmount: parseFloat(amountPaid),
          transactionDate: serverTimestamp(),
          transactionType: transactionType,
        };
  
        const allTransactionsCollection = collection(db, 'AllTransactions');
        await addDoc(allTransactionsCollection, transactionData);
        console.log('Funds Transaction added successfully');
      }
  
      if (transactionType === 'Shares') {
        const calculatedAmount = enteredShares * amountPerShares;
        const requestSharesData = {
          ...commonData,
          requestDate: serverTimestamp(),
          amount: calculatedAmount,
          feedbackmessage: 'Request pending',
          receipt: '', // Leave receipt empty for now
          requestStatus: 'pending',
          requestType: 'Shares Purchase',
          shares: enteredShares,
        };
  
        const transactionsSharesData = {
          ...commonData,
          approvalStatus: 'pending',
          transactionAmount: calculatedAmount,
          transactionDate: serverTimestamp(),
          transactionType: transactionType,
        };
  
        const requestsCollection = collection(db, 'Requests');
        await addDoc(requestsCollection, requestSharesData);
        console.log('Shares Request added successfully');
  
        // Add document to Shares collection
        const sharesCollection = collection(db, 'Shares');
        await addDoc(sharesCollection, transactionsSharesData);
        console.log('Shares Transaction added successfully');
      }
  
      // Reset form fields after submission
      setEnteredShares('');
      setTransactionType('Shares');
      setAmountPaid(0);
  
    } catch (error) {
      console.error('Error adding transaction:', error);
    }
  };
  

  return (
    <div>
      <h5 className="mb-lg">Make Transactions</h5>
      <Row>
        <Col xs={6}>
          <Widget>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="TransactionType">Transaction Type</Label>
                <Input
                  type="select"
                  name="transactionType"
                  id="TransactionType"
                  value={transactionType}
                  onChange={handleTransactionTypeChange}
                >
                  <option value="Shares">Shares</option>
                  <option value="Funds">Funds</option>
                </Input>
              </FormGroup>
              <FormGroup>
              {transactionType === 'Shares' && (
                <FormGroup>
                <Label for="SharesAmount">Number Of Shares</Label>
                <Input
                  id="SharesAmount"
                  name="sharesAmount"
                  placeholder="Shares Amount"
                  type="number"
                  required
                  value={enteredShares}
                  onChange={handleSharesChange}
                />
                </FormGroup>
              )}
               <Label for="PaidAmount">Amount</Label>
                  <Input
                    id="PaidAmount"
                    name="paidAmount"
                    placeholder="Payment Amount"
                    type="number"
                    required
                    value={amountPaid}
                    disabled={transactionType === 'Shares'}
                    onChange={(e) => setAmountPaid(e.target.value)}
                  />
              </FormGroup>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default TransactionFormPayment;
