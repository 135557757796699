import React, { useRef, useState, useEffect } from 'react';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem, Form, Col, Row, Table, Collapse, Button } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { collection, doc, updateDoc, addDoc, getDocs, deleteDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';

const CashFlow = () => {
  const { db } = fetchFirebaseConfig();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modal, setModal] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [operationsTable, setOperationsTable] = useState([]);
  const [investingTable, setInvestingTable] = useState([]);
  const [financialTable, setFinancialTable] = useState([]);

  const componentRef = useRef(null);

  const toggle = () => setModal(!modal);

  const fetchCashFlowData = async () => {
    try {
      // Fetch data from Operations collection
      const operationsSnapshot = await getDocs(collection(db, 'Operations'));
      const operationsData = operationsSnapshot.docs.map(doc => doc.data());
      setOperationsTable(operationsData);

      // Fetch data from Investments collection
      const investmentsSnapshot = await getDocs(collection(db, 'Investments'));
      const investmentsData = investmentsSnapshot.docs.map(doc => doc.data());
      setInvestingTable(investmentsData);

      // Fetch data from Financial collection
      const financialSnapshot = await getDocs(collection(db, 'Financial'));
      const financialData = financialSnapshot.docs.map(doc => doc.data());
      setFinancialTable(financialData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {  
    fetchCashFlowData();
  }, [db]);

  // useEffect to fetch account types
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
        const types = [];

        for (const table of tables) {
          const collectionSnapshot = await getDocs(collection(db, table));
          const typeNames = collectionSnapshot.docs.map((doc) => ({
            name: doc.data().account_name,
            balance: doc.data().balance,
            subCategory: doc.data().subCategory // Assuming subCategory is a field in your Firestore document
          }));
          types.push(...typeNames);
        }

        // Remove duplicates and set as options for the dropdown
        const uniqueTypes = [...new Set(types)];
        setAccountTypes(uniqueTypes);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [db]);


  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString('default', { month: 'short' });
  const year = String(currentDate.getFullYear()).slice(2); // Get last two digits of the year

  // Function to add ordinal suffix to the day
  const addOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };


  const formattedDate = `${addOrdinalSuffix(day)} ${month} ${year}`;


  const handleAdd = async () => {
    const selectedAccountObject = accountTypes.find(account => account.name === selectedAccount);
    selectedAccountObject.subCategory = selectedSubCategory;
    if (selectedCategory === 'Operations') {
      setOperationsTable([...operationsTable, selectedAccountObject]);
      try {
        await addDoc(collection(db, 'Operations'), {
          name: selectedAccountObject.name,
          added_at: serverTimestamp(), // Current server timestamp
          balance: selectedAccountObject.balance,
          subcategory: selectedSubCategory
        });
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    } else if (selectedCategory === 'Investing Activities') {
      // Add selected account to the investingTable state
      setInvestingTable([...investingTable, selectedAccountObject]);
  
      try {
        // Create a document in the Investments collection
        await addDoc(collection(db, 'Investments'), {
          name: selectedAccountObject.name,
          added_at: serverTimestamp(), // Current server timestamp
          balance: selectedAccountObject.balance,
          subcategory: selectedSubCategory
        });
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    } else if (selectedCategory === 'Financial Activities') {
      setFinancialTable([...financialTable, selectedAccountObject]);
  
      try {
        // Create a document in the Financial collection
        await addDoc(collection(db, 'Financial'), {
          name: selectedAccountObject.name,
          added_at: serverTimestamp(), // Current server timestamp
          balance: selectedAccountObject.balance,
          subcategory: selectedSubCategory
        });
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    }

    fetchCashFlowData();
  
    toggle();
  };
  

  // Function to group accounts by sub-category
const groupBySubCategory = (accounts) => {
  const groupedAccounts = {};
  accounts.forEach(account => {
    if (!groupedAccounts[account.subcategory]) {
      groupedAccounts[account.subcategory] = [];
    }
    groupedAccounts[account.subcategory].push(account);
  });
  return groupedAccounts;
};


  const calculateOperationsNetCashFlow = () => {
    let totalCashReceipts = 0;
    let totalCashPaidFor = 0;

    operationsTable.forEach(account => {
      if (account.subcategory === 'Cash from receipts') {
        totalCashReceipts += account.balance;
      } else if (account.subcategory === 'Cash paid for') {
        totalCashPaidFor += account.balance;
      }
    });

    return totalCashReceipts - totalCashPaidFor;
  };

  // Function to calculate net cash flow for investing table
const calculateInvestingNetCashFlow = () => {
  let totalCashReceipts = 0;
  let totalCashPaidFor = 0;

  investingTable.forEach(account => {
    if (account.subcategory === 'Cash from receipts') {
      totalCashReceipts += account.balance;
    } else if (account.subcategory === 'Cash paid for') {
      totalCashPaidFor += account.balance;
    }
  });

  return totalCashReceipts - totalCashPaidFor;
};

// Function to calculate net cash flow for financial table
const calculateFinancialNetCashFlow = () => {
  let totalCashReceipts = 0;
  let totalCashPaidFor = 0;

  financialTable.forEach(account => {
    if (account.subcategory === 'Cash from receipts') {
      totalCashReceipts += account.balance;
    } else if (account.subcategory === 'Cash paid for') {
      totalCashPaidFor += account.balance;
    }
  });

  return totalCashReceipts - totalCashPaidFor;
};

// Calculate net cash flow for each table
const operationsNetCashFlow = calculateOperationsNetCashFlow();
const investingNetCashFlow = calculateInvestingNetCashFlow();
const financialNetCashFlow = calculateFinancialNetCashFlow();

const netIncreaseDecrease =operationsNetCashFlow + investingNetCashFlow + financialNetCashFlow

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Cash-Flow</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Cash Flow</h4>
      <Widget
        title={<h7><span className="fw-semi-bold">Data Range</span></h7>}>
        <Row style={{ marginTop: '15px' }}>
          <Col md={6}>
            <FormGroup style={{ marginBottom: '10px' }}>
              <label><span style={{ fontSize: '14px' }}>Start Date:</span></label>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ marginRight: '10px' }}
                size='sm'
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup style={{ marginBottom: '10px' }}>
              <label><span style={{ fontSize: '14px' }}>End Date:</span></label>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                size='sm'
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="mb-1">
          <Widget>
          </Widget>
        </div>
        <div ref={componentRef}>
          <h5 className="mb-lg">Cash Flow</h5>

          <div style={{ marginBottom: '20px' }}>
            <Button color='success' size='sm' onClick={toggle}>Add Entry</Button>
          </div>

          {/* Render Operations Table */}
          <Table size='sm' borderless hover>
            <tbody>
              <tr>
              <th style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px', width:'100%'}}>Operations</span></th>
              <th style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px', width:'100%'}}></span></th>
              </tr>
              {Object.entries(groupBySubCategory(operationsTable)).map(([subcategory, accounts], index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th colSpan="2">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{fontWeight: 'bold', fontSize: '14px' }}>{subcategory}</span>
                      </div>
                    </th>
                  </tr>
                  {accounts.map((account, index) => (
                    <tr key={index}>
                      <td>
                        <span style={{ marginLeft: '15px' }}>{account.name}</span>
                      </td>
                      <td style={{ width: '100px' }}>{account.balance.toLocaleString()}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
              <tr>
              <td style={{backgroundColor:'#f5f7fa'}}>
                <span style={{ fontWeight: 'bold', fontSize: '15px', width:'100%'}}>Net Operations Cash Flow</span>
              </td>
              <td style={{backgroundColor:'#f5f7fa'}}>
                <span style={{ fontWeight: 'bold', fontSize: '15px', width:'100%'}}>{operationsNetCashFlow.toLocaleString()}</span>
              </td>
              </tr>
            </tbody>
          </Table>

          {/* Render Financial Activities Table */}
          <Table size='sm' borderless hover>
            <tbody>
              <tr>
              <th style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px' }}>Financial Activities</span></th>
              <th style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px', width:'100%'}}></span></th>
              </tr>
              {Object.entries(groupBySubCategory(financialTable)).map(([subcategory, accounts], index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th colSpan="2">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{subcategory}</span>
                      </div>
                    </th>
                  </tr>
                  {accounts.map((account, index) => (
                    <tr key={index}>
                      <td>
                        <span style={{ marginLeft: '15px' }}>{account.name}</span>
                      </td>
                      <td style={{ width: '100px' }}>{account.balance.toLocaleString()}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
             <tr>
              <td style={{backgroundColor:'#f5f7fa'}}>
                <span style={{ fontWeight: 'bold', fontSize: '15px', width:'100%'}}>Net Financial Cash Flow</span>
              </td>
              <td style={{backgroundColor:'#f5f7fa'}}>
                <span style={{ fontWeight: 'bold', fontSize: '15px', width:'100%'}}>{financialNetCashFlow.toLocaleString()}</span>
              </td>
              </tr>
            </tbody>
          </Table>

          {/* Render Investing Activities Table */}
          <Table size='sm' borderless hover>
            <tbody>
              <tr>
              <th style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px',width:'100%' }}>Investment Activities</span></th>
              <th style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px', width:'100%'}}></span></th>
              </tr>
              {Object.entries(groupBySubCategory(investingTable)).map(([subcategory, accounts], index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th colSpan="2">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{  fontWeight: 'bold', fontSize: '14px' }}>{subcategory}</span>
                      </div>
                    </th>
                  </tr>
                  {accounts.map((account, index) => (
                    <tr key={index}>
                      <td>
                        <span style={{ marginLeft: '15px' }}>{account.name}</span>
                      </td>
                      <td style={{ width: '100px' }}>{account.balance.toLocaleString()}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
               <tr>
              <td style={{backgroundColor:'#f5f7fa'}}>
                <span style={{ fontWeight: 'bold', fontSize: '15px', width:'100%'}}>Net Investments Cash Flow</span>
              </td>
              <td style={{backgroundColor:'#f5f7fa'}}>
                <span style={{ fontWeight: 'bold', fontSize: '15px', width:'100%'}}>{investingNetCashFlow.toLocaleString()}</span>
              </td>
              </tr>
            </tbody>
          </Table>

            <Table size='sm' borderless hover>
            <tbody>
               <tr>
              <td style={{backgroundColor:'#f5f7fa'}} colSpan="2">
                <span style={{ color: '#28d456',fontWeight: 'bold', fontSize: '15px',width:'20%'}}>Net Increase/Decrease In Cash</span>
              </td>
              <td style={{backgroundColor:'#f5f7fa'}} colSpan="2">
                <span style={{ color: '#28d456',fontWeight: 'bold', fontSize: '15px',width:'20%'}}>{netIncreaseDecrease.toLocaleString()}</span>
              </td>
              </tr>
            </tbody>
           </Table>

        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}  style={{border:'none'}}>Add Entry</ModalHeader>
          <ModalBody>
          <Widget>
            <FormGroup>
              <Label>Select Category</Label>
              <Input
                name="select"
                type="select"
                className="mb-3"
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                <option value="Operations">Operations</option>
                <option value="Investing Activities">Investing Activities</option>
                <option value="Financial Activities">Financial Activities</option>
              </Input>
              <Label>Select Account</Label>
              <Input
                name="select"
                type="select"
                className="mb-3"
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                <option value="">Select Type</option>
                {accountTypes.map((type, index) => (
                  <option key={index} value={type.name}>
                    {`${type.name} - Balance: ${type.balance.toLocaleString()}`} {/* Display account name and balance */}
                  </option>
                ))}
              </Input>
              <Label>Select Sub-Category</Label>
              <Input
                name="selectSubCategory"
                type="select"
                className="mb-3"
                onChange={(e) => setSelectedSubCategory(e.target.value)}
              >
                <option value="">Select Sub-Category</option>
                <option value="Cash from receipts">Cash From Receipts</option>
                <option value="Cash paid for">Cash Paid For</option>
              </Input>
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'flex-start',marginTop:'12px'}}>
            <Button color="success" onClick={handleAdd}>
              Add
            </Button>{' '}
            <Button color="danger" onClick={toggle} style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
            </div>
            </Widget>
          </ModalBody>
        </Modal>
      </Widget>
    </div>
  )
};

export default CashFlow;

