import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Breadcrumb, BreadcrumbItem, Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import LoanSummaryDetails from './loanDetails';

const MidTerm = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null); 
  
  const history = useHistory();

  const componentRef = useRef(null);

  const toggle = () => setModal(!modal);

  const toggleLoanDetailsModal = (id) => {
    setSelectedLoanId(id); // Set the selected loan id
    setModal(!modal); // Toggle the modal
  };

  const handleDetailsClick = (id) => {
    toggleLoanDetailsModal(id);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const fetchLoanDataAndSetState = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();

      // Create a reference to the Loans collection
      const loansCollectionRef = collection(db, 'Loans');

      // Query loans where loanSchedual is "Long Term Loan"
      const querySnapshot = await getDocs(query(loansCollectionRef, where('loanSchedual', '==', 'Mid Term Loan')));

      const newLoansData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Sort the loans data based on the dateTaken field in descending order
      const sortedNewLoansData = newLoansData.sort((a, b) => b.dateTaken.seconds - a.dateTaken.seconds);

      // Filter out loans with loanAmount equal to zero
      const filteredNewLoansData = sortedNewLoansData.filter((loan) => loan.loanAmount !== 0 && loan.loanSchedual == "Mid Term Loan");

      setData(filteredNewLoansData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLoanDataAndSetState();
  }, []);


  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollectionRef = collection(db, 'Loans');
      let loansQuery = query(loansCollectionRef);

      // Add filters for start and end dates if provided
      if (startDate && endDate) {
        // Convert start and end dates to Date objects
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
  
        // Filter loans based on the full date range, existence, and non-blank loanSchedual field
        loansQuery = query(
          loansCollectionRef,
          where('dateTaken', '>=', startDateObj),
          where('dateTaken', '<=', endDateObj),
        );
      } else {
        // If start or end date is not provided, set data state to an empty array
        setData([]);
        setLoadingData(false);
        return;
      }
  
      const querySnapshot = await getDocs(loansQuery);
      
      const newLoansData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
      // Sort the loans data based on the dateTaken field in descending order
      const sortedNewLoansData = newLoansData.sort((a, b) => b.dateTaken.seconds - a.dateTaken.seconds);
  
      // Filter out loans with loanAmount equal to zero
      const filteredNewLoansData = sortedNewLoansData.filter((loan) => loan.loanAmount !== 0 && loan.loanSchedual == "Mid Term Loan");
  
      setData(filteredNewLoansData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };


  const handleApproveClick = async (id) => {
    try {
      // Find the loan with the specified ID in the local state
      const { db } = fetchFirebaseConfig(); // Fetch Firebase configuration  
      // Display the confirmation modal
      Swal.fire({
        icon: 'warning',
        title: 'Confirm this action',
        text: 'Are you sure you want to approve this loan?',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(async (result) => {
        // Check if the confirm button is clicked
        if (result.isConfirmed) {
  
          const loanDocRef = doc(db, 'Loans', id);
  
          // Fetch the existing loan data
          const loanDocSnapshot = await getDoc(loanDocRef);
          if (loanDocSnapshot.exists()) {
            // Calculate due date based on loanDuration and current time
            const loanData = loanDocSnapshot.data();
            // const loanDurationInWeeks = parseInt(loanData.loanDuration, 10);
            // const currentTime = new Date();
            // const dueDate = new Date(currentTime.getTime() + loanDurationInWeeks * 7 * 24 * 60 * 60 * 1000);
  
            // Update the Firestore document with 'loanState' and 'dueDate'
            await updateDoc(loanDocRef, {
              loanState: 'Approved',
              // payOutDate: dueDate,
              dateApproved: serverTimestamp(),
            });
          }
          // Display the success alert
          Swal.fire({
            icon: 'success',
            title: 'Loan Approved',
            text: 'The Loan has been successfully approved!',
            confirmButtonColor: '#28a745',
          });

          fetchLoanDataAndSetState();
        }
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
  


  const handleDenyClick = async (id) => {
    try {
      // Find the loan with the specified ID in the local state
      const { db } = fetchFirebaseConfig(); // Fetch Firebase configuration
  
      // Display the confirmation modal
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Confirm this action',
        text: 'Are you sure you want to decline this loan?',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
  
      // Check if the confirm button is clicked
      if (result.isConfirmed) {
  
        const loanDocRef = doc(db, 'Loans', id);
  
        // Fetch the existing loan data
        const loanDocSnapshot = await getDoc(loanDocRef);
        if (loanDocSnapshot.exists()) {
          await updateDoc(loanDocRef, {
            loanState: 'Denied',
            dateDenied: serverTimestamp(),
          });
        }
        // Display the success alert
        await Swal.fire({
          icon: 'success',
          title: 'Loan Declined',
          text: 'The Loan has been successfully declined!',
          confirmButtonColor: '#28a745',
        });
        // Fetch and update loan data after declining
        fetchLoanDataAndSetState();
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
  
  

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };


  const formatNumber = (number) => {
    return number?.toLocaleString();
  };

  const renderLoanTerm = (loanSchedual) => {
    return loanSchedual === "Mid Term Loan" ? (
      <Badge color="warning" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Mid Term</span>
      </Badge>
    ) : null;
  };

  const renderLoanState = (loanState) => {
    return loanState === "Approved" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Approved</span>
      </Badge>
    ) : loanState === "pending" ? (
      <Badge color="info" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Pending</span>
      </Badge>
    ) : (
      <Badge color="danger" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Denied</span>
      </Badge>
    );
  };

  const handleRedo = () => {
    fetchLoanDataAndSetState();
    setEndDate('');
    setStartDate('');
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
      sorter: (a, b) => a.userName - b.userName,
      ...getColumnSearchProps('userName'),
    },
    {
      title: 'Loan Request',
      dataIndex: 'loanAmount1',
      key: 'loanAmount1',
      align: 'center',
      ...getColumnSearchProps('loanAmount1'),
      sorter: (a, b) => a.loanAmount1 - b.loanAmount1,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Loan Term',
      dataIndex: 'loanSchedual',
      key: 'loanSchedual',
      align: 'center',
      sorter: (a, b) => a.loanSchedual - b.loanSchedual,
      ...getColumnSearchProps('loanSchedual'),
      render:renderLoanTerm,
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'amountTobeReturned',
      key: 'amountTobeReturned',
      align: 'center',
      sorter: (a, b) => a.amountTobeReturned - b.amountTobeReturned,
      ...getColumnSearchProps('amountTobeReturned'),
      render: (text) => formatNumber(text),
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      align: 'center',
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      ...getColumnSearchProps('amountPaid'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Remaining Balance',
      dataIndex: 'amountRemaining',
      key: 'amountRemaining',
      align: 'center',
      sorter: (a, b) => a.amountRemaining - b.amountRemaining,
      ...getColumnSearchProps('amountRemaining'),
      render: (text) => formatNumber(text),
    },
    {
      title: 'Payment Status',
      dataIndex: 'loanStatus',
      key: 'loanStatus',
      align: 'center',
      sorter: (a, b) => a.loanStatus - b.loanStatus,
      ...getColumnSearchProps('loanStatus'),
    },
    {
      title: 'Requested On',
      dataIndex: 'dateTaken',
      key: 'dateTaken',
      align: 'center',
      sorter: (a, b) => a.dateTaken - b.dateTaken,
      render: renderDateRequested,
      ...getColumnSearchProps('dateTaken'),
      render: (text) => new Date(text.seconds * 1000).toLocaleDateString(),
    },
    {
      title: 'Payment Due On',
      dataIndex: 'payOutDate',
      key: 'payOutDate',
      align: 'center',
      sorter: (a, b) => a.payOutDate - b.payOutDate,
      render: renderDateRequested,
      ...getColumnSearchProps('payOutDate'),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Click outline color='success' style={{borderRadius:'10px' }} onClick={() => handleDetailsClick(record.id)}>
            <i className="fa fa-file-text-o" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'loanState',
      key: 'loanState',
      align: 'center',
      ...getColumnSearchProps('loanState'),
      render:renderLoanState,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space>
          <Click  color='success' size='sm' onClick={() => handleApproveClick(record.id)} disabled={record.loanState === 'Denied' || record.loanState === 'Approved' || record.loanComiteeStatus !=1 && record.guaranteersStatus !=1 }>
            APPROVE
          </Click>
          <Click  color='danger' size='sm' onClick={() => handleDenyClick(record.id)} disabled={record.loanState === 'Approved' || record.loanState === 'Denied' ||record.loanComiteeStatus !=1 && record.guaranteersStatus !=1}>
            DECLINE
          </Click>
          <Click  color='success' outline style={{borderRadius:'10px'}}  onClick={() => history.push(`/app/loans/long-term-loans/loans-panel/${record.id}`)} disabled={record.loanState === 'pending' || record.loanState === 'Denied'}>
          <i className="fa fa-credit-card" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Mid-Term Loans</BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mb-lg">Mid-Term Loans Requests</h5>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div>
           </Widget>
      <Widget>
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ButtonGroup>
                <Click color="success" size='sm'>
                  CSV
                </Click>
                <Click color="success" size='sm'>
                  PDF
                </Click>
                <ReactToPrint
                      trigger={() => (
                        <Click color="success" disabled={printing} size='sm'>
                         {printing ? <Spinner size="sm" style={{ color: 'white' }}/> : <i className="fa fa-print" aria-hidden="true"></i>}
                        </Click>
                      )}
                      content={() => componentRef.current} // Specify the component to be printed
                      onBeforeGetContent={() => setPrinting(true)}
                      onAfterPrint={() => setPrinting(false)}
                    />
            </ButtonGroup>
          </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size='small' />
        </div>
      </Widget>
      <Modal isOpen={modal} toggle={toggle}>
        <Widget>
        {selectedLoanId && <LoanSummaryDetails id={selectedLoanId} />}
        </Widget>
        <ModalFooter>
          <Click color="danger" onClick={toggle} size='sm'>
            Cancel
          </Click>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MidTerm;


