import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Spinner} from 'reactstrap';
import {Breadcrumb} from 'antd';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { Button as Click, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory,useParams,Link } from 'react-router-dom/cjs/react-router-dom.min';
import RegisterHolder from './RegisterHolder';
import { fetchUsers } from './fetchData';
import { doc,getDoc,collection } from 'firebase/firestore';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';

const ViewStakeholders = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [modal, setModal] = useState(false);
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [companyCode, setCompanyCode] = useState('');
    const [companyName, setCompanyName] = useState('');
    

    const toggle = () => setModal(!modal);
  
    const searchInput = useRef(null);
    const componentRef = useRef(null);
    const {id} = useParams();
    const history = useHistory();

    const fetchData = async () => {
      const { db } = fetchFirebaseConfig();
      setIsLoading(true);
    
      try {
        // Fetch the company document based on the provided id
        const companyDocRef = await doc(db, 'Companies', id);
        const companyDocSnapshot = await getDoc(companyDocRef);
    
        if (companyDocSnapshot.exists()) {
          const companyData = companyDocSnapshot.data();
          const companyCode = companyData.company_id;
          const companyName = companyData.CompanyName

          setCompanyCode(companyCode)
          setCompanyName(companyName)
    
          // Get the UID and position of the currently authenticated user
          const currentUser = firebase.auth().currentUser;
          const currentUserUID = currentUser.uid;
    
          // Fetch user's position based on UID
          const usersCollection = collection(db, 'users');
          const userDocRef = doc(usersCollection, currentUserUID);
          const userDocSnapshot = await getDoc(userDocRef);
    
          let currentUserPosition = "Stakeholder"; // Default position
    
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            currentUserPosition = userData.position || "Stakeholder";
          }
    
          let usersData = [];
    
          // If the current user is Admin, fetch all users
          if (currentUserPosition === "Admin") {
            usersData = await fetchUsers(companyCode);
          } else {
            // If the current user is Stakeholder, fetch only their data
            const allUsersData = await fetchUsers(companyCode);
            usersData = allUsersData.filter(user => user.uid === currentUserUID);
          }
    
          usersData.sort((a, b) => {
            const aTime = a.created_date?.seconds || 0;
            const bTime = b.created_date?.seconds || 0;
    
            return bTime - aTime;
          });
    
          setUsers(usersData);
          setIsLoading(false);
    
        } else {
          console.error('Company document not found');
          setIsLoading(false);
        }
    
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, columnTitle) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${columnTitle}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    sorter: (a, b) => a[dataIndex] - b[dataIndex], // Sorting function
    sortDirections: ['ascend', 'descend'],
  });

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };


  // Custom rendering function for the # column
  const renderRowNumber = (_, __, index) => {
    return index + 1;
  };

  const renderProfileImage = (photo_url) => {
    return photo_url ? (
      <img src={photo_url} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
    ) : (
      <i className="fa fa-user-circle" style={{ fontSize: '2.5em', color: '#c0c0c0' }}></i> 
    );
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'uid',
      key: 'uid',
      align: 'center', 
      render: renderRowNumber, // Apply custom rendering for row number
      sorter: (a, b) => a.uid - b.uid, // Sorting function for ID column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Profile',
      dataIndex: 'photo_url',
      key: 'photo_url',
      align: 'center', 
      render: renderProfileImage, // Apply custom rendering for profile image
      // No sorting for Profile column
    },
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      align: 'center', 
      ...getColumnSearchProps('display_name', 'Name'), // Apply filter for Name column
      sorter: (a, b) => a.display_name.localeCompare(b.display_name), // Sorting function for Name column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Code',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: () => companyCode, // Display the company_id fetched from useParams
      // No sorting or filtering for Code column
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center', 
      ...getColumnSearchProps('gender', 'Gender'), // Apply filter for Gender column
      sorter: (a, b) => a.gender.localeCompare(b.gender), // Sorting function for Gender column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center', 
      ...getColumnSearchProps('email', 'Email'), // Apply filter for Email column
      sorter: (a, b) => a.email.localeCompare(b.email), // Sorting function for Email column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      align: 'center', 
      ...getColumnSearchProps('phone_number', 'Contact'), // Apply filter for Contact column
      sorter: (a, b) => a.phone_number.localeCompare(b.phone_number), // Sorting function for Contact column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Location',
      dataIndex: 'address',
      key: 'address',
      align: 'center', 
      ...getColumnSearchProps('location', 'Location'), // Apply filter for Location column
      sorter: (a, b) => a.location.localeCompare(b.location), // Sorting function for Location column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'position',
      key: 'position',
      align: 'center', 
      ...getColumnSearchProps('position', 'Status'), // Apply filter for Status column
      sorter: (a, b) => a.position.localeCompare(b.position), // Sorting function for Status column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Joined',
      dataIndex: 'created_date',
      key: 'created_date',
      align: 'center', 
      render: renderDateRequested, // Apply date formatting
      sorter: (a, b) => a.created_time - b.created_time, // Sorting function for Joined column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center', 
      render: (_,record) => (
        <Space>
          <Click outline color='primary' style={{borderRadius:'10px' }} onClick={() => history.push(`/app/company/company-view/company-stakeholders/edit-stakeholder/${record.uid}`)}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </Click>
          <Click outline color='primary' style={{borderRadius:'10px' }}  onClick={() => history.push(`/app/company/company-view/company-stakeholders/profile-stakeholder/${record.uid}`)}>
            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
          </Click>
          {/* <Click outline color='primary' style={{borderRadius:'10px' }}>
            <i className="fa fa-lock" aria-hidden="true"></i>
          </Click>
          <Click outline color='danger' style={{borderRadius:'10px' }}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click> */}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/app/companies/company-view/${id}`}>View Stakeholders</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Company Stakeholders</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">{companyName} Stakeholders</h4>
        <Widget>
        {/* <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Click color="primary" outline size='sm' onClick={toggle}>
                <i class="fa fa-plus" aria-hidden="true"></i> Add Stakeholder
                </Click>
          </div> */}
            <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup>
                <Click color="primary" size='sm'>
                  CSV
                </Click>
                <Click color="primary" size='sm'>
                  PDF
                </Click>
                <ReactToPrint
                      trigger={() => (
                        <Click color="primary" disabled={printing} size='sm'>
                         {printing ? <Spinner size="sm" style={{ color: 'white' }}/> : <i className="fa fa-print" aria-hidden="true"></i>}
                        </Click>
                      )}
                      content={() => componentRef.current} // Specify the component to be printed
                      onBeforeGetContent={() => setPrinting(true)}
                      onAfterPrint={() => setPrinting(false)}
                    />
            </ButtonGroup>
          </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table
            columns={columns}
            dataSource={users}
            loading={isLoading}
            size="small"
          />
        </div>
        <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add new Stakeholder</ModalHeader>
        <ModalBody>
         <RegisterHolder id={id}/>
        </ModalBody>
        <ModalFooter>
          <Click color="danger" onClick={toggle}>
            Cancel
          </Click>
        </ModalFooter>
      </Modal>
        </Widget>
    </div>
  );
};

export default ViewStakeholders;
