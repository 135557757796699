import React, { useState, useEffect } from 'react';
import { Row, Col, Statistic } from 'antd';
import { collection, query, where, getDoc,doc,getDocs } from "firebase/firestore";
import {fetchFirebaseConfig} from '../../firebase';
import CountUp from 'react-countup';
import { Progress } from 'antd';
import UserCard from '../../components/UserWidgets/userCard';
import { Collapse,FormGroup,Label,Input } from 'reactstrap';

const StakeholderFinancialInfo = ({ uid }) => {

  const { db } = fetchFirebaseConfig();
  const [totalFunds, setTotalFunds] = useState('');
  const [totalShares, setTotalShares] = useState('');
  const [totalSharesAmount, setTotalSharesAmount] = useState('');
  const [equityValue, setEquityValue] = useState('');
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0); // State for pending requests count
  const [pendingRequestsTransCount, setPendingTransRequestsCount] = useState(0); // State for pending requests count
  const [totalTransactionAmount,setTotaltransactionAmount] = useState(0)
  const [companies, setCompanies] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [shares, setShares] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch total savings
        const sharesQuerySnapshot = await getDocs(
          query(collection(db, 'Shares'), where("user", "==", doc(db, "users", uid)))
        );
  
        sharesQuerySnapshot.forEach(async (sharesDocSnapshot) => {
          const sharesData = sharesDocSnapshot.data();
          setTotalShares(sharesData.shares);
          setEquityValue(sharesData.EquityValue);
  
          // Fetch CompanyShares subcollection of the user document
          const companySharesQuerySnapshot = await getDocs(collection(sharesDocSnapshot.ref, 'CompanyShares'));
  
          // Calculate total share amount from CompanyShares documents
          let totalShareAmount = 0;
          let totalShares = 0;
          companySharesQuerySnapshot.forEach(doc => {
            const companySharesData = doc.data();
            totalShareAmount += companySharesData.sharesAmount || 0; // Add ShareAmount to total
            totalShares += companySharesData.shares || 0; // Add ShareAmount to total
          });
  
          setTotalSharesAmount(totalShareAmount); // Set total share amount
          setTotalShares(totalShares); 
        });

        const fundsQuerySnapshot = await getDocs(
          query(collection(db, 'Funds'), where("user", "==", doc(db, "users", uid)))
        );
  
        fundsQuerySnapshot.forEach(async (fundsDocSnapshot) => {
          const fundsData = fundsDocSnapshot.data();
  
          // Fetch CompanyShares subcollection of the user document
          const companyFundsQuerySnapshot = await getDocs(collection(fundsDocSnapshot.ref, 'CompanyFunds'));
  
          // Calculate total share amount from CompanyShares documents
          let totalFundsAmount = 0;
          companyFundsQuerySnapshot.forEach(doc => {
            const companyFundsData = doc.data();
            totalFundsAmount += companyFundsData.totalAmount || 0; // Add ShareAmount to total
          });
  
          setTotalFunds(totalFundsAmount); // Set total share amount 
        });
  
        // Fetch count of pending requests
        const requestsCollection = collection(db, 'Requests');
        const requestsQuery = query(
          requestsCollection,
          where("user", "==", doc(db, "users", uid)),
          where("requestStatus", "==", "pending")
        );
        const requestsQuerySnapshot = await getDocs(requestsQuery);
        setPendingRequestsCount(requestsQuerySnapshot.size); // Set the count of pending requests

         // Fetch count of pending requests
         const transCollection = collection(db, 'AllTransactions');
         const transQuery = query(
           transCollection,
           where("user", "==", doc(db, "users", uid)),
           where("approvalStatus", "==", "pending")
         );
         const transQuerySnapshot = await getDocs(transQuery);
         setPendingTransRequestsCount(transQuerySnapshot.size); // Set the count of pending requests
  
        // Fetch total short-term loans
        const transactionsCollection = collection(db, 'AllTransactions');
        const transactionsQuery = query(
          transactionsCollection,
          where("user", "==", doc(db, "users", uid))
        );
        const transactionsQuerySnapshot = await getDocs(transactionsQuery);
        if (!transactionsQuerySnapshot.empty) {
          const transactionsDocSnapshot = transactionsQuerySnapshot.docs[0];
          const transactionsData = transactionsDocSnapshot.data();
          setTotaltransactionAmount(transactionsData.transactionAmount);
        } else {
          console.log("No savings document found for the user.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [uid]);


  const totalPending = pendingRequestsCount + pendingRequestsTransCount
  

    // Function to handle selection of companies
    const handleCompanySelection = (companyId) => {
      if (selectedCompanies.includes(companyId)) {
        // If already selected, remove from selectedCompanies
        setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId));
      } else {
        // If not selected, add to selectedCompanies
        setSelectedCompanies([...selectedCompanies, companyId]);
      }
    };


    useEffect(() => {
      const fetchShareDetails = async () => {
        try {
          // Fetch all shares of the user
          const sharesQuerySnapshot = await getDocs(
            query(collection(db, 'Shares'), where("user", "==", doc(db, "users", uid)))
          );
    
          const shareList = [];
    
          // Iterate through each share document
          await Promise.all(sharesQuerySnapshot.docs.map(async (sharesDocSnapshot) => {
            // Fetch CompanyShares subcollection of the user document
            const companySharesQuerySnapshot = await getDocs(collection(sharesDocSnapshot.ref, 'CompanyShares'));
    
            // Iterate through each CompanyShares document
            await Promise.all(companySharesQuerySnapshot.docs.map(async (companySharesDocSnapshot) => {
              const companySharesData = companySharesDocSnapshot.data();
              const shareValue = companySharesData.shares || 0;
              const shareAmount = companySharesData.sharesAmount || 0;
              const companyName = companySharesData.CompanyName || '';
    
              // Push share details to the list
              shareList.push({ id: companySharesDocSnapshot.id, companyName, shareValue, shareAmount });
            }));
          }));
    
          setShares(shareList);
        } catch (error) {
          console.error('Error fetching share details:', error);
        }
      };
    
      fetchShareDetails();
    }, [db, uid]);
    


  return (
    <div className="financial-summary">
      <h5>Financial Summary</h5>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <UserCard
            title="Total Shares"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalShares} duration={2} separator="," /></div>}
            icon={<i className="fa fa-share-alt icon-color" aria-hidden="true"></i>}
            color="#5790c9"
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Shares Amount"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalSharesAmount} duration={2} separator="," /></div>}
            icon={<i className="fa fa-dollar icon-color" aria-hidden="true"></i>}
            color="#28d456"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Total Transactions"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalTransactionAmount} duration={2} separator="," /></div>}
            icon={<i className="fa fa-exchange icon-color" aria-hidden="true"></i>}
            color="#e3206b"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Equity Value"
            value={<div style={{ fontWeight: 600 }}><CountUp end={equityValue} duration={2} separator="," /></div>}
            icon={<i className="fa fa-link icon-color" aria-hidden="true"></i>}
            color="#762aad"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Total Funds"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalFunds} duration={2} separator="," /></div>}
            icon={<i className="fa fa-money icon-color" aria-hidden="true"></i>}
            color="#4b3e75"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Pending Requests"
            value={<div style={{ fontWeight: 600 }}><CountUp end={totalPending} duration={2} separator="," /></div>}
            icon={<i className="fa fa-commenting icon-color" aria-hidden="true"></i>}
            color="#2aabad"
          />
        </Col>
        <Col span={12}>
          <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        onChange={() => setCollapseOpen(!collapseOpen)}
                        checked={collapseOpen}
                      />
                      View Shares
                    </Label>
                  </FormGroup>
                  <Collapse isOpen={collapseOpen}>
                  {shares.map((share) => (
                    <div key={share.id}>
                      <p>
                        <span style={{color:'#5790c9', fontWeight:'bold'}}>{share.companyName}</span> - Shares:&nbsp;<span style={{fontWeight:'bold'}}>{share.shareValue.toLocaleString()}</span> &nbsp;( Tsh <span style={{ fontWeight: 600 }}>{share.shareAmount.toLocaleString()}</span>)
                      </p>
                      <Progress percent={(share.shareValue / totalShares) * 100} status="active" style={{marginBottom:'20px', marginTop:'-40px'}} />
                    </div>
                  ))}
                </Collapse>
             </Col>
      </Row>
    </div>
  );
};

export default StakeholderFinancialInfo;
