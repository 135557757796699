import React, { useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import FinancialCategoriesChart from './userFinanceComparison';
import TransactionTrendChart from './userTransactionsLineTrend';
import Widget from '../../components/Widget/Widget';

const UserDataCharts = ({ uid }) => {
  const [activeTab, setActiveTab] = useState('financialCategories');

  return (
    <div>
      <Row>
        <Col xs={24}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'financialCategories' ? 'active' : ''}
                onClick={() => setActiveTab('financialCategories')}
                style={activeTab === 'financialCategories' ? { backgroundColor: '#3754a5', color: 'white', borderColor: '#3754a5' } : {}}
              >
                <span style={{ fontSize: '12px' }}>Financial Categories</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'transactionTrend' ? 'active' : ''}
                onClick={() => setActiveTab('transactionTrend')}
                style={activeTab === 'transactionTrend' ? { backgroundColor: '#3754a5', color: 'white', borderColor: '#3754a5' } : {}}
              >
                <span style={{ fontSize: '12px' }}>Transaction's Trend</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>

        <Col xs={12} className={activeTab !== 'financialCategories' ? 'd-none' : ''}>
        <Widget
           style={{ height: '300px'}} 
           title={<h6><span className="fw-semi-bold">Financial Category comparisons</span></h6>}
          >
            <FinancialCategoriesChart uid={uid} />
          </Widget>
        </Col>

        <Col xs={12} className={activeTab !== 'transactionTrend' ? 'd-none' : ''}>
        <Widget
           style={{ height: '300px'}} 
           title={<h6><span className="fw-semi-bold">Loans Monthly Trend</span></h6>}
          >
              <TransactionTrendChart uid={uid} />
            </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default UserDataCharts;
