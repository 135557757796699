import React, { useState,useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { useParams,useHistory,Link} from "react-router-dom";
import { doc, getDoc,updateDoc,getDocs,collection } from "firebase/firestore";
import {fetchFirebaseConfig} from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Breadcrumb } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import Widget from '../../components/Widget/Widget';
//import 'react-toastify/dist/ReactToastify.css';


const EditStakeholder = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [position, setPosition] = useState("Member");
  const [positionOptions, setPositionOptions] = useState([]);
  const [gender, setGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [initialName, setInitialName] = useState('');
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [profile, setProfile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);



  const { uid } = useParams(); // Get the UID from the URL

  const { db,storage } = fetchFirebaseConfig();

  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!uid) {
          console.error("UID is undefined");
          return;
        }

        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setInitialName(userData.display_name || '');
          setName(userData.display_name || '');
          setProfile(userData.photo_url|| '');
          setEmail(userData.email|| '');
          setPassword(userData.password|| '');
          setGender(userData.gender || 'Male');
          setPosition(userData.position || 'Member');
          setAddress(userData.address || '');
          setContact(userData.mobile || '');
                  if (selectedProfilePicture) {
            setPreviewUrl(URL.createObjectURL(selectedProfilePicture)); // Update preview URL
          }
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

      fetchUserData();
    }, [db, uid, selectedProfilePicture]);

    
    useEffect(() => {
      const fetchPermissions = async () => {
          try {
              const { db } = fetchFirebaseConfig();
              const permissionsCollection = collection(db, 'permissions');
              const permissionsSnapshot = await getDocs(permissionsCollection);
              const permissionsData = permissionsSnapshot.docs.map(doc => doc.data());
              const positions = permissionsData.map(permission => permission.Role);
              setPositionOptions(positions);
          } catch (error) {
              console.error('Error fetching permissions:', error);
          }
      };

      fetchPermissions();
  }, []);


      const handleEdit = async () => {

        setIsSubmitting(true);

        try {
          const userDocRef = doc(db, 'users', uid);
          await updateDoc(userDocRef, {
            display_name: name,
            gender,
            position,
            address: address,
            mobile: contact,
          });
        
          // Upload the new profile picture if selected
          if (selectedProfilePicture) {
            const storageRef = ref(storage, `users/${uid}`);
            await uploadBytes(storageRef, selectedProfilePicture);
            const newPhotoUrl = await getDownloadURL(storageRef);    
        
            // Update the user document with the new profile picture URL
            await updateDoc(userDocRef, { photo_url: newPhotoUrl });
          }

          setIsSubmitting(false);
          
        //   toast.success('member info updated successfully!');
                 
        } catch (error) {
        //   toast.error('Error while updating user info');
        }
        
      };

  return (
        <div>
       <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/app/stakeholders/all-stakeholders">View Stakeholders</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Edit Profile</Breadcrumb.Item>
      </Breadcrumb>
        <h4 className="mb-lg">Edit Profile Details</h4>
            <Widget
                title={<h5>Update {initialName}&apos;s information</h5>}
                >
                <Form>
                  <p className="text-medium-emphasis">Edit member details</p>
                  {/* Profile Picture */}
                  <div style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-center">
                  {previewUrl ? (
                    <img
                      className="profileImg"
                      src={previewUrl}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                      alt="Profile Preview"
                    />
                  ) : profile ? (
                    <img
                      className="profileImg"
                      src={profile}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                    />
                  ) : (
                    <i className="fa fa-user-circle" style={{ fontSize: '7em', color: '#c0c0c0' }}></i>
                  )}
                </div>
                  <FormGroup className="mb-3">
                  <Label>Update image</Label>
                    <Input
                        type="file"
                        id="profileInput"
                        accept="image/*"
                        style={{fontSize:'13px'}}
                        onChange={(e) => {
                          setSelectedProfilePicture(e.target.files[0]);
                          setPreviewUrl(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                  </FormGroup>
                  {/* Name/Display Name */}
                  <FormGroup className="mb-3">
                  <Label>Name</Label>
                    <Input
                      placeholder="Username"
                      autoComplete="name"
                      value={name}
                      size="sm"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  {/* Email */}
                  <FormGroup className="mb-3">
                  <Label>Email</Label>
                    <Input
                      placeholder="Email"
                      autoComplete="email"
                      value={email}
                      size="sm"
                      disabled={true}
                    />
                  </FormGroup>

                  {/* Password */}
                  <FormGroup className="mb-3">
                  <Label>Password</Label>
                    <Input
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      value={password}
                      size="sm"
                      disabled={true}
                    />
                  </FormGroup>
                  {/* Gender */}
                  <FormGroup className="mb-3">
                  <Label>Gender</Label>
                    <Input size="sm" type='select' onChange={(e) => setGender(e.target.value)} value={gender}>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Input>
                  </FormGroup>

                  {/* Contact/Phone Number */}
                  <FormGroup className="mb-3">
                  <Label>Mobile</Label>
                    <Input
                      placeholder="Contact"
                      value={contact}
                      size="sm"
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </FormGroup>

                  {/* Position */}
                  <FormGroup className="mb-3">
                  <Label>Access permission</Label>
                    <Input
                            onChange={(e) => setPosition(e.target.value)}
                            value={position}
                            size="sm"
                            type='select'
                            >
                          <option value="Member">Member</option>
                           {positionOptions.map((option, index) => (
                           <option key={index} value={option}>{option}</option>
                            ))}
                     </Input>
                  </FormGroup>
                  {/* Address/Location */}
                  <FormGroup className="mb-3">
                  <Label>Address</Label>
                    <Input
                      placeholder="Address"
                      value={address}
                      size="sm"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormGroup>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                  <Button color="primary" onClick={handleEdit}>
                   Edit
                 </Button>
                </div>
                  </Form>
                </Widget>
        </div>
    );
  };

export default EditStakeholder;
