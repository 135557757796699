import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input as InputData, Breadcrumb, BreadcrumbItem,Form,Col,Row } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { collection, getDocs } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';

const TrialBalance = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [trialBalanceData, setTrialBalanceData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState([]);
  const [loading, setLoading] = useState(false); 
  const searchInput = useRef(null);
  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { db } = fetchFirebaseConfig();

      // Fetch data from the "Assets" collection
      const assetsCollection = collection(db, 'Assets');
      const assetsSnapshot = await getDocs(assetsCollection);
      const assetsData = assetsSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Assets' }));

      // Fetch data from the "Liabilities" collection
      const liabilitiesCollection = collection(db, 'Liabilities');
      const liabilitiesSnapshot = await getDocs(liabilitiesCollection);
      const liabilitiesData = liabilitiesSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Liabilities' }));

      // Fetch data from the "Income" collection
      const incomeCollection = collection(db, 'Income');
      const incomeSnapshot = await getDocs(incomeCollection);
      const incomeData = incomeSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Income' }));

      // Fetch data from the "Expenses" collection
      const expensesCollection = collection(db, 'Expenses');
      const expensesSnapshot = await getDocs(expensesCollection);
      const expensesData = expensesSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Expenses' }));

      // Combine data from different collections
      let trialBalance = [...assetsData, ...liabilitiesData, ...incomeData, ...expensesData];

      // Fetch data from the "Journal" collection
      const journalCollection = collection(db, 'Journal');
      const journalSnapshot = await getDocs(journalCollection);
      const journalData = journalSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      // Update the trialBalanceData based on Journal entries and selected account
      trialBalance = trialBalance.map(account => {
        const matchingEntries = journalData.flatMap(entry => entry.entries.filter(e => e.debit_account === account.account_name || e.credit_account === account.account_name));
        
        const totalDebit = matchingEntries.reduce((total, entry) => total + entry.debit_amount, 0);
        const totalCredit = matchingEntries.reduce((total, entry) => total + entry.credit_amount, 0);

        return { ...account, debit: totalDebit, credit: totalCredit };
      });

      // Filter by selected account if an account is selected
      const filteredData = selectedAccount ? trialBalance.filter(item => item.account_name === selectedAccount) : trialBalance;

      setTrialBalanceData(filteredData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data from Firebase:', error);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const handleFilter = async () => {
    try {
      setLoading(true);
      
      // Fetch data from the "Journal" collection
      const journalCollection = collection(db, 'Journal');
      const journalSnapshot = await getDocs(journalCollection);
      const journalData = journalSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  
      // Filter journal entries based on the selected start and end dates
      const filteredJournalData = journalData.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate >= new Date(startDate) && entryDate <= new Date(endDate);
      });
  
      // Fetch data from the other collections (Assets, Liabilities, Income, Expenses)
      const assetsCollection = collection(db, 'Assets');
      const assetsSnapshot = await getDocs(assetsCollection);
      const assetsData = assetsSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Assets' }));
  
      const liabilitiesCollection = collection(db, 'Liabilities');
      const liabilitiesSnapshot = await getDocs(liabilitiesCollection);
      const liabilitiesData = liabilitiesSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Liabilities' }));
  
      const incomeCollection = collection(db, 'Income');
      const incomeSnapshot = await getDocs(incomeCollection);
      const incomeData = incomeSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Income' }));
  
      const expensesCollection = collection(db, 'Expenses');
      const expensesSnapshot = await getDocs(expensesCollection);
      const expensesData = expensesSnapshot.docs.map(doc => ({ ...doc.data(), category: 'Expenses' }));
  
      // Combine data from different collections
      let trialBalance = [...assetsData, ...liabilitiesData, ...incomeData, ...expensesData];
  
      // Update the trialBalanceData based on filtered Journal entries
      trialBalance = trialBalance.map(account => {
        const matchingEntries = filteredJournalData.flatMap(entry => entry.entries.filter(e => e.debit_account === account.account_name || e.credit_account === account.account_name));
  
        const totalDebit = matchingEntries.reduce((total, entry) => {
          if (entry.debit_account === account.account_name) return total + entry.debit_amount;
          return total;
        }, 0);
  
        const totalCredit = matchingEntries.reduce((total, entry) => {
          if (entry.credit_account === account.account_name) return total + entry.credit_amount;
          return total;
        }, 0);
  
        return { ...account, debit: totalDebit, credit: totalCredit };
      });
  
      // Filter by selected account if an account is selected
      const filteredData = selectedAccount ? trialBalance.filter(item => item.account_name === selectedAccount) : trialBalance;
  
      setTrialBalanceData(filteredData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data from Firebase:', error);
    }
  };


  const handleRedo = () => {
    setEndDate('');
    setSelectedAccount('');
    setStartDate('');
    fetchData();
  };
  
useEffect(() => {
  const fetchAccountNames = async () => {
    try {
      // Your logic to fetch account names from Firestore collections
      const assetsSnapshot = await getDocs(collection(db, 'Assets'));
      const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
      const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
      const incomeSnapshot = await getDocs(collection(db, 'Income'));

      const assetsNames = assetsSnapshot.docs.map((doc) => doc.data().account_name);
      const liabilitiesNames = liabilitiesSnapshot.docs.map((doc) => doc.data().account_name);
      const expensesNames = expensesSnapshot.docs.map((doc) => doc.data().account_name);
      const incomeNames = incomeSnapshot.docs.map((doc) => doc.data().account_name);

      // Combine all account names into one array
      const allAccountNames = [...assetsNames, ...liabilitiesNames, ...expensesNames, ...incomeNames];
      // Remove duplicates and set as options for the dropdown
      setAccountOptions([...new Set(allAccountNames)]);
    } catch (error) {
      console.error('Error fetching account names: ', error);
    }
  };

  fetchAccountNames();
}, [db]);
  

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, record, index) => {
        if (record.key !== 'total') {
          return index + 1; // Render the row number for all rows except the "Total" row
        }
        return null; // Return null to prevent rendering for the "Total" row
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      ...getColumnSearchProps('category'),
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text}</b>; // Render the text in bold for the "Total" row
        }
        return text;
      },
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      ...getColumnSearchProps('account_name'),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text.toLocaleString()}</b>; // Render the text in bold for the "Total" row
        }
        return text.toLocaleString();
      },
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      align: 'center',
      sorter: (a, b) => a.credit - b.credit,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text.toLocaleString()}</b>; // Render the text in bold for the "Total" row
        }
        return text.toLocaleString();
      },
    },
  ];
  
  // Calculate total debit and credit
  const totalDebit = trialBalanceData.reduce((total, item) => total + item.debit, 0);
  const totalCredit = trialBalanceData.reduce((total, item) => total + item.credit, 0);
  
  const formattedTotalDebit = totalDebit.toLocaleString();
  const formattedTotalCredit = totalCredit.toLocaleString();
  
  const totalRow = {
    key: 'total',
    category: 'Total',
    account_name: '',
    debit: formattedTotalDebit,
    credit: formattedTotalCredit,
    isTotalRow: true, // Add a flag to identify the total row
  };

  const tableData = [...trialBalanceData, totalRow];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Trial-balance</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Trial balance</h4>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleAccName">Account name</Label>
                      <InputData
                        id="accName" name="accountname" placeholder="Account name" type="select" bsSize="sm"
                        value={selectedAccount}
                        onChange={(e) => setSelectedAccount(e.target.value)}
                      >
                       <option value="">Select Account</option>
                        {accountOptions.map((accountName) => (
                          <option key={accountName} value={accountName}>
                            {accountName}
                          </option>
                        ))}
                        </InputData>
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div>
           </Widget>
      <Widget>
      <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
        <Table columns={columns} dataSource={tableData} loading={loading} size='small' />
      </div>
      </Widget>
    </div>
  );
};

export default TrialBalance;

