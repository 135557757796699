import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { countUsers, getTotalApprovedLoanAmount, getTotalSavingsAmount, getShares, getSharesDifferenceAmount, fetchEligibleLoansCount, fetchTotalUsersCount, fetchActiveSavingsCount, getTotalSharesAmount, getTotalDeposits, countCompanies } from './data';
import StatsCard from '../../../components/StatsCard/statsCard';

const DashboardWidgets = () => {
  const [userCount, setUserCount] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalSharesAmount, setTotalSharesAmount] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [sharesAmount, setSharesAmount] = useState(0);
  const [sharesDifferenceAmount, setSharesDifferenceAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [eligibleLoansCount, setEligibleLoansCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [activeSavingsCount, setActiveSavingsCount] = useState(0);
  const [totalDepositsAmount, setTotalDepositsAmount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const count = await countUsers();
        setUserCount(count);
        const cocount = await countCompanies();
        setCompaniesCount(cocount);
        const totalAmount = await getTotalApprovedLoanAmount();
        setTotalApprovedLoanAmount(totalAmount);
        const formattedTotalSavingsAmount = await getTotalSavingsAmount();
        setTotalSavings(formattedTotalSavingsAmount);
        const formattedTotalShares = await getShares();
        setTotalShares(formattedTotalShares);
        const formattedDifferenceAmount = await getSharesDifferenceAmount();
        setSharesDifferenceAmount(formattedDifferenceAmount);
        const loansCount = await fetchEligibleLoansCount();
        setEligibleLoansCount(loansCount);
        const usersCount = await fetchTotalUsersCount();
        setTotalUsersCount(usersCount);
        const savingsCount = await fetchActiveSavingsCount();
        setActiveSavingsCount(savingsCount);
        const sharesAmount = await getTotalSharesAmount();
        setTotalSharesAmount(sharesAmount);
        const depositsAmount = await getTotalDeposits();
        setTotalDepositsAmount(depositsAmount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Row>
      <Col sm={12} md={6} lg={3}>
           {/* <StatsCard
            title="Members"
            icon={<i className="fa fa-users icon-color" aria-hidden="true"></i>} // Example icon, replace with your desired icon
          >
            <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp  end={userCount} duration={2} separator="," />}</div>
          </StatsCard> */}
                 <StatsCard
        title="Total Stakeholders"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={userCount} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-group icon-color" aria-hidden="true"></i>} 
        color="#28d456"// Example icon, replace with your desired icon
       />
          </Col>
      <Col sm={12} md={6} lg={3}>
        {/* <StatsCard
          title="Loans"
          icon={<i className="fa fa-bank icon-color" aria-hidden="true"></i>} // Example icon, replace with your desired icon
        >
          <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp end={totalApprovedLoanAmount} duration={2} separator="," />}</div>
        </StatsCard> */}
       <StatsCard
        title="Total Transactions"
        value={<div style={{fontWeight: 600 }}>{<CountUp end={totalApprovedLoanAmount} duration={2} separator="," />}</div>}
        icon={<i class="fa fa-exchange" aria-hidden="true"></i>} // Example icon, replace with your desired icon
        color="#5790c9"
        showTSH={true}  // Show TSH label
       />
      </Col>
      <Col sm={12} md={6} lg={3}>
        {/* <StatsCard
          title="Savings"
          icon={<i className="fa fa-balance-scale icon-color" aria-hidden="true"></i>} // Example icon, replace with your desired icon
        >
          <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp end={totalSavings} duration={2} separator="," />}</div>
        </StatsCard> */}
       <StatsCard
        title=" Total Disbursements"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSavings} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-money icon-color" aria-hidden="true"></i>} 
        color="#e3206b"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
      </Col>
      <Col sm={12} md={6} lg={3}>
        {/* <StatsCard
        title="Deposits"
        icon={<i className="fa fa-credit-card icon-color" aria-hidden="true"></i>} // Example icon, replace with your desired icon
      >
        <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp end={totalDepositsAmount} duration={2} separator="," />}</div>
      </StatsCard> */}
      <StatsCard
        title="Total Deposits"
        value={<div style={{fontWeight: 600 }}>{<CountUp end={totalDepositsAmount} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-credit-card icon-color" aria-hidden="true"></i>}
        color="#4b3e75" // Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
      />
      </Col>
      <Col sm={12} md={6} lg={3}>
        {/* <StatsCard
        title="App Downloads"
        icon={<i className="fa fa-android icon-color" aria-hidden="true"></i>} // Example icon, replace with your desired icon
      >
        <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp end={totalUsersCount} duration={2} separator="," />}</div>
      </StatsCard> */}
      <StatsCard
        title="App Downloads"
        value={<div style={{fontWeight: 600 }}>{<CountUp end={totalUsersCount} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-android icon-color" aria-hidden="true"></i>}
        color="#e35f27" // Example icon, replace with your desired icon
      />
      </Col>
      <Col sm={12} md={6} lg={3}>
      {/* <StatsCard
        title="Shares"
        icon={<i class="fa fa-suitcase" aria-hidden="true"></i>} // Example icon, replace with your desired icon
      >
        <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp end={sharesDifferenceAmount} duration={2} separator="," />}</div>
      </StatsCard> */}
      <StatsCard
        title="Total Shares"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalShares} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-suitcase icon-color" aria-hidden="true"></i>} 
        color="#adc234"// Example icon, replace with your desired icon
         // Show TSH label
      />
      </Col>
      <Col sm={12} md={6} lg={3}>
        {/* <StatsCard
        title="Savings Accounts"
        icon={<i className="fa fa-user icon-color" aria-hidden="true"></i>} // Example icon, replace with your desired icon
      >
        <div style={{fontSize:'17px', fontWeight:600}}>{<CountUp end={activeSavingsCount} duration={2} separator="," />}</div>
      </StatsCard> */}
      <StatsCard
        title="Total Companies"
        value={<div style={{fontWeight: 600 }}>{<CountUp end={companiesCount} duration={2} separator="," />}</div>}
        icon={<i className="fa fa-building icon-color" aria-hidden="true"></i>} 
        color="#2aabad"// Example icon, replace with your desired icon
      />
      </Col>
      <Col sm={12} md={6} lg={3}>
      <StatsCard
          title="Shares Issuance"
          value={<div style={{fontWeight: 600 }}>{<CountUp end={totalSharesAmount} duration={2} separator="," />}</div>}
          icon={<i class="fa fa-share-alt" aria-hidden="true"></i>} // Example icon, replace with your desired icon
          color="#762aad"
          showTSH={true}  // Show TSH label
        />
      </Col>
    </Row>
  );
}

export default DashboardWidgets;
