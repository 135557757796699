import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import {
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { Button as Click } from 'reactstrap';
import { useHistory } from "react-router-dom";

const Permissions = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const searchInput = useRef(null);

  const history=useHistory();

  const { db } = fetchFirebaseConfig();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'permissions'), (snapshot) => {
      const permissionsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(permissionsData);
      setLoading(false);  // Setting loading to false after data is fetched
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const handleAddRole = () =>{
    history.push("/app/roles-permissions/add-Role")
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Role',
      dataIndex: 'Role',
      key: 'Role',
      align: 'center',
      ...getColumnSearchProps('Role'), // Corrected dataIndex here
    },
    {
      title: 'Created On',
      dataIndex: 'updated_At',
      key: 'updated_At',
      align: 'center',
      sorter: (a, b) => new Date(a.updated_At) - new Date(b.updated_At), // Corrected sorting function
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space>
          <Click color='success' size='sm' outline style={{ borderRadius: '10px' }} onClick={() => history.push(`/app/roles-permissions//edit-role/${record.id}`)}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </Click>
          <Click color='danger' size='sm' outline style={{ borderRadius: '10px' }}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Permissions</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Roles & Permissions</h4>
      <Widget>
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Click color="success" size="sm" onClick={handleAddRole}>
              <i className="fa fa-plus" aria-hidden="true"></i> Add Role
            </Click>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
        <Table columns={columns} dataSource={data} loading={loading} size='small' />
        </div>
      </Widget>
    </div>
  );
};

export default Permissions;
