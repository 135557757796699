import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Breadcrumb, BreadcrumbItem, Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 

const LoanRecords = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState('select category');  // State for category input
  const [startingAmount, setStartingAmount] = useState('');     // State for starting amount input
  const [endingAmount, setEndingAmount] = useState('');         // State for ending amount input

  const componentRef = useRef(null);

  const fetchLoanDataAndSetState = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();

      // Create a reference to the Loans collection
      const loansCollectionRef = collection(db, 'Loans');

      // Query loans where loanSchedual is "Long Term Loan"
      const querySnapshot = await getDocs (loansCollectionRef);

      const newLoansData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Sort the loans data based on the dateTaken field in descending order
      const sortedNewLoansData = newLoansData.sort((a, b) => b.dateTaken.seconds - a.dateTaken.seconds);

      // Filter out loans with loanAmount equal to zero
      const filteredNewLoansData = sortedNewLoansData.filter((loan) => loan.loanAmount !== 0);

      setData(filteredNewLoansData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLoanDataAndSetState();
  }, []);

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollectionRef = collection(db, 'Loans');
      let loansQuery = query(loansCollectionRef);
  
      // Add filters for start and end dates if provided
      if (startDate && endDate) {
        // Convert start and end dates to Date objects
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        
        // Filter loans based on the full date range, existence, and non-blank loanSchedual field
        loansQuery = query(
          loansCollectionRef,
          where('dateTaken', '>=', startDateObj),
          where('dateTaken', '<=', endDateObj),
        );
      } else {
        // If start or end date is not provided, set data state to an empty array
        setData([]);
        setLoadingData(false);
        return;
      }
  
      // Add filters for category, starting amount, and ending amount if provided
      if (category !== 'select category' && startingAmount.trim() !== '' && endingAmount.trim() !== '') {
        // Determine the column based on the selected category
        let column;
        if (category === 'Loan Request') {
          column = 'loanAmount1';
        } else if (category === 'Outstanding Balance') {
          column = 'amountTobeReturned';
        } else if (category === 'Amount Paid') {
          column = 'amountPaid';
        } else if (category === 'Remaining Balance') {
          column = 'amountRemaining';
        }
  
        // Apply filters based on the determined column and starting/ending amounts
        loansQuery = query(
          loansQuery,
          where(column, '>=', parseInt(startingAmount)),
          where(column, '<=', parseInt(endingAmount))
        );
      }
        
      const querySnapshot = await getDocs(loansQuery);
      const newLoansData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Sort the loans data based on the dateTaken field in descending order
      const sortedNewLoansData = newLoansData.sort((a, b) => b.dateTaken.seconds - a.dateTaken.seconds);
  
      // Filter out loans with loanAmount equal to zero
      const filteredNewLoansData = sortedNewLoansData.filter(loan => loan.loanAmount !== 0);
  
      setData(filteredNewLoansData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  
  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    });
    return formattedDate;
  }
  return null;
};

const formatNumber = (number) => {
  return number?.toLocaleString();
};

const renderLoanState = (loanState) => {
  return loanState === "Approved" ? (
    <Badge color="success" style={{ borderRadius: '5px' }}>
      <span style={{ color: 'white' }}>Approved</span>
    </Badge>
  ) : loanState === "Pending" ? (
    <Badge color="info" style={{ borderRadius: '5px' }}>
      <span style={{ color: 'white' }}>Pending</span>
    </Badge>
  ) : (
    <Badge color="danger" style={{ borderRadius: '5px' }}>
      <span style={{ color: 'white' }}>Denied</span>
    </Badge>
  );
};

const handleRedo = () => {
  fetchLoanDataAndSetState();
  setEndDate('');
  setStartDate('');
};

const columns = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, __, index) => index + 1,
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Name',
    dataIndex: 'userName',
    key: 'userName',
    align: 'center',
    sorter: (a, b) => a.userName - b.userName,
  },
  {
    title: 'Loan Request',
    dataIndex: 'loanAmount1',
    key: 'loanAmount1',
    align: 'center',
    sorter: (a, b) => a.loanAmount1 - b.loanAmount1,
    sortDirections: ['descend', 'ascend'],
    render: (text) => formatNumber(text),
  },
  {
    title: 'Loan Term',
    dataIndex: 'loanSchedual',
    key: 'loanSchedual',
    align: 'center',
    sorter: (a, b) => a.loanSchedual - b.loanSchedual,
  },
  {
    title: 'Outstanding Balance',
    dataIndex: 'amountTobeReturned',
    key: 'amountTobeReturned',
    align: 'center',
    sorter: (a, b) => a.amountTobeReturned - b.amountTobeReturned,
    render: (text) => formatNumber(text),
  },
  {
    title: 'Amount Paid',
    dataIndex: 'amountPaid',
    key: 'amountPaid',
    align: 'center',
    sorter: (a, b) => a.amountPaid - b.amountPaid,
    sortDirections: ['descend', 'ascend'],
    render: (text) => formatNumber(text),
  },
  {
    title: 'Remaining Balance',
    dataIndex: 'amountRemaining',
    key: 'amountRemaining',
    align: 'center',
    sorter: (a, b) => a.amountRemaining - b.amountRemaining,
    render: (text) => formatNumber(text),
  },
  {
    title: 'Payment Status',
    dataIndex: 'loanStatus',
    key: 'loanStatus',
    align: 'center',
    sorter: (a, b) => a.loanStatus - b.loanStatus,
  },
  {
    title: 'Requested On',
    dataIndex: 'dateTaken',
    key: 'dateTaken',
    align: 'center',
    sorter: (a, b) => a.dateTaken - b.dateTaken,
    render: renderDateRequested,
    render: (text) => new Date(text.seconds * 1000).toLocaleDateString(),
  },
  {
    title: 'Payment Due On',
    dataIndex: 'payOutDate',
    key: 'payOutDate',
    align: 'center',
    sorter: (a, b) => a.payOutDate - b.payOutDate,
    render: renderDateRequested,
  },
  {
    title: 'Approval Status',
    dataIndex: 'loanState',
    key: 'loanState',
    align: 'center',
    render: renderLoanState,
  },
];

return (
  <div>
    <Breadcrumb>
      <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
      <BreadcrumbItem active>Loan Records</BreadcrumbItem>
    </Breadcrumb>
    <h5 className="mb-lg">Saccos Loan Records</h5>
    <Widget
      title={<h7><span className="fw-semi-bold">Set Filtering parameters</span></h7>}
    >
      <Row style={{ marginTop: '10px' }}>
        <Col md={6}>
          <FormGroup>
            <Label for="exampleFirstName">Start Date</Label>
            <InputData
              id="exampleFirstName"
              name="startDate"
              placeholder="Start Date"
              type="date"
              bsSize="sm"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="exampleMiddleName">End Date</Label>
            <InputData
              id="endDate"
              name="middlename"
              placeholder="End date"
              type="date"
              bsSize="sm"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginTop: '2px' }}>
        <Col md={4}>
          <FormGroup>
            <Label for="exampleAmount">Category</Label>
            <InputData
              id="endAmount"
              name="middlename"
              placeholder="End Amount"
              type="select"
              bsSize="sm"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="select category">Select Category</option>
              <option value="Loan Request">Loan Request</option>
              <option value="Outstanding Balance">Outstanding Balance</option>
              <option value="Amount Paid">Amount Paid</option>
              <option value="Remaining Balance">Remaining Balance</option>
            </InputData>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="exampleFirstName">Starting Amount</Label>
            <InputData
              id="exampleFirstName"
              name="startDate"
              placeholder="Start Amount"
              type="number"
              bsSize="sm"
              value={startingAmount}
              onChange={(e) => setStartingAmount(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="exampleMiddleName">Ending Amount</Label>
            <InputData
              id="endDate"
              name="middlename"
              placeholder="End Amount"
              type="number"
              bsSize="sm"
              value={endingAmount}
              onChange={(e) => setEndingAmount(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Click type='submit' size='sm' color='success' onClick={handleFilter}>Filter</Click>
        <div style={{ marginLeft: '10px' }}></div>
        <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
      </div>
    </Widget>
    <Widget>
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonGroup>
          <Click color="success" size='sm'>
            CSV
          </Click>
          <Click color="success" size='sm'>
            PDF
          </Click>
          <ReactToPrint
            trigger={() => (
              <Click color="success" disabled={printing} size='sm'>
                {printing ? <Spinner size="sm" style={{ color: 'white' }} /> : <i className="fa fa-print" aria-hidden="true"></i>}
              </Click>
            )}
            content={() => componentRef.current} // Specify the component to be printed
            onBeforeGetContent={() => setPrinting(true)}
            onAfterPrint={() => setPrinting(false)}
          />
        </ButtonGroup>
      </div>
      <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
        <Table columns={columns} dataSource={data}  loading={loadingData} size='small' />
      </div>
    </Widget>
  </div>
);
};

export default LoanRecords;


       
