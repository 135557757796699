import React, { useRef, useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem,Form,Col,Row } from 'reactstrap';
import { Button, Space, Table, Spin } from 'antd'; // Imported Spin for loading indicator
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { getDocs, collection,query,where, deleteDoc, doc } from 'firebase/firestore'; // Added deleteDoc and doc
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import AddEntry from './addJournal';

const Journals = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);
  const history = useHistory();
  const { db } = fetchFirebaseConfig();

  const fetchData = async () => {
    try {
      setLoading(true);
      const collectionSnapshot = await getDocs(collection(db, 'Journal'));
      const fetchedData = collectionSnapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }));
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [db]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const EditJournal = (id) => {
    history.push(`/app/accounting/account-journals/edit-entry/${id}`);
  };

  const handleDeleteEntry = async (docId) => {
    try {
      if (!docId) {
        console.error('Document ID is empty or undefined.');
        return;
      }

      await deleteDoc(doc(collection(db, 'Journal'), docId));

      // Fetch updated data after deletion
      const collectionSnapshot = await getDocs(collection(db, 'Journal'));
      const updatedData = collectionSnapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }));

      // Update state with updated data
      setData(updatedData);

      console.log('Entry deleted successfully.');
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handleFilter = async () => {
    try {
      setLoading(true);
      const collectionSnapshot = await getDocs(query(collection(db, 'Journal'), 
        where('date', '>=', startDate),
        where('date', '<=', endDate)
      ));
      const fetchedData = collectionSnapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }));
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  };

  const handleRedo = () => {
    fetchData();
    setEndDate('');
    setStartDate('');
  };

  const handleAddEntry= ()=>{
     history.push("/app/accounting/account-journals/add-entry")
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      ...getColumnSearchProps('date'),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space>
          <Click color='success' outline style={{ borderRadius: '10px' }} onClick={()=> EditJournal(record.key)}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </Click>
          <Click color='danger' outline style={{ borderRadius: '10px' }} onClick={() => handleDeleteEntry(record.key)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Journals</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Account Journals</h4>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <Input
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <Input
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div>
           </Widget>
       <Widget>  
       <div className="mb-3" style={{ display: 'flex', justifyContent: 'flex-start' }}>
       <Click color='success' size='sm' onClick={handleAddEntry}>Add Journal</Click> {/* Button to trigger Add Account Modal */}
      </div> 
      <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <Table columns={columns} dataSource={data} loading={loading} size='small'/>
      </div>
     </Widget> 
    </div>
  );
};

export default Journals;
