import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, Collapse} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { fetchFirebaseConfig2 } from '../../firebaseClient';
import { collection, doc, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { createUserAndUploadProfile } from './RegisterData';
import { Breadcrumb } from 'antd';

const RegisterHolder = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingMember, setIsCreatingMember] = useState(false);
  const [gender, setGender] = useState('Male');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [sirName, setSirName] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]); // State to store selected company IDs
  const history = useHistory();
  const { auth, db, storage } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companiesCollection = collection(db, 'Companies');
        const companySnapshot = await getDocs(companiesCollection);
        const companyList = [];
        companySnapshot.forEach((doc) => {
          const companyData = doc.data();
          companyList.push({ id: doc.id, name: companyData.CompanyName, company_id: companyData.company_id }); // Modify here to include company_id field
        });
        setCompanies(companyList);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };
    fetchCompanyDetails();
  }, [db]);
  

  // Function to handle selection of companies
  const handleCompanySelection = (companyId) => {
    if (selectedCompanies.includes(companyId)) {
      // If already selected, remove from selectedCompanies
      setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId));
    } else {
      // If not selected, add to selectedCompanies
      setSelectedCompanies([...selectedCompanies, companyId]);
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const { auth2, app2 } = fetchFirebaseConfig2();
    setIsCreatingMember(true);

    const success = await createUserAndUploadProfile(
      {
        Role: 'Admin',
        email,
        password,
        position: 'Stakeholder',
        gender,
        selectedPhoto,
        display_name: firstName,
        mid_name: middleName,
        sir_name: sirName,
        mobile: contact,
        address,
        company_id: selectedCompanies, // Pass selected company IDs
      },
      auth2,
      app2,
      db,
      storage,
      companies
    );
    

    setIsCreatingMember(false);
    setIsSubmitting(false);

    if (success) {
      // Clear form inputs and selected companies
      setEmail('');
      setPassword('');
      setAddress('');
      setContact('');
      setFirstName('');
      setMiddleName('');
      setSirName('');
      setSelectedPhoto(null);
      setSelectedCompanies([]);
      setIsSubmitting(false);
    } else {
      // Handle error
    }
  };
    

  return (
    <div>
      {/* <h5 className="mb-lg">Add new member</h5> */}
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Register Stakeholder</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg"><i class="fa fa-plus" aria-hidden="true" style={{fontSize:'1.5rem'}}></i><i class="fa fa-user" aria-hidden="true" style={{fontSize:'2rem'}}></i> Add Stakeholder</h4>
      <Widget>
        <Row>
          <Col xs={12}>
              <Form className="mt" onSubmit={handleOnSubmit}>
                <Row>
                  <Col md={4}>
                    {/* First Column */}
                    <FormGroup>
                      <Label for="exampleFirstName">First name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleFirstName"
                        name="firstname"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        required
                        placeholder="First name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Second Column */}
                    <FormGroup>
                      <Label for="exampleMiddleName">Middle name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleMiddleName"
                        name="middlename"
                        onChange={(e) => setMiddleName(e.target.value)}
                        value={middleName}
                        required
                        placeholder="middle name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Third Column */}
                    <FormGroup>
                      <Label for="exampleSirName">Sir name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleSirName"
                        name="Sir-name"
                        onChange={(e) => setSirName(e.target.value)}
                        value={sirName}
                        required
                        placeholder="sir name"
                        type="text"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Fourth Column */}
                    <FormGroup>
                      <Label for="exampleGender">Gender<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleGender"
                        name="Gender"
                        onChange={(e) => setGender(e.target.value)} 
                        value={gender}
                        required
                        type="select"
                        size='sm'
                      >
                        <option value="Male">male</option>
                        <option value="Female">female </option>
                        </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Sixth Column */}
                    <FormGroup>
                      <Label for="exampleUploadImage">Upload Image</Label>
                      <Input
                        id="exampleUploadImage"
                        bsSize="sm"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setSelectedPhoto(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Third Column */}
                    <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        onChange={() => setCollapseOpen(!collapseOpen)}
                        checked={collapseOpen}
                      />
                      Select Company
                    </Label>
                  </FormGroup>
                  <Collapse isOpen={collapseOpen}>
                    {companies.map((company) => (
                      <FormGroup check key={company.id}>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={() => handleCompanySelection(company.company_id)}
                            checked={selectedCompanies.includes(company.company_id)}
                          />
                          {company.name}
                        </Label>
                      </FormGroup>
                    ))}
                  </Collapse>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* Seventh Column */}
                    <FormGroup>
                      <Label for="examplePassword">Password<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="examplePassword"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="password"
                        required
                        type="password"
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleEmail">Email<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleEmail"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="email"
                        required
                        bsSize="sm"
                        type='email'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleMobile">Mobile<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleMobile"
                        name="mobile"
                        onChange={(e) => setContact(e.target.value)}
                        value={contact}
                        placeholder="mobile"
                        required
                        bsSize="sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {/* Eighth Column */}
                    <FormGroup>
                      <Label for="exampleAddress">Address<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        id="exampleAddress"
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        placeholder="Address"
                        required
                        bsSize="sm"
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='submit' color='primary'>Submit</Button>
                </div>
              </Form>
          </Col>
        </Row>
      </Widget>
    </div>
  );
};

export default RegisterHolder;
