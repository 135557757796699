import React, { useEffect, useState,useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getFirestore, collection, getDocs, query, where, doc } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';
import { FormGroup, Input, Label,ButtonGroup,Button} from 'reactstrap';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';

const TransactionTrendChart = ({uid}) => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const { db } = fetchFirebaseConfig();
      const transactionCollection = collection(db, 'allTransaction2');
      const transactionsTrendQuery = query(
        transactionCollection,
        where("transactionUser", "==", doc(db, "users", uid))
      );
      const transactionQuerySnapshot = await getDocs(transactionsTrendQuery);
      

      const processedData = {};
      
      // Initialize all months with 0 values
      const allMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      allMonths.forEach(month => {
        processedData[month] = { month, 'Loan Payment': 0, 'Saving Deposit': 0, 'Share deposit': 0 };
      });

      transactionQuerySnapshot.forEach(doc => {
        const { transactionType, transactionDate, transactionAmount } = doc.data();
        const date = transactionDate.toDate();
        const year = date.getFullYear().toString();
        const month = date.toLocaleString('default', { month: 'long' });

        if (year === selectedYear) {
          processedData[month][transactionType] += transactionAmount;
        }
      });

      // Convert processed data to array
      const dataArray = Object.values(processedData);

      // Set processed data to state
      setTransactionData(dataArray);
      setLoading(false);
    };

    fetchData();
  }, [selectedYear]);

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const formatTooltip = (value, name, props) => {
    return [value.toLocaleString(), name];
  };

  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then(canvas => {
        canvas.toBlob(blob => {
          FileSaver.saveAs(blob, 'transaction_line.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(transactionData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'transaction_line.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'transaction_line.svg');
    }
  };

  return (
    <div>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <FormGroup className="me-3">
            <Label for="yearSelect"><i class="fa fa-calendar" aria-hidden="true"></i><span style={{ fontSize: '13px', marginLeft: '3px' }}> Year</span></Label>
            <Input
              type="select"
              id="yearSelect"
              onChange={handleChangeYear}
              value={selectedYear}
              size="sm"
              style={{ fontSize: '12px' }}
            >
              <option value="">Select Year</option>
              {generateYearOptions(2000, new Date().getFullYear() + 10).map(year => (
                <option key={year} value={year.toString()}>{year}</option>
              ))}
            </Input>
          </FormGroup>
          <ButtonGroup size='sm' >
            <Button color="success" size='sm' onClick={handleDownloadImage}>
                PNG
            </Button>
            <Button color="success" size='sm' onClick={handleDownloadSVG} >
                SVG
            </Button>
            <Button color="success" size='sm'  onClick={handleDownloadCSV}>
                CSV
            </Button>
          </ButtonGroup>
        </div>
    <ResponsiveContainer height={250}>
      <LineChart
        data={transactionData}
        margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
        ref={chartRef}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={{ fontSize: 12 }} />
        <YAxis tickFormatter={formatYAxisTick} tick={{ fontSize: 12 }}/>
        <Tooltip formatter={formatTooltip} wrapperStyle={{ fontSize: '10px' }} contentStyle={{ fontSize: '13px' }}/>
        {/* <Legend tick={{ fontSize: 12 }}/> */}
        <Line type="monotone" dataKey="Loan Payment" stroke="#8BC34A" />
        <Line type="monotone" dataKey="Saving Deposit" stroke="#ffc658" />
        <Line type="monotone" dataKey="Share deposit" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  </div>
  );  
};

export default TransactionTrendChart;

