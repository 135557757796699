import React, { useRef, useState,useEffect } from 'react';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input as InputData, Breadcrumb, BreadcrumbItem,Form,Col,Row,Table } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { collection, getDocs } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';

const BalanceSheet = () => {
  const { db } = fetchFirebaseConfig();
  const [assetsData, setAssetsData] = useState([]);
  const [liabilitiesData, setLiabilitiesData] = useState([]);
  const [equityData, setEquityData] = useState([]);
  const [totalCurrentAssets, setTotalCurrentAssets] = useState(0);
  const [totalFixedAssets, setTotalFixedAssets] = useState(0);
  const [totalInventory, setTotalInventory] = useState(0);
  const [totalNonCurrentAssets, setTotalNonCurrentAssets] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalCurrentLiabilities, setTotalCurrentLiabilities] = useState(0);
  const [totalLongTermLiabilities, setTotalLongTermLiabilities] = useState(0);
  const [totalShareCapital, setTotalShareCapital] = useState(0);
  const [totalRetainedEarnings, setTotalRetainedEarnings] = useState(0);
  const [totalLiabilities, setTotalLiabilities] = useState(0);
  const [totalEquity, setTotalEquity] = useState(0);
  const [totalLiabilitiesAndEquity, setTotalLiabilitiesAndEquity] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filterLatestDocuments = (data) => {
    const filteredData = data.reduce((accumulator, currentValue) => {
      const existingIndex = accumulator.findIndex(
        (item) => item.account_name === currentValue.account_name && item.account_type === currentValue.account_type
      );

      if (existingIndex === -1) {
        accumulator.push(currentValue);
      } else {
        const existingDate = new Date(accumulator[existingIndex].date.replace(/,/g, '')); // Remove commas
        const currentDate = new Date(currentValue.date.replace(/,/g, ''));

        if (currentDate > existingDate) {
          accumulator[existingIndex] = currentValue;
        }
      }

      return accumulator;
    }, []);

    return filteredData;
  };

  const fetchData = async () => {
    try {
      // Fetch assets data
      const assetsCollection = collection(db, 'Assets');
      const assetsSnapshot = await getDocs(assetsCollection);
      let assetsData = assetsSnapshot.docs.map((doc) => doc.data());

      // Filter assets data based on start and end dates
      if (startDate && endDate) {
        assetsData = assetsData.filter((asset) => {
          const assetDate = new Date(asset.date.replace(/,/g, '')); // Remove commas
          return assetDate >= new Date(startDate) && assetDate <= new Date(endDate);
        });
      } else if (startDate) {
        assetsData = assetsData.filter((asset) => {
          const assetDate = new Date(asset.date.replace(/,/g, '')); // Remove commas
          return assetDate >= new Date(startDate);
        });
      } else if (endDate) {
        assetsData = assetsData.filter((asset) => {
          const assetDate = new Date(asset.date.replace(/,/g, '')); // Remove commas
          return assetDate <= new Date(endDate);
        });
      }

      const filteredAssetsData = filterLatestDocuments(assetsData);
      setAssetsData(filteredAssetsData);

      // Fetch liabilities data
      const liabilitiesCollection = collection(db, 'Liabilities');
      const liabilitiesSnapshot = await getDocs(liabilitiesCollection);
      let liabilitiesData = liabilitiesSnapshot.docs.map((doc) => doc.data());

      // Filter liabilities data based on start and end dates
      if (startDate && endDate) {
        liabilitiesData = liabilitiesData.filter((liability) => {
          const liabilityDate = new Date(liability.date.replace(/,/g, '')); // Remove commas
          return liabilityDate >= new Date(startDate) && liabilityDate <= new Date(endDate);
        });
      } else if (startDate) {
        liabilitiesData = liabilitiesData.filter((liability) => {
          const liabilityDate = new Date(liability.date.replace(/,/g, '')); // Remove commas
          return liabilityDate >= new Date(startDate);
        });
      } else if (endDate) {
        liabilitiesData = liabilitiesData.filter((liability) => {
          const liabilityDate = new Date(liability.date.replace(/,/g, '')); // Remove commas
          return liabilityDate <= new Date(endDate);
        });
      }

      const filteredLiabilitiesData = filterLatestDocuments(liabilitiesData);
      setLiabilitiesData(filteredLiabilitiesData);

      // Fetch equity data
      const equityCollection = collection(db, 'Equity');
      const equitySnapshot = await getDocs(equityCollection);
      let equityData = equitySnapshot.docs.map((doc) => doc.data());

      // Filter equity data based on start and end dates
      if (startDate && endDate) {
        equityData = equityData.filter((equity) => {
          const equityDate = new Date(equity.date.replace(/,/g, '')); // Remove commas
          return equityDate >= new Date(startDate) && equityDate <= new Date(endDate);
        });
      } else if (startDate) {
        equityData = equityData.filter((equity) => {
          const equityDate = new Date(equity.date.replace(/,/g, '')); // Remove commas
          return equityDate >= new Date(startDate);
        });
      } else if (endDate) {
        equityData = equityData.filter((equity) => {
          const equityDate = new Date(equity.date.replace(/,/g, '')); // Remove commas
          return equityDate <= new Date(endDate);
        });
      }

      const filteredEquityData = filterLatestDocuments(equityData);
      setEquityData(filteredEquityData);

    } catch (error) {
      console.error('Error fetching data from Firebase:', error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    const currentAssets = assetsData.filter(asset => asset.account_type === 'current-assets');
    const fixedAssets = assetsData.filter(asset => asset.account_type === 'fixed-assets');
    const inventory = assetsData.filter(asset => asset.account_type === 'inventory');
    const nonCurrentAssets = assetsData.filter(asset => asset.account_type === 'non-current-assets');
  
    setTotalCurrentAssets(currentAssets.reduce((total, asset) => total + asset.balance, 0));
    setTotalFixedAssets(fixedAssets.reduce((total, asset) => total + asset.balance, 0));
    setTotalInventory(inventory.reduce((total, asset) => total + asset.balance, 0));
    setTotalNonCurrentAssets(nonCurrentAssets.reduce((total, asset) => total + asset.balance, 0));
  
    const totalAssets = totalCurrentAssets + totalInventory + totalNonCurrentAssets + totalFixedAssets;
    setTotalAssets(totalAssets);
  
    const currentLiabilities = liabilitiesData.filter(liability => liability.account_type === 'current-liabilities');
    const longTermLiabilities = liabilitiesData.filter(liability => liability.account_type === 'long-term-liabilities');
    const shareCapital = liabilitiesData.filter(liability => liability.account_type === 'share-capital');
    const retainedEarnings = liabilitiesData.filter(liability => liability.account_type === 'retained-earnings');
  
    setTotalCurrentLiabilities(currentLiabilities.reduce((total, liability) => total + liability.balance, 0));
    setTotalLongTermLiabilities(longTermLiabilities.reduce((total, liability) => total + liability.balance, 0));
    setTotalShareCapital(shareCapital.reduce((total, liability) => total + liability.balance, 0));
    setTotalRetainedEarnings(retainedEarnings.reduce((total, liability) => total + liability.balance, 0));
  
    const totalLiabilities = totalCurrentLiabilities + totalLongTermLiabilities + totalShareCapital + totalRetainedEarnings;
    setTotalLiabilities(totalLiabilities);
  
    const ownersEquity = equityData.filter(item => item.account_type === 'owners-equity');
    const totalEquity = ownersEquity.reduce((total, item) => total + item.balance, 0);
    setTotalEquity(totalEquity);
  
    // Calculate Total Liabilities & Equity
    const totalLiabilitiesAndEquity = totalLiabilities + totalEquity;
    setTotalLiabilitiesAndEquity(totalLiabilitiesAndEquity);
  }, [
    assetsData,
    liabilitiesData,
    totalCurrentAssets,
    totalInventory,
    totalNonCurrentAssets,
    totalCurrentLiabilities,
    totalLongTermLiabilities,
    totalShareCapital,
    totalRetainedEarnings,
    equityData,
  ]);
  
  const formatBalance = (balance) => {
    return balance !== undefined && balance !== 0
      ? balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '--';
  };

  const currentDate = new Date();
const day = currentDate.getDate();
const month = currentDate.toLocaleString('default', { month: 'short' });
const year = String(currentDate.getFullYear()).slice(2); // Get last two digits of the year

// Function to add ordinal suffix to the day
const addOrdinalSuffix = (number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

const formattedDate = `${addOrdinalSuffix(day)} ${month} ${year}`;

  return(
    <div>
    <Breadcrumb>
      <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
      <BreadcrumbItem active>Balance-Sheet</BreadcrumbItem>
    </Breadcrumb>
    <h4 className="mb-lg">Balance Sheet</h4>
    <Widget>
    <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div> */}
           </Widget>
            <div>
            <h5 className="mb-lg">Balance Sheet</h5>
                  <Table size='sm' borderless hover>
                  <th colSpan="2" style={{backgroundColor:'#f5f7fa'}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: '#28d456', fontWeight: 'bold', fontSize: '20px' }}>Assets</span>
                    <span><strong>{formattedDate}</strong></span>
                  </div>
                  </th>
                <tbody>
                  {/* Current Assets */}
                  <tr>
                    <td colSpan="2">
                     <span style={{fontWeight: 'bold', fontSize: '14px' }}>Current Assets</span> 
                    </td>
                  </tr>
                  {assetsData
                    .filter(asset => asset.account_type === 'current-assets')
                    .map((asset, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:'15px'}}>{asset.account_name}</span></td>
                        <td>{formatBalance(asset.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Current Assets */}
                  <tr>
                    <td style={{backgroundColor:'#fcfcfc'}}><span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Current Assets</span></td>
                    <td style={{backgroundColor:'#fcfcfc'}}>
                      <strong>{formatBalance(totalCurrentAssets)}</strong>
                    </td>
                  </tr>
                   {/* Fixed Assets */}
                  <tr>
                    <td colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Fixed Assets</span>
                    </td>
                  </tr>
                  {assetsData
                    .filter(asset => asset.account_type === 'fixed-assets')
                    .map((asset, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:'15px'}}>{asset.account_name}</span></td>
                        <td style={{ width: '100px' }}>{formatBalance(asset.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Fixed Assets */}
                  <tr>
                    <th style={{ backgroundColor:'#fcfcfc' }}><span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Fixed Assets</span></th>
                    <td style={{ backgroundColor:'#fcfcfc' }}>
                      <strong>{formatBalance(totalFixedAssets)}</strong>
                    </td>
                  </tr>
                  {/* Inventory */}
                  <tr>
                    <th colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Inventory</span>
                    </th>
                  </tr>
                  {assetsData
                    .filter(asset => asset.account_type === 'inventory')
                    .map((asset, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:'15px'}}>{asset.account_name}</span></td>
                        <td>{formatBalance(asset.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Inventory */}
                  <tr>
                      <th style={{backgroundColor:'#fcfcfc'}}>
                        <span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Inventory</span>
                      </th>
                    <td style={{backgroundColor:'#fcfcfc'}}>
                      <strong>{formatBalance(totalInventory)}</strong>
                    </td>
                  </tr>
                  {/* Non-Current Assets */}
                  <tr>
                    <td colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Non-Current Assets</span>
                    </td>
                  </tr>
                  {assetsData
                    .filter(asset => asset.account_type === 'non-current-assets')
                    .map((asset, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:'15px'}}>{asset.account_name}</span></td>
                        <td>{formatBalance(asset.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Non-Current Assets */}
                  <tr>
                    <th style={{ backgroundColor:'#fcfcfc' }}><span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Non-current Assets</span></th>
                    <td style={{ backgroundColor:'#fcfcfc' }}>
                      <strong>{formatBalance(totalNonCurrentAssets)}</strong>
                    </td>
                  </tr>
                  {/* Total Assets */}
                  <tr >
                      <th style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                        Total Assets
                      </th>
                    <td style={{ width: '20%',padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456',fontWeight:'bold', fontSize:'15px' }}>
                      {formatBalance(totalAssets)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* Liabilities Table */}
              <Table size='sm' borderless hover>
                  <tr>
                    <th th colSpan="2" style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456',fontWeight:'bold',fontSize:'20px' }}>Liabilities</span></th>
                  </tr>
                <tbody>
                  {/* Current Liabilities */}
                  <tr>
                    <th colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Current Liabilities</span>
                    </th>
                  </tr>
                  {liabilitiesData
                    .filter(liability => liability.account_type === 'current-liabilities')
                    .map((liability, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:'15px'}}>{liability.account_name}</span></td>
                        <td>{formatBalance(liability.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Current Liabilities */}
                  <tr>
                      <th style={{ backgroundColor:'#fcfcfc' }}>
                        <span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Current Liabilities</span>
                      </th>
                    <td style={{ backgroundColor:'#fcfcfc' }}>
                      <strong>{formatBalance(totalCurrentLiabilities)}</strong>
                    </td>
                  </tr>
                  {/* Long-Term Liabilities */}
                  <tr>
                    <th colSpan="2">
                     <span style={{fontWeight: 'bold', fontSize: '14px' }}>Long-Term Liabilities</span> 
                    </th>
                  </tr>
                  {liabilitiesData
                    .filter(liability => liability.account_type === 'long-term-liabilities')
                    .map((liability, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:'15px'}}>{liability.account_name}</span></td>
                        <td>{formatBalance(liability.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Long-Term Liabilities */}
                  <tr>
                      <th style={{ backgroundColor:'#fcfcfc' }}>
                        <span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Long-Term Liabilities</span>
                      </th>
                    <td style={{ backgroundColor:'#fcfcfc' }}>
                      <strong>{formatBalance(totalLongTermLiabilities)}</strong>
                    </td>
                  </tr>
                  {/* Share Capital */}
                  <tr>
                    <th colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Share Capital</span>
                    </th>
                  </tr>
                  {liabilitiesData
                    .filter(liability => liability.account_type === 'share-capital')
                    .map((liability, index) => (
                      <tr key={index}>
                        <td style={{width:'20%' }}><span style={{marginLeft:'15px'}}>{liability.account_name}</span></td>
                        <td style={{width:'20%' }}>{formatBalance(liability.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Share Capital */}
                  <tr>
                      <th style={{ backgroundColor:'#fcfcfc' }}>
                        <span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Share Capital</span>
                      </th>
                    <td style={{ backgroundColor:'#fcfcfc',width:'20%' }}>
                      <strong>{formatBalance(totalShareCapital)}</strong>
                    </td>
                  </tr>
                  {/* Retained Earnings */}
                  <tr>
                    <th colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Retained Earnings</span>
                    </th>
                  </tr>
                  {liabilitiesData
                    .filter(liability => liability.account_type === 'retained-earnings')
                    .map((liability, index) => (
                      <tr key={index}>
                          <td style={{width:'20%' }}><span style={{marginLeft:'15px'}}>{liability.account_name}</span></td>
                        <td style={{width:'20%' }}>{formatBalance(liability.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Retained Earnings */}
                  <tr>             
                      <th style={{ backgroundColor:'#fcfcfc' }}>
                        <span style={{fontWeight: 'bold', fontSize: '14px' }}>Total Retained Earnings</span>
                      </th>
                    <td style={{ backgroundColor: '#fcfcfc',width:'20%'  }}>
                      <strong>{formatBalance(totalRetainedEarnings)}</strong>
                    </td>
                  </tr>
                  {/* Total Liabilities */}
                  <tr>
                    <th style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                        Total Liabilities
                      </th>
                    <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                      {formatBalance(totalLiabilities)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* Equity Table */}
              <Table size='sm' borderless hover>
                  <tr>
                    <th colSpan="2" style={{backgroundColor:'#f5f7fa'}}><span style={{ color: '#28d456',fontWeight:'bold',fontSize:'20px' }}>Equity</span></th>
                  </tr>
                <tbody>
                  {/* Owners Equity */}
                  <tr>
                    < th colSpan="2">
                      <span style={{fontWeight: 'bold', fontSize: '14px' }}>Owners Equity</span>
                    </th>
                  </tr>
                  {equityData
                    .filter(equity => equity.account_type === 'owners-equity')
                    .map((equity, index) => (
                      <tr key={index}>
                          <td style={{width:'80%' }}><span style={{marginLeft:'15px'}}>{equity.account_name}</span></td>
                        <td style={{ width:'80%' }}>{formatBalance(equity.balance)}</td>
                      </tr>
                    ))}
                  {/* Total Equity */}
                  <tr>
                    <th style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                        Total Equity
                      </th>
                    <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}}>
                    {formatBalance(totalEquity)}
                    </td>
                  </tr>
                </tbody>
              </Table>

               {/* Total Liabilities & Equity */}
               <Table borderless size='sm' hover>
                <tbody>
                  <tr>    
                    <th style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px'}} colSpan="2">
                       Total Liabilities & Equity (Total Liability + Total Equity)
                      </th>      
                    <td style={{padding:'2px',backgroundColor:'#fcfcfc',color:'#28d456', fontWeight:'bold', fontSize:'15px',width:'20%'}} colSpan="2">
                      {formatBalance(totalLiabilitiesAndEquity)}
                    </td>
                  </tr>
                </tbody>
              </Table>
              </div>
    </Widget>
    </div>
  )
};

export default BalanceSheet;
