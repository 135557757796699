import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Widget from '../../components/Widget/Widget';

const SharesSettings = () => {
  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Settings</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Shares-settings</h4>
      <Row>
        <Col xs={12}>
          <Widget>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label for="exampleEmail">Amount Per Shares</Label>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder="Enter amount"
                  type="number"
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Company</Label>
                <Input
                  id="examplePassword"
                  name="password"
                  type="select"
                >
                  <option>select company</option>
                  <option>NCSS</option>
                  <option>DSP</option>
                  <option>LTI</option>
                </Input>
              </FormGroup>
              <Button color="primary">Create</Button>
            </Form>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default SharesSettings;
