import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { fetchFirebaseConfig } from '../../firebase';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {Button,ButtonGroup} from 'reactstrap';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';

const FinancialCategoriesChart = ({ uid }) => {
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [totalLoans, setTotalloans] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { db } = fetchFirebaseConfig();

        // Fetch total savings
        const savingsCollection = collection(db, 'Savings');
        const savingsQuery = query(
          savingsCollection,
          where("userSavings", "==", doc(db, "users", uid))
        );
        const savingsQuerySnapshot = await getDocs(savingsQuery);
        if (!savingsQuerySnapshot.empty) {
          const savingsDocSnapshot = savingsQuerySnapshot.docs[0];
          const savingsData = savingsDocSnapshot.data();
          setTotalSavings(savingsData.totalSavings || 0);
        }

        // Fetch total transactions
        const transactionsCollection = collection(db, 'allTransaction2');
        const transactionsQuery = query(
          transactionsCollection,
          where("transactionUser", "==", doc(db, "users", uid))
        );
        const transactionsQuerySnapshot = await getDocs(transactionsQuery);
        let totalTransactionAmount = 0;
        transactionsQuerySnapshot.forEach(doc => {
          totalTransactionAmount += doc.data().transactionAmount || 0;
        });
        setTotalTransactions(totalTransactionAmount);

        // Fetch total shares transactions
        const sharesCollection = collection(db, 'Savings');
        const sharesQuery = query(
          sharesCollection,
          where("userSavings", "==", doc(db, "users", uid))
        );
        const sharesQuerySnapshot = await getDocs(sharesQuery);
        if (!sharesQuerySnapshot.empty) {
          const sharesDocSnapshot = sharesQuerySnapshot.docs[0];
          const sharesData = sharesDocSnapshot.data();
          setTotalShares(sharesData.Amount || 0);
        }

        // Fetch total loans
        const loansCollection = collection(db, 'Loans');
        const loansQuery = query(
          loansCollection,
          where("user", "==", doc(db, "users", uid))
        );
        const loansQuerySnapshot = await getDocs(loansQuery);
        let totalloansAmount = 0;
        loansQuerySnapshot.forEach(doc => {
          totalloansAmount += doc.data().loanAmount1 || 0;
        });
        setTotalloans(totalloansAmount);

      } catch (error) {
        console.error("Error fetching financial categories data:", error);
      }
    };

    fetchData();
  }, [uid]);

  const dataAll = [
    { name: 'Savings', Savings: totalSavings },
    { name: 'Loans', Loans: totalLoans },
    { name: 'Shares', Shares: totalShares },
    { name: 'Transactions', Transactions: totalTransactions }
  ];

  const formatYAxisTick = (value) => {
    return value.toLocaleString();
  };

  const formatTooltip = (value, name) => {
    return [value.toLocaleString(), name];
  };

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const chart = chartRef.current.container;
      html2canvas(chart).then(canvas => {
        canvas.toBlob(blob => {
          FileSaver.saveAs(blob, 'transaction_line.png');
        });
      });
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(dataAll);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'transaction_line.csv');
  };

  const handleDownloadSVG = () => {
    if (chartRef.current) {
      const svg = chartRef.current.container.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      FileSaver.saveAs(blob, 'transaction_line.svg');
    }
  };

  return (
    <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <div className="mb-4 d-flex align-items-center">
        <ButtonGroup size='sm' className="ml-auto">
          <Button color="success" size='sm' onClick={handleDownloadImage}>
              PNG
          </Button>
          <Button color="success" size='sm' onClick={handleDownloadSVG} >
              SVG
          </Button>
          <Button color="success" size='sm'  onClick={handleDownloadCSV}>
              CSV
          </Button>
        </ButtonGroup>
      </div>
      <ResponsiveContainer height={250}>
        <BarChart width={350} height={300} data={dataAll} ref={chartRef}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis tickFormatter={formatYAxisTick} tick={{ fontSize: 12 }} />
          <Tooltip
            labelFormatter={(value) => value}
            formatter={formatTooltip}
            wrapperStyle={{ fontSize: '10px' }}
            contentStyle={{ fontSize: '13px' }}
          />
          <Legend />
          <Bar dataKey="Savings" fill="#82ca9d" />
          <Bar dataKey="Transactions" fill="#ffc658" />
          <Bar dataKey="Shares" fill="#bbe36b" />
          <Bar dataKey="Loans" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );   
};

export default FinancialCategoriesChart;
