import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button as Click,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { Button, Space, Table,Spin,Select } from 'antd';
import { useState,useEffect } from 'react';
import { collection,doc,addDoc,updateDoc,serverTimestamp,getDocs,query,getDoc,where } from 'firebase/firestore';
import { useHistory,useParams } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';


const EditEntry = () => {
    const { db } = fetchFirebaseConfig();
    const [newEntry, setNewEntry] = useState({
      Amount: '',
      Description: '',
      Date: '',
    });

    const [debitAccount, setDebitAccount] = useState('');
    const [creditAccount, setCreditAccount] = useState('');
    const [debitAmount, setDebitAmount] = useState('');
    const [creditAmount, setCreditAmount] = useState('');
    const [doubleEntryDescription, setDoubleEntryDescription] = useState('');
  
    const [accountRows, setAccountRows] = useState([
      {
        Account: '',
        Debit: '',
        Credit: '',
        Description: '',
        Amount: '',
      },
    ]);
  
    const [accountOptions, setAccountOptions] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [loading, setLoading] = useState(false);

  
    const history = useHistory();

    const { id } = useParams();

    useEffect(() => {
        fetchEntryDataForEdit();
      }, [db, id]);

      const fetchEntryDataForEdit = async () => {
        try {
         setLoading(true);
          if (id) {
            const entryDoc = await getDoc(doc(db, 'Journal', id));
            if (!entryDoc.exists()) {
              console.error('Document does not exist');
              return;
            }
  
            const entryData = entryDoc.data();
            console.log('Fetched entry data:', entryData);
  
            // Set the data in the state to autofill the inputs above the table
            setNewEntry({
              Amount: entryData.amount || '',
              Description: entryData.description || '',
              Date: entryData.date || '',
            });
  
            // Set the account data in the state
            setDebitAccount(entryData.debit_account || '');
            setCreditAccount(entryData.credit_account || '');
            setDebitAmount(entryData.debit_amount || '');
            setCreditAmount(entryData.credit_amount || '');
            setDoubleEntryDescription(entryData.double_entry_description || '');
  
            // Populate accountRows with existing data
            const existingAccountRows = entryData.entries || [];
  
            const updatedRows = existingAccountRows.map((entry) => ({
              Account: entry.credit_account || entry.debit_account || '',
              Debit: entry.debit_amount || '',
              Credit: entry.credit_amount || '',
              Description: entry.double_entry_description || '',
            }));
  
            setAccountRows(updatedRows);
            setLoading(false);
  
            console.log('State after setting data:', {
              newEntry,
              debitAccount,
              creditAccount,
              debitAmount,
              creditAmount,
              doubleEntryDescription,
              accountRows,
            });
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching entry data for edit: ', error);
        }
      };
    
      const handleAddAccount = () => {
        setAccountRows((prevRows) => [
          ...prevRows,
          {
            Account: '',
            Debit: '',
            Credit: '',
            Description: '',
            Amount: '',
          },
        ]);
      };

      const handleRemoveRow = (index) => {
        const updatedRows = [...accountRows];
        updatedRows.splice(index, 1);
        setAccountRows(updatedRows);
      };

      
    const handleRedo = () => { 
        fetchEntryDataForEdit();
      };
    
    
      const calculateTotals = () => {
        let debitTotal = 0;
        let creditTotal = 0;
      
        accountRows.forEach((row) => {
          if (!isNaN(row.Debit)) {
            debitTotal += parseFloat(row.Debit);
          }
      
          if (!isNaN(row.Credit)) {
            creditTotal += parseFloat(row.Credit);
          }
        });
      
        setTotalDebit(isNaN(debitTotal) ? 0 : debitTotal);
        setTotalCredit(isNaN(creditTotal) ? 0 : creditTotal);
      };
      
    
      useEffect(() => {
        const fetchAccountNames = async () => {
          try {
            const assetsSnapshot = await getDocs(collection(db, 'Assets'));
            const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
            const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
            const incomeSnapshot = await getDocs(collection(db, 'Income'));
            const equitySnapshot = await getDocs(collection(db, 'Equity'));
    
            const assetsNames = assetsSnapshot.docs.map((doc) => doc.data().account_name);
            const liabilitiesNames = liabilitiesSnapshot.docs.map((doc) => doc.data().account_name);
            const expensesNames = expensesSnapshot.docs.map((doc) => doc.data().account_name);
            const incomeNames = incomeSnapshot.docs.map((doc) => doc.data().account_name);
            const equityNames = equitySnapshot.docs.map((doc) => doc.data().account_name);
    
            const allAccountNames = [
              ...assetsNames,
              ...liabilitiesNames,
              ...expensesNames,
              ...incomeNames,
              ...equityNames,
            ];
    
            setAccountOptions([...new Set(allAccountNames)]);
          } catch (error) {
            console.error('Error fetching account names: ', error);
          }
        };
    
        fetchAccountNames();
      }, [db]);
    
      useEffect(() => {
        // Recalculate totals whenever accountRows changes
        calculateTotals();
      }, [accountRows]);
    
    
      // Helper function to check if an account belongs to the Assets collection
    const checkIfAssetAccount = async (accountName) => {
      const assetsSnapshot = await getDocs(collection(db, 'Assets'));
      return assetsSnapshot.docs.some((doc) => doc.data().account_name === accountName);
    };
    
    // Helper function to check if an account belongs to the Liabilities collection
    const checkIfLiabilityAccount = async (accountName) => {
      const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
      return liabilitiesSnapshot.docs.some((doc) => doc.data().account_name === accountName);
    };
    
    // Helper function to check if an account belongs to the Income collection
    const checkIfIncomeAccount = async (accountName) => {
      const incomeSnapshot = await getDocs(collection(db, 'Income'));
      return incomeSnapshot.docs.some((doc) => doc.data().account_name === accountName);
    };
    
    // Helper function to check if an account belongs to the Equity collection
    const checkIfEquityAccount = async (accountName) => {
      const equitySnapshot = await getDocs(collection(db, 'Equity'));
      return equitySnapshot.docs.some((doc) => doc.data().account_name === accountName);
    };
    
    // Helper function to check if an account belongs to the Expenses collection
    const checkIfExpensesAccount = async (accountName) => {
      const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
      return expensesSnapshot.docs.some((doc) => doc.data().account_name === accountName);
    };
    
    
    
    const updateAssetBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Assets' collection
        const assetsCollectionRef = collection(db, 'Assets');
        const querySnapshot = await getDocs(query(assetsCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Assets collection.`);
          return;
        }
    
        // Retrieve the first matching document (assuming account names are unique)
        const assetDoc = querySnapshot.docs[0];
        const currentBalance = assetDoc.data().balance || 0;
    
        // Update the balance based on Debit/Credit
        const newBalance = currentBalance + debitAmount - creditAmount;
    
        // Update the balance field in the Assets collection
        const assetToUpdateRef = doc(db, 'Assets', assetDoc.id);
        await updateDoc(assetToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating asset balance: ', error);
      }
    };
    
    const updateLiabilityBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Liabilities' collection
        const liabilitiesCollectionRef = collection(db, 'Liabilities');
        const querySnapshot = await getDocs(query(liabilitiesCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Liabilities collection.`);
          return;
        }
    
        // Retrieve the first matching document (assuming account names are unique)
        const liabilityDoc = querySnapshot.docs[0];
        const currentBalance = liabilityDoc.data().balance || 0;
    
        // Update the balance based on Debit/Credit
        const newBalance = currentBalance + creditAmount - debitAmount;
    
        // Update the balance field in the Liabilities collection
        const liabilityToUpdateRef = doc(db, 'Liabilities', liabilityDoc.id);
        await updateDoc(liabilityToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating liability balance: ', error);
      }
    };
    
    const updateIncomeBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Income' collection
        const incomeCollectionRef = collection(db, 'Income');
        const querySnapshot = await getDocs(query(incomeCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Income collection.`);
          return;
        }
    
        // Retrieve the first matching document (assuming account names are unique)
        const incomeDoc = querySnapshot.docs[0];
        const currentBalance = incomeDoc.data().balance || 0;
    
        // Update the balance based on Debit/Credit
        const newBalance = currentBalance + creditAmount - debitAmount;
    
        // Update the balance field in the Income collection
        const incomeToUpdateRef = doc(db, 'Income', incomeDoc.id);
        await updateDoc(incomeToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating income balance: ', error);
      }
    };
    
    const updateEquityBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Equity' collection
        const equityCollectionRef = collection(db, 'Equity');
        const querySnapshot = await getDocs(query(equityCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Equity collection.`);
          return;
        }
    
        // Retrieve the first matching document (assuming account names are unique)
        const equityDoc = querySnapshot.docs[0];
        const currentBalance = equityDoc.data().balance || 0;
    
        // Update the balance based on Debit/Credit
        const newBalance = currentBalance + creditAmount - debitAmount;
    
        // Update the balance field in the Equity collection
        const equityToUpdateRef = doc(db, 'Equity', equityDoc.id);
        await updateDoc(equityToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating equity balance: ', error);
      }
    };
    
    const updateExpensesBalance = async (accountName, debitAmount, creditAmount) => {
      try {
        // Check if the document with the selected account name exists in the 'Expenses' collection
        const expensesCollectionRef = collection(db, 'Expenses');
        const querySnapshot = await getDocs(query(expensesCollectionRef, where('account_name', '==', accountName)));
        if (querySnapshot.size === 0) {
          console.error(`Document with account_name '${accountName}' does not exist in the Expenses collection.`);
          return;
        }
    
        // Retrieve the first matching document (assuming account names are unique)
        const expensesDoc = querySnapshot.docs[0];
        const currentBalance = expensesDoc.data().balance || 0;
    
        // Update the balance based on Debit/Credit
        const newBalance = currentBalance + debitAmount - creditAmount;
    
        // Update the balance field in the Expenses collection
        const expensesToUpdateRef = doc(db, 'Expenses', expensesDoc.id);
        await updateDoc(expensesToUpdateRef, { balance: newBalance });
      } catch (error) {
        console.error('Error updating expenses balance: ', error);
      }
    };
    
    
    
    const handleEditEntry = async () => {
      try {
        const updatedData = {
          amount: newEntry.Amount || '0',
          description: newEntry.Description || '',
          date: newEntry.Date || '',
          total_credit: totalCredit,
          total_debit: totalDebit,
          entries: accountRows.map((row) => ({
            debit_account: row.Debit > 0 ? row.Account : '',
            credit_account: row.Credit > 0 ? row.Account : '',
            debit_amount: row.Debit ? parseFloat(row.Debit) : 0,
            credit_amount: row.Credit ? parseFloat(row.Credit) : 0,
            double_entry_description: row.Description,
          })),
        };
    
        // Loop through accountRows to update balances
        accountRows.forEach(async (row) => {
          const debitAmount = parseFloat(row.Debit);
          const creditAmount = parseFloat(row.Credit);
    
          if (debitAmount > 0 || creditAmount > 0) {
            const accountName = row.Account;
    
            // Check if the selected account belongs to the Assets, Liabilities, Income, Equity, or Expenses collection
            const isAssetAccount = await checkIfAssetAccount(accountName);
            const isLiabilityAccount = await checkIfLiabilityAccount(accountName);
            const isIncomeAccount = await checkIfIncomeAccount(accountName);
            const isEquityAccount = await checkIfEquityAccount(accountName);
            const isExpensesAccount = await checkIfExpensesAccount(accountName);
    
            // Update balances based on account type
            if (isAssetAccount) {
              await updateAssetBalance(accountName, debitAmount, creditAmount);
            } else if (isLiabilityAccount) {
              await updateLiabilityBalance(accountName, debitAmount, creditAmount);
            } else if (isIncomeAccount) {
              await updateIncomeBalance(accountName, debitAmount, creditAmount);
            } else if (isEquityAccount) {
              await updateEquityBalance(accountName, debitAmount, creditAmount);
            } else if (isExpensesAccount) {
              await updateExpensesBalance(accountName, debitAmount, creditAmount);
            }
          }
        });
    
        // Update the existing entry in the 'Journal' collection
        await updateDoc(doc(db, 'Journal', id), updatedData);
    
        // Redirect back to the Journal page after updating the entry
        history.push("/app/accounting/account-journals");
        console.log('Entry updated successfully!');
      } catch (error) {
        console.error('Error updating entry: ', error);
      }
    };


    const columns = [
      {
        title: 'Account',
        dataIndex: 'Account',
        key: 'Account',
        align: 'center',
        render: (text, record, index) => (
          <Select
            style={{ width: '100%' }}
            value={text}
            onChange={(value) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Account = value;
              setAccountRows(updatedRows);
            }}
          >
            <option value="">Select Account</option>
            {accountOptions.map((accountName) => (
              <option key={accountName} value={accountName}>
                {accountName}
              </option>
            ))}
          </Select>
        ),
      },
      {
        title: 'Debit',
        dataIndex: 'Debit',
        key: 'Debit',
        align: 'center',
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Debit = e.target.value;
              updatedRows[index].Credit = e.target.value ? '0' : '';
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
      {
        title: 'Credit',
        dataIndex: 'Credit',
        key: 'Credit',
        align: 'center',
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Credit = e.target.value;
              updatedRows[index].Debit = e.target.value ? '0' : '';
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
        align: 'center',
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Description = e.target.value;
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
    ];
  

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Add Journal Entry</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Add Journal Entry</h4>
        <Widget>
          <Form>
            <FormGroup>
                <Label for="loanGuarantors">Date</Label>
                <Input
                value={newEntry.Date}
                onChange={(e) => setNewEntry({ ...newEntry, Date: e.target.value })}
                type="date"
                size='sm'
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanCommitee"> Description </Label>
                <Input
                value={newEntry.Description}
                onChange={(e) => setNewEntry({ ...newEntry, Description: e.target.value })}
                placeholder='Description'
                type="textarea"
                size='sm'
                />
            </FormGroup>
            </Form>
          </Widget>

          <Widget>
          <ButtonGroup className='mb-3'>
        <Click color="success" size="sm" onClick={handleAddAccount}>
        <i className="fa fa-plus" aria-hidden="true"></i> Add Row
        </Click>
        <Click color="danger" size="sm" onClick={handleRemoveRow}style={{marginLeft:'1px'}}>
          <i className="fa fa-times" aria-hidden="true"></i> Delete Row
        </Click>
        <Click color="primary" size="sm" onClick={handleRedo}style={{marginLeft:'1px'}}>
        <i className="fa fa-refresh" aria-hidden="true"></i> Reset
        </Click>
        </ButtonGroup>
        <Table
          columns={columns}
          dataSource={accountRows}
          pagination={false}
          bordered
          rowKey={(record, index) => index}
          size='small'
          loading={loading}
        />
        <div style={{ justifyContent: 'flex-start' }} className='mt-3'>
          <p style={{ fontSize: '14px', margin: '5px 0' }}>
            <span style={{ fontWeight: 'bold' }}>Total Credit: </span>
            {totalCredit.toFixed(2)}
          </p>
          <p style={{ fontSize: '14px', margin: '5px 0' }}>
            <span style={{ fontWeight: 'bold' }}>Total Debit: </span>
            {totalDebit.toFixed(2)}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Click
            color="success"
            onClick={handleEditEntry}
            style={{ border: 'none', fontSize: '14px' }}
          >
            <span style={{ color: 'white' }}>Edit</span>
          </Click>
        </div>
      </Widget>
    </div>
  );
};

export default EditEntry;

