// import { createUserWithEmailAndPassword,signOut } from "firebase/auth";
// import { doc, setDoc } from "firebase/firestore";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { sendEmailVerification } from "firebase/auth";
// import { serverTimestamp,collection } from "firebase/firestore";
// import { deleteApp } from "firebase/app";
// import {fetchFirebaseConfig} from "../../firebase";
// // Function to create a user and upload their profile
// export const createUserAndUploadProfile = async (
//   values,
//   auth,
//   db,
//   app,
//   storage
// ) => {
//   try {
//     // Destructure values
//     const {                                          
//         Role,
//         email,
//         password,
//         position,
//         gender,
//         company_id,
//         selectedPhoto,
//       ...userData
//     } = values;

//     // Create the user in Firebase Authentication
//     const { user } = await createUserWithEmailAndPassword(auth, email, password);

//     // Upload the selected photo file to Firebase Storage
//     let photo_url = "";
//     if (selectedPhoto) {
//       const storageRef = ref(storage, `users/${user.uid}`);
//       await uploadBytes(storageRef, selectedPhoto);
//       photo_url = await getDownloadURL(storageRef);
//     }

//     const{db}=fetchFirebaseConfig();


//     //Create the user data in Firestore
//     const userRef = doc(db,"users", user.uid);
//     await setDoc(userRef, {
//       uid: user.uid,
//       Role:'Admin',
//       email,
//       password,
//       position:'Stakeholder',
//       gender,
//       ...userData,
//       company_id,
//       created_time: serverTimestamp(),
//       photo_url: photo_url,
//      // Role, // Use "Role" instead of "role"
//     });

//       // Create loan document when the user is created in Loans collection
//        const sharesRef = collection(db, "Shares");
//        const sharesDocRef = doc(sharesRef);
//        await setDoc(sharesDocRef, {
//        user: userRef,
//        sharesAmount: 0,
//        shares: 0,
//        EquityValue: 0, // Set the loanLimit field to 0
//        created_date: serverTimestamp(),
//        company_id:company_id
//     });


//      // Create savings document when the user is created in Loans collection
//      const savingsRef = collection(db, "Funds");
//      const savingsDocRef = doc(savingsRef);
//      await setDoc(savingsDocRef, {
//      user: userRef,
//      Amount: 0,
//      status: "inactive",
//      created_date: serverTimestamp(),
//      company_id:company_id
//     });

//   //   const outStandingLoansRef = collection(db, "outstandingLoanBalance");
//   //   const outStandingLoansDocRef = doc(outStandingLoansRef);
//   //   await setDoc(outStandingLoansDocRef, {
//   //   //user: userRef,
//   //   OutstandingLoanAmount: 0,
//   //   created_At: serverTimestamp(),
//   //  });


//     // Create shares document when the user is created in Loans collection
//     //      const userSharesRef = collection(db, "UserShares");
//     //      const userSharesDocRef = doc(userSharesRef);
//     //      await setDoc(userSharesDocRef, {
//     //      user: userRef,
//     //      Amount: 0,
//     //      shares: 0, 
//     //      created_at: serverTimestamp(),
//     // });


//       // Create shares document when the user is created in Loans collection
//   //       const userAdminRef = collection(db, "admin");
//   //       const userAdminDocRef = doc(userAdminRef);
//   //       await setDoc(userAdminDocRef, {
//   //       photo_url: photo_url,
//   //       uid:user.uid,
//   //       user: userRef,
//   //       created_at: serverTimestamp(),
//   //  });


//          // Create shares document when the user is created in Loans collection
//     //      const depositsRef = collection(db, "Deposits");
//     //      const depositsDocRef = doc(depositsRef);
//     //      await setDoc(depositsDocRef, {
//     //      created_At: serverTimestamp(),
//     //      user:userRef,
//     //      status: 'inactive',
//     //      totalDeposits:0
//     // });

//     // Send email verification to the user
//     await sendEmailVerification(auth.currentUser);

//     await signOut(auth);

//     await deleteApp(app);

//     // Return success
//     return true;
//   } catch (error) {
//     console.log(error);
//     // Return failure
//     return false;
//   }
// };


import { createUserWithEmailAndPassword,signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { sendEmailVerification } from "firebase/auth";
import { serverTimestamp,collection } from "firebase/firestore";
import { deleteApp } from "firebase/app";
import {fetchFirebaseConfig} from "../../firebase";
// Function to create a user and upload their profile
export const createUserAndUploadProfile = async (
  values,
  auth,
  db,
  app,
  storage,
  companies
) => {
  try {
    // Destructure values
    const {                                          
        Role,
        email,
        password,
        position,
        gender,
        company_id,
        selectedPhoto,
      ...userData
    } = values;

    // Create the user in Firebase Authentication
    const { user } = await createUserWithEmailAndPassword(auth, email, password);

    // Upload the selected photo file to Firebase Storage
    let photo_url = "";
    if (selectedPhoto) {
      const storageRef = ref(storage, `users/${user.uid}`);
      await uploadBytes(storageRef, selectedPhoto);
      photo_url = await getDownloadURL(storageRef);
    }

    const{db}=fetchFirebaseConfig();


    //Create the user data in Firestore
    const userRef = doc(db,"users", user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      Role:'Admin',
      email,
      password,
      position:'Stakeholder',
      gender,
      ...userData,
      company_id:company_id,
      created_time: serverTimestamp(),
      photo_url: photo_url,
     // Role, // Use "Role" instead of "role"
    });

// Create shares document when the user is created in Loans collection
const sharesRef = collection(db, "Shares");
const sharesDocRef = doc(sharesRef);

await setDoc(sharesDocRef, {
  user: userRef,
  totalShares: 0,
  totalSharesAmount: 0,
  created_date: serverTimestamp(),
});

// Create subcollections under 'CompanyShares' for each company ID
await Promise.all(company_id.map(async (companyId) => {
  const companySharesRef = collection(db, `Shares/${sharesDocRef.id}/CompanyShares`);
  const companySharesDocRef = doc(companySharesRef);

  await setDoc(companySharesDocRef, {
    user: userRef,
    sharesAmount: 0,
    shares: 0,
    EquityValue: 0,
    created_date: serverTimestamp(),
    company_id: companyId,
    CompanyName: companies.find(company => company.company_id === companyId).name
  });
}));


     // Create Funds document when the user is created in Loans collection
    //  const savingsRef = collection(db, "Funds");
    //  const savingsDocRef = doc(savingsRef);
     
    //  // Create a data object with dynamic field names for Amount
    //  const amountData = {};
    //  company_id.forEach(companyId => {
    //    amountData[`Amount${companyId}`] = 0; // Add the company_id to the field name
    //  });
 
    //  await setDoc(savingsDocRef, {
    //    totalAmount:0,
    //    user: userRef,
    //    status: "inactive",
    //    created_date: serverTimestamp(),
    //    company_id: company_id,
    //    ...amountData // Merge dynamic Amount fields
    //  });

 const fundsRef = collection(db, "Funds");
const fundsDocRef = doc(fundsRef);

await setDoc(fundsDocRef, {
  user: userRef,
  totalFundsAmount: 0,
  created_date: serverTimestamp(),
});

// Create subcollections under 'CompanyShares' for each company ID
await Promise.all(company_id.map(async (companyId) => {
  const companyFundsRef = collection(db, `Funds/${fundsDocRef.id}/CompanyFunds`);
  const companyFundsDocRef = doc(companyFundsRef);

  await setDoc(companyFundsDocRef, {
    totalAmount:0,
    user: userRef,
    status: "inactive",
    created_date: serverTimestamp(),
    company_id: companyId,
    CompanyName: companies.find(company => company.company_id === companyId).name
  });
}));


    // Send email verification to the user
    await sendEmailVerification(auth.currentUser);

    await signOut(auth);

    await deleteApp(app);

    // Return success
    return true;
  } catch (error) {
    console.log(error);
    // Return failure
    return false;
  }
};
