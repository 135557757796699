import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Breadcrumb, BreadcrumbItem, Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';


const StakeholderRequests = ({uid}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const componentRef = useRef(null);

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  const fetchAllTransactions = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
  
      // Retrieve the UID of the current authenticated user
      const currentUserUID = firebase.auth().currentUser.uid;
  
      // Get a reference to the user document
      const userRef = doc(db, 'users', currentUserUID);
  
      // Query the AllTransactions collection where the user field matches the user reference
      const querySnapshot = await getDocs(query(collection(db, 'Requests'), where('user', '==', userRef)));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.user);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          member: userData ? userData.display_name : '',
          receipts: item.receipts,
        };
      }));
  
      // Sort the data based on the requestDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.requestDate.seconds - a.requestDate.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
      fetchAllTransactions();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (receiptUrl) => {
    window.open(receiptUrl, '_blank'); // Open the voucher URL in a new tab
  };


  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(query(collection(db, 'Requests'), 
      where('requestDate', '>=', new Date(startDate)),
      where('requestDate', '<=', new Date(endDate))
      ));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.user);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          member: userData ? userData.display_name : '',
          receipt: item.receipt,
        };
      }));

      // Sort the data based on the transactionDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);

      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  const handleRedo = () => {
    fetchAllTransactions();
    setEndDate('');
    setStartDate('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      ...getColumnSearchProps('member'),
      sorter: (a, b) => a.member - b.member,
      sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Shares',
        dataIndex: 'shares',
        key: 'shares',
        align: 'center',
        ...getColumnSearchProps('shares'),
        sorter: (a, b) => a.member - b.member,
        sortDirections: ['descend', 'ascend'],
      },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      ...getColumnSearchProps('amount'),
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Request Type',
      dataIndex: 'requestType',
      key: 'requestType',
      align: 'center',
      ...getColumnSearchProps('requestType'),
      sorter: (a, b) => a.requestType - b.requestType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Receipt',
      dataIndex: 'receipt',
      key: 'receipt',
      align: 'center',
      render: (text) => (
        <Space>
         <Click outline color="primary" style={{ borderRadius: '10px' }} onClick={() => handleVoucherClick(text)}>
            <i className="fa fa-external-link" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
    {
      title: 'Request Status',
      dataIndex: 'requestStatus',
      key: 'requestStatus',
      align: 'center',
      ...getColumnSearchProps('requestStatus'),
      sorter: (a, b) => a.requestStatus - b.requestStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Request Date',
      dataIndex: 'requestDate',
      key: 'requestDate',
      align: 'center',
      ...getColumnSearchProps('requestDate'),
      sorter: (a, b) => a.requestDate - b.requestDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
      <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='primary' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='primary' onClick={handleRedo}>Reset</Click>
                </div>
           </Widget>
      <Widget>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>
      </Widget>
    </div>
  );
};

export default StakeholderRequests;
