import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  Alert, 
  Button, 
  FormGroup, 
  Input, 
  Row,
  Col,
  Form
} from 'reactstrap';
import s from './Login.module.scss';
import Widget from '../../components/Widget';
import Footer from "../../components/Footer";
import { UserAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { collection,getDoc,doc} from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';
import firebase from 'firebase/compat/app';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login=({ onLoginSuccess })=>{
  const [email,setEmail] = useState('');
  const [password, setPassword]= useState('')
  const [error, setError] = useState(' ')
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

     const { signIn }= UserAuth();

     const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
      try {
        await signIn(email, password);
  
        // Fetch user's position
        const currentUser = firebase.auth().currentUser;
        const { db } = fetchFirebaseConfig();
        const userDocRef = doc(collection(db, 'users'), currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (!userDocSnapshot.exists()) {
          throw new Error('User document not found');
        }
        const userData = userDocSnapshot.data();
        const userPosition = userData.position;
  
        // Redirect based on user's position
        if (userPosition === 'Stakeholder') {
          history.push("/app/companies/companies-list");
        } else {
          history.push("/app/main");
        }
        onLoginSuccess();
      } catch (e) {
        setError(e.message);
        console.log(e.message);
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Invalid email or password</div>, {
          position: "top-center"
        });        
      }
    };

        return (
          <div className={s.root}>
          <Row>
            <Col xs={{size: 10, offset: 1}} sm={{size: 6, offset: 3}} lg={{size:4, offset: 4}}>
              <div className="text-center" style={{marginBottom:'-45px',marginTop:'-45px'}}><img src="SharesTracker2.png" alt="Description of the image" style={{ width: '150px', height: '150px' }}/></div>
              <Widget className={s.widget}>
                <span className="text-center" style={{fontWeight:600, fontSize:'20px'}}>Sign In to your Account</span>
                <p className="fs-sm text-muted">
                  Use your email and password to sign in<br />
                  {/* Don&#39;t have an account? Sign up now! */}
                </p>
                <Form className="mt" onSubmit={handleSubmit}>
                  {/* {this.props.errorMessage && (
                    <Alert size="sm" color="danger">
                      {this.props.errorMessage}
                    </Alert>
                  )} */}
                  <FormGroup className="form-group">
                    <Input
                      className="no-border"
                      type="text"
                      required
                      name="email"
                      placeholder="Username"
                      onChange={(e)=> setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div style={{ position: 'relative' }}>
                      <Input
                        className="no-border"
                        type={showPassword ? 'text' : 'password'}
                        required
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '12px', cursor: 'pointer', color: '#c0c0c0' }} onClick={togglePasswordVisibility}></i>
                    </div>
                  </FormGroup>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <a href="#" className="fs-sm">Trouble with account?</a> */}
                    <div>
                      {/* <Button color="default" size="sm">
                        Create an account
                      </Button> */}
                      <Button color="primary" size="sm" type="submit">
                        Login
                      </Button>
                    </div>
                  </div>
                </Form>
              </Widget>
            </Col>
          </Row>
          <Footer className="text-center" />
          </div>
        );
    }

    Login.propTypes = {
      onLoginSuccess: PropTypes.func.isRequired, // Make sure onLoginSuccess is a function prop
    };

export default Login;

