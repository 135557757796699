// data.js
import { collection, getDocs,query,where,onSnapshot } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../../firebase';

export const countUsers = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users'); // Use collection function
  
      const snapshot = await getDocs(usersCollection); // Use getDocs to get a snapshot of the collection
      const userCount = snapshot.size;
  
      return userCount;
    } catch (error) {
      console.error('Error counting users:', error);
      return 0;
    }
  };


  export const countCompanies = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'Companies'); // Use collection function
  
      const snapshot = await getDocs(usersCollection); // Use getDocs to get a snapshot of the collection
      const userCount = snapshot.size;
  
      return userCount;
    } catch (error) {
      console.error('Error counting users:', error);
      return 0;
    }
  };

  export const getTotalApprovedLoanAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollection = collection(db, 'Loans'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(loansCollection, where('loanState', '==', 'Approved'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalLoanAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalLoanAmount += doc.data().loanAmount1;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalLoanAmount = totalLoanAmount
  
      return formattedTotalLoanAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };

  export const getTotalSavingsAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Savings'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection, where('status', '==', 'active'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let totalSavingsAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        totalSavingsAmount += doc.data().totalSavings;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedTotalSavingsAmount = totalSavingsAmount
  
      return formattedTotalSavingsAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };

  export const getTotalShares = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Savings'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection, where('status', '==', 'active'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let totalShares = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        totalShares += doc.data().shares;
      });
  
  
      const formattedtotalShares = totalShares
  
      return formattedtotalShares; 
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };


const fetchEligibleLoansCount = async () => {
  const { db } = fetchFirebaseConfig();
  const loansCollection = collection(db, 'Loans');
  const loansQuery = query(loansCollection, where('loanAmount', '>', 0)); // Filter loans with loanAmount > 0
  const loansSnapshot = await getDocs(loansQuery);

  return loansSnapshot.size; // Get the count of eligible loans
};

export { fetchEligibleLoansCount };


const fetchTotalUsersCount = async () => {
    const { db } = fetchFirebaseConfig();
    const usersCollection = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCollection);
  
    return usersSnapshot.size; // Get the total count of users
  };
  
  export { fetchTotalUsersCount };

  const fetchActiveSavingsCount = async () => {
    const { db } = fetchFirebaseConfig();
    const savingsCollection = collection(db, 'Savings');
    const activeSavingsQuery = query(savingsCollection, where('status', '==', 'active'));
    const activeSavingsSnapshot = await getDocs(activeSavingsQuery);
  
    return activeSavingsSnapshot.size; // Get the count of active savings
  };
  
  export { fetchActiveSavingsCount };


  export const getTotalSharesAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Savings'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection, where('status', '==', 'active'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let Amount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        Amount += doc.data().Amount;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedTotalSharesAmount = Amount
  
      return formattedTotalSharesAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };



  export const getShares = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Companies'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection);
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let totalShares = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        totalShares += doc.data().CompanySharesAvailable;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedSharesAmount = totalShares
  
      return formattedSharesAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };

  // In data.js

export const getSharesDifferenceAmount = async () => {
  try {
    const { db } = fetchFirebaseConfig();
    const savingsCollection = collection(db, 'Savings');
    const sharesCollection = collection(db, 'shares');

    // Create a query to filter documents where status is 'active' in Savings
    const savingsQuery = query(savingsCollection, where('status', '==', 'active'));
    const savingsSnapshot = await getDocs(savingsQuery);

    // Initialize variables to store the total savings amount and shares amount
    let totalSavingsAmount = 0;
    savingsSnapshot.forEach((doc) => {
      totalSavingsAmount += doc.data().Amount;
    });

    // Create a query to get totalSharesAmount from the 'shares' collection
    const sharesQuery = query(sharesCollection);
    const sharesSnapshot = await getDocs(sharesQuery);

    // Initialize a variable to store the total shares amount
    let totalSharesAmount = 0;
    sharesSnapshot.forEach((doc) => {
      totalSharesAmount += doc.data().totalSharesAmount;
    });

    // Calculate the difference between totalSavingsAmount and totalSharesAmount
    const differenceAmount = totalSharesAmount - totalSavingsAmount;

    // Format the difference amount as TZS using numeral.js
    const formattedDifferenceAmount = differenceAmount

    return formattedDifferenceAmount;
  } catch (error) {
    console.error('Error fetching shares difference amount:', error);
    throw error;
  }
};


export const getTotalDeposits = async () => {
  try {
    const { db } = fetchFirebaseConfig();
    const savingsCollection = collection(db, 'Deposits'); // Replace 'Savings' with your collection name

    // Create a query to filter documents where status is 'active'
    const q = query(savingsCollection, where('status', '==', 'active'));

    const querySnapshot = await getDocs(q);

    // Initialize a variable to store the total savings amount
    let totalDepositsAmount = 0;

    querySnapshot.forEach((doc) => {
      // Access the totalSavings field from each document and add it to the total
      totalDepositsAmount += doc.data().totalDeposits;
    });

    // Format the total savings amount as TZS using numeral.js
    const formattedTotalDepositsAmount = totalDepositsAmount

    return formattedTotalDepositsAmount; // Return the formatted amount
  } catch (error) {
    console.error('Error fetching total savings:', error);
    throw error;
  }
};




  
