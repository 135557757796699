import React,{useState,useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { useParams,useHistory} from "react-router-dom";
import Widget from '../../components/Widget/Widget';
import PropTypes from 'prop-types';
import {fetchFirebaseConfig} from '../../firebase';
import { collection, addDoc, serverTimestamp,doc,getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions

const EditCompany = () => {

  const { id } = useParams(); 

  const history = useHistory();

  const { db, storage } = fetchFirebaseConfig();

  const [ceoName, setCeoName] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [companyStaff, setCompanyStaff] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyMobile, setCompanyMobile] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [initialcompanyName, setInitialCompanyName] = useState('');
  const [companyShares, setCompanyShares] = useState(0);
  const [companyAddress, setCompanyAddress] = useState("");
  const [amountPerShares, setAmountPerShares] = useState(0);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [profile, setProfile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setCompanyLogo(file);
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!id) {
          console.error("ID is undefined");
          return;
        }
        const userDocRef = doc(db, 'Companies', id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setCeoName(userData.CompanyCeo || '');
          setProfile(userData.CompanyLogo|| '');
          setCompanyCode(userData.company_id || '');
          setCompanyStaff(userData.CompanyStaff|| '');
          setCompanyEmail(userData.CompanyEmail|| '');
          setCompanyMobile(userData.CompanyMobile|| '');
          setCompanyName(userData.CompanyName || '');
          setInitialCompanyName(userData.CompanyName || '');
          setCompanyShares(userData.CompanySharesAvailable ||'');
          setCompanyAddress(userData.CompanyAddress || '');
          setAmountPerShares(userData.AmountPerShares || '');
          if (selectedProfilePicture) {
            setPreviewUrl(URL.createObjectURL(selectedProfilePicture)); // Update preview URL
          }
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

      fetchUserData();
    }, [db, id, selectedProfilePicture]);


    const handleEdit = async () => {

        setIsSubmitting(true);

        try {
          const userDocRef = doc(db, 'Companies', id);
          await updateDoc(userDocRef, {
            CompanyCeo:ceoName,
            company_id:companyCode,
            CompanyStaff:companyStaff,
            CompanyEmail:companyEmail,
            CompanyMobile:companyMobile,
            CompanyName: companyName,
            CompanySharesAvailable:companyShares,
            CompanyAddress:companyAddress,
            AmountPerShares:amountPerShares,
          });
        
          // Upload the new profile picture if selected
          if (selectedProfilePicture) {
            const storageRef = ref(storage, `CompanyLogo/${id}`);
            await uploadBytes(storageRef, selectedProfilePicture);
            const newLogo = await getDownloadURL(storageRef);    
        
            // Update the user document with the new profile picture URL
            await updateDoc(userDocRef, { CompanyLogo: newLogo });
          }

          setIsSubmitting(false);
          
        //   toast.success('member info updated successfully!');
        
          setTimeout(() => {
            history.push("/app/companies/companies-list");
          }, 4000);         
        } catch (error) {
        //   toast.error('Error while updating user info');
        }
        
      };



  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Edit Company</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Update {initialcompanyName} details</h4>
      <Row>
       <Col xs={8}>
          <Widget>
          <Form>
              <FormGroup>
                  <Label for="CompanyName"> Company name</Label>
                  <Input
                  type='text'
                  id="CompanyName"
                  name="CompanyName"
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  />
              </FormGroup>
                <FormGroup>
                    <Label for="CompanyCode">code</Label>
                    <Input
                    type='text'
                    id="CompanyCode"
                    name="CompanyCode"
                    placeholder="Company code"
                    value={companyCode}
                    onChange={(e) => setCompanyCode(e.target.value)}
                    />
                </FormGroup>
               <FormGroup>
                <Label for="CompanyEmail">email</Label>
                <Input
                type='email'
                id="CompanyEmail"
                name="CompanyEmail"
                placeholder="Company email"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
                />
                </FormGroup>
               <FormGroup>
                <Label for="CompanyName">mobile</Label>
                <Input
                type='number'
                id="CompanyMobile"
                name="CompanyMobile"
                placeholder="Company mobile"
                value={companyMobile}
                onChange={(e) => setCompanyMobile(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="CeoName">CEO name</Label>
                <Input
                type='text'
                id="CeoName"
                name="CeoName"
                placeholder="Ceo Name"
                value={ceoName}
                onChange={(e) => setCeoName(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="CompanyName">Address</Label>
                <Input
                type='text'
                id="CompanyAddress"
                name="CompanyAddress"
                placeholder="Company Address"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanCommitee">Total Staff</Label>
                <Input
                id="Shares Amount"
                name="Shares Amount"
                placeholder="Shares Amount"
                type='number'
                value={companyStaff}
                onChange={(e) => setCompanyStaff(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
            <Label for="TotalShares">Total Shares</Label>
                <Input
                type='number'
                id="TotalShares"
                name="TotalShares"
                placeholder="Total Shares"
                value={companyShares}
                onChange={(e) => setCompanyShares(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
            <Label for="AmountPerShare">Amount/Share</Label>
                <Input
                type='number'
                id="AmountPerShare"
                name="AmountPerShare"
                placeholder="Amount per Share"
                value={amountPerShares}
                onChange={(e) => setAmountPerShares(e.target.value)}
                />
            </FormGroup>
            <div style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-center">
                  {previewUrl ? (
                    <img
                      className="profileImg"
                      src={previewUrl}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                      alt="Profile Preview"
                    />
                  ) : profile ? (
                    <img
                      className="profileImg"
                      src={profile}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                    />
                  ) : (
                    <i className="fa fa-user-circle" style={{ fontSize: '7em', color: '#c0c0c0' }}></i>
                  )}
                </div>
            <FormGroup>
             <Label for="exampleUploadImage">Upload logo</Label>
                 <Input
                  id="exampleUploadImage"
                  bsSize="sm"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  />
              </FormGroup>
            </Form>
            <Button color='primary' onClick={handleEdit}>
             Edit
            </Button>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default EditCompany;

