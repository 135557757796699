import React, { useState, useEffect } from 'react';
// import { Tabs } from 'antd';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Button, Breadcrumb, BreadcrumbItem,Badge,Collapse } from 'reactstrap';
import {fetchFirebaseConfig} from '../../firebase';
import {
    doc,
    getDoc,
    onSnapshot
  } from 'firebase/firestore';
  import CountUp from 'react-countup';
  import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import StakeholderTransactions from './stakeholderTransactions';
import StakeholderRequests from './Requests';

const TransactionsRequests = ({uid}) => {

  const [activeTab, setActiveTab] = useState('transactions');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    transactions: <StakeholderTransactions uid={uid}/>,
    requests:<StakeholderRequests uid={uid}/>
  };
  const [name, setName] = useState("");
  const [loansData, setLoanData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loansDetails, setLoanDetails] = useState(null);

  const {db}=fetchFirebaseConfig();


  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'transactions' || key === 'requests') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };


  return (
    <div>
            <Widget> {/* Add margin-left for separation */}
              <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
              <NavItem>
                  <NavLink
                    className={activeTab === 'transactions' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('transactions')}
                    style={activeTab === 'transactions' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                    <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Transactions</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'requests' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('requests')}
                    style={activeTab === 'requests' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                  <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Requests</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="transactions">
                  <Widget>
                    <div className="align-items-center">{tabContent.transactions}</div>
                  </Widget>
                </TabPane>
                <TabPane tabId="requests">
                  <Widget>
                    <div className="align-items-center" >{tabContent.requests}</div>
                  </Widget>
                </TabPane>
              </TabContent>
            </Widget>
          </div>
      );
    };

export default TransactionsRequests;
