import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Spinner,Badge} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { getDocs, doc, getDoc, collection, onSnapshot, query, where } from "firebase/firestore";
import { fetchUsers } from './fetchUsers';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { Breadcrumb } from 'antd';


import Swal from 'sweetalert2';

const ViewAllStakeholders = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const searchInput = useRef(null);
  const componentRef = useRef(null);
  const history = useHistory();

//   const [isVisible1b1, setIsVisible1b1] = useState(false);
//   const [isVisible1b2, setIsVisible1b2] = useState(false);
//   const [isVisible1b3, setIsVisible1b3] = useState(false);
//   const [isVisible1b4, setIsVisible1b4] = useState(false);

//   const fetchVisibility = async () => {
//     const user = firebase.auth().currentUser;
//     if (user) {
//       const { db } = fetchFirebaseConfig();
//       const usersCollection = collection(db, 'users');
//       const userDocRef = doc(usersCollection, user.uid);
      
//       // Use onSnapshot to listen for changes to user permissions
//       const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
//         if (userDocSnap.exists()) {
//           const userData = userDocSnap.data();
//           const userPosition = userData.position;
//           const permissionsCollection = collection(db, 'permissions');
//           const q = query(permissionsCollection, where('Role', '==', userPosition));
//           const querySnapshot = await getDocs(q);
//           if (!querySnapshot.empty) {
//             const permissionDocSnap = querySnapshot.docs[0];
//             const permissionData = permissionDocSnap.data();
//             const accessArray = permissionData.acess || [];
//             setIsVisible1b1(accessArray.includes(1.21));
//             setIsVisible1b2(accessArray.includes(1.22));
//             setIsVisible1b3(accessArray.includes(1.23));
//             setIsVisible1b4(accessArray.includes(1.24));
//           } else {
//             setIsVisible1b1(false);
//             setIsVisible1b2(false);
//             setIsVisible1b3(false);
//             setIsVisible1b4(false);
//           }
//         }
//       });
      
//       return () => {
//         // Unsubscribe from onSnapshot when component unmounts
//         unsubscribe();
//       };
//     }
//   };

//   useEffect(() => {
//     fetchVisibility();
//   }, []);

//   fetchVisibility();


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, columnTitle) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${columnTitle}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    sorter: (a, b) => a[dataIndex] - b[dataIndex], // Sorting function
    sortDirections: ['ascend', 'descend'],
  });

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  // Custom rendering function for the Loan Committee column
  const renderLoanCommittee = (loanCommitee) => {
    return loanCommitee ? <Badge color="success" style={{borderRadius:'5px'}}><span  style={{color:'white'}}>Yes</span></Badge> : <Badge color="warning" style={{borderRadius:'5px'}}><span  style={{color:'white'}}>No</span></Badge>;
  };

  // Custom rendering function for the # column
  const renderRowNumber = (_, __, index) => {
    return index + 1;
  };

  const renderProfileImage = (photo_url) => {
    return photo_url ? (
      <img src={photo_url} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
    ) : (
      <i className="fa fa-user-circle" style={{ fontSize: '2.5em', color: '#c0c0c0' }}></i> 
    );
  };

//   const visibleActions = [isVisible1b1, isVisible1b2, isVisible1b3, isVisible1b4].some(Boolean);

  const columns = [
    {
      title: '#',
      dataIndex: 'uid',
      key: 'uid',
      align: 'center', 
      render: renderRowNumber, // Apply custom rendering for row number
      sorter: (a, b) => a.uid - b.uid, // Sorting function for ID column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Profile',
      dataIndex: 'photo_url',
      key: 'photo_url',
      align: 'center', 
      render: renderProfileImage, // Apply custom rendering for profile image
      // No sorting for Profile column
    },
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      align: 'center', 
      ...getColumnSearchProps('display_name', 'Name'), // Apply filter for Name column
      sorter: (a, b) => a.display_name.localeCompare(b.display_name), // Sorting function for Name column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Code',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center', 
      ...getColumnSearchProps('company_id','Code'), // Apply filter for Reference column
      sorter: (a, b) => a.company_id - b.company_id, // Sorting function for Reference column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center', 
      ...getColumnSearchProps('gender', 'Gender'), // Apply filter for Gender column
      sorter: (a, b) => a.gender.localeCompare(b.gender), // Sorting function for Gender column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center', 
      ...getColumnSearchProps('email', 'Email'), // Apply filter for Email column
      sorter: (a, b) => a.email.localeCompare(b.email), // Sorting function for Email column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Contact',
      dataIndex: 'mobile',
      key: 'mobile',
      align: 'center', 
      ...getColumnSearchProps('mobile', 'Contact'), // Apply filter for Contact column
      sorter: (a, b) => a.phone_number.localeCompare(b.mobile), // Sorting function for Contact column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Location',
      dataIndex: 'address',
      key: 'address',
      align: 'center', 
      ...getColumnSearchProps('address', 'Location'), // Apply filter for Location column
      sorter: (a, b) => a.location.localeCompare(b.location), // Sorting function for Location column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'position',
      key: 'position',
      align: 'center', 
      ...getColumnSearchProps('position', 'Status'), // Apply filter for Status column
      sorter: (a, b) => a.position.localeCompare(b.position), // Sorting function for Status column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Joined',
      dataIndex: 'created_time',
      key: 'created_time',
      align: 'center', 
      render: renderDateRequested, // Apply date formatting
      sorter: (a, b) => a.created_time - b.created_time, // Sorting function for Joined column
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            {/* {isVisible1b1 && ( */}
              <Click outline color='primary' style={{borderRadius:'10px' }} onClick={() => history.push(`/app/company/company-view/company-stakeholders/edit-stakeholder/${record.uid}`)}>
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </Click>
            {/* )} */}
            {/* {isVisible1b2 && ( */}
              <Click outline color='primary' style={{borderRadius:'10px' }} onClick={() => history.push(`/app/company/company-view/company-stakeholders/profile-stakeholder/${record.uid}`)}>
                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
              </Click>
            {/* )} */}
            {/* {isVisible1b3 && ( */}
              <Click outline color='primary' style={{borderRadius:'10px' }} onClick={()=>history.push(`/app/members/members-list/reset-password/${record.uid}`)}>
                <i className="fa fa-lock" aria-hidden="true"></i>
              </Click>
            {/* )} */}
            {/* {isVisible1b4 && ( */}
              <Click outline color='danger' style={{borderRadius:'10px' }} onClick={() => handleDelete(record.uid)}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </Click>
            {/* )} */}
          </Space>
        );
      },
    //   ...(visibleActions ? {} : { hidden: true }),
    },
  ];


  const handleDelete = async (uid) => {
    try {
      // Use SweetAlert to confirm the action
      const result = await Swal.fire({
        title: 'Confirm',
        text: "Delete user, this action is irreversible",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
  
      // Check if the user confirmed the action
      if (result.isConfirmed) {
        const response = await fetch('https://us-central1-lti-sharestracker.cloudfunctions.net/deleteUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: uid,
          }),
        });
        
        if (response.ok) {
          // Display the success alert
          await Swal.fire({
            icon: 'success',
            title: 'User Deleted',
            text: 'The user has been successfully deleted!',
            confirmButtonColor: '#28a745',
          });
          fetchData();
        } else {
          // Display error alert if something went wrong
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete user. Please try again later.',
            confirmButtonColor: '#dc3545',
          });
        }
      }
    } catch (error) {
      // Handle error
      console.error('Error deleting user:', error);
    }
  };
  

  const fetchData = async () => {
    const { db } = fetchFirebaseConfig();
    setIsLoading(true);
    const usersData = await fetchUsers(db);

    usersData.sort((a, b) => {
      const aTime = a.created_time?.seconds || 0;
      const bTime = b.created_time?.seconds || 0;

      return bTime - aTime;
    });

    setUsers(usersData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  


  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>View Stakeholders</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg"><i class="fa fa-group" aria-hidden="true" style={{fontSize:'2rem'}}></i> Stakeholders</h4>
        <Widget>
            <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup>
                <Click color="primary" size='sm'>
                  CSV
                </Click>
                <Click color="primary" size='sm'>
                  PDF
                </Click>
                <ReactToPrint
                      trigger={() => (
                        <Click color="primary" disabled={printing} size='sm'>
                         {printing ? <Spinner size="sm" style={{ color: 'white' }}/> : <i className="fa fa-print" aria-hidden="true"></i>}
                        </Click>
                      )}
                      content={() => componentRef.current} // Specify the component to be printed
                      onBeforeGetContent={() => setPrinting(true)}
                      onAfterPrint={() => setPrinting(false)}
                    />
            </ButtonGroup>
          </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table
            columns={columns}
            dataSource={users}
            loading={isLoading}
            size="small"
          />
        </div>
        </Widget>
    </div>
  );
};

export default ViewAllStakeholders;
