import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Breadcrumb, BreadcrumbItem, Spinner, Badge, FormGroup, Label, Row, Col, Input as InputData } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';

const TransactionsRecords = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState('select category');  // State for category input
  const [startingAmount, setStartingAmount] = useState('');     // State for starting amount input
  const [endingAmount, setEndingAmount] = useState('');
  const [showSummaryTable, setShowSummaryTable] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [expandStatusRow, setExpandStatusRow] = useState(false);

  const componentRef = useRef(null);

  const fetchTransactions = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(collection(db, 'allTransaction2'));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.transactionUser);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          member: userData ? userData.display_name : '',
        };
      }));

      // Sort the data based on the transactionDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);

      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      let queryRef = collection(db, 'allTransaction2');

      // Apply date filter
      if (startDate && endDate) {
        queryRef = query(
          queryRef,
          where('transactionDate', '>=', new Date(startDate)),
          where('transactionDate', '<=', new Date(endDate))
        );
      }

      const querySnapshot = await getDocs(queryRef);
      const fetchedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch user data for each document
      const updatedData = await Promise.all(
        fetchedData.map(async item => {
          const userDoc = await getDoc(item.transactionUser);
          const userData = userDoc.exists() ? userDoc.data() : null;
          return {
            ...item,
            id: item.id,
          };
        })
      );

      // Apply category filter if startingAmount and endingAmount are not empty for the selected category
      if (
        (category === 'Transaction Amount' && startingAmount !== '' && endingAmount !== '')
      ) {
        const filteredData = updatedData.filter(item => {
          if (category === 'Transaction Amount') {
            return item.transactionAmount >= parseInt(startingAmount) && item.transactionAmount <= parseInt(endingAmount);
          } else
            return true;
        });
        // Calculate counts for each column
        const summaryData = columns.map(column => {
          if (column.dataIndex === 'transactionType') {
            const loanPaymentCount = filteredData.filter(item => item.transactionType === 'Loan Payment').length;
            const savingDepositCount = filteredData.filter(item => item.transactionType === 'Saving Deposit').length;
            const shareDepositCount = filteredData.filter(item => item.transactionType === 'Share deposit').length;
            const total = loanPaymentCount + savingDepositCount + shareDepositCount;
            return {
              title: column.title,
              count: {
                'Loan Payment': loanPaymentCount,
                'Saving Deposit': savingDepositCount,
                'Share deposit': shareDepositCount,
                'Total': total // Include total count
              },
            };
          } else if (column.dataIndex === 'member') {
            return {
              title: column.title,
              count: filteredData.length,
            };
          } else {
            return {
              title: column.title,
              count: filteredData.reduce((acc, curr) => {
                if (curr[column.dataIndex]) {
                  acc++;
                }
                return acc;
              }, 0),
            };
          }
        });


        // Set summary table data
        setSummaryData(summaryData);

        setData(filteredData);
      } else {
        const summaryData = columns.map(column => {
          if (column.dataIndex === 'transactionType') {
            const loanPaymentCount = updatedData.filter(item => item.transactionType === 'Loan Payment').length;
            const savingDepositCount = updatedData.filter(item => item.transactionType === 'Saving Deposit').length;
            const shareDepositCount = updatedData.filter(item => item.transactionType === 'Share deposit').length;
            return {
              title: column.title,
              count: { 'Loan Payment': loanPaymentCount, 'Saving Deposit': savingDepositCount, 'Share deposit': shareDepositCount }, // Separate counts for each transaction type
            };
          } else if (column.dataIndex === 'member') {
            return {
              title: column.title,
              count: updatedData.length,
            };
          } else {
            return {
              title: column.title,
              count: updatedData.reduce((acc, curr) => {
                if (curr[column.dataIndex]) {
                  acc++;
                }
                return acc;
              }, 0),
            };
          }
        });

        // Set summary table data
        setSummaryData(summaryData);

        setData(updatedData);
      }

      setShowSummaryTable(true);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  const handleRedo = () => {
    fetchTransactions();
    setEndDate('');
    setStartDate('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key:      'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      sorter: (a, b) => a.member.localeCompare(b.member),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Transaction Amount',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      sorter: (a, b) => a.transactionAmount - b.transactionAmount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      sorter: (a, b) => a.transactionType - b.transactionType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      sorter: (a, b) => a.transactionDate - b.transactionDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  const summaryColumns = [
    {
      title: 'Data Instances',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => {
        if (record.title === 'Transaction Type') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{title}</span>
              <div onClick={() => setExpandStatusRow(!expandStatusRow)} style={{ marginLeft: '10px' }}>
                {/* Add a tiny trigger button */}
                <Click size="sm" color='success' outline style={{borderRadius:'5px'}}>
                  {expandStatusRow ? <i class="fa fa-minus-square-o" aria-hidden="true"></i> : <i class="fa fa-plus-square-o" aria-hidden="true"></i>}
                </Click>
              </div>
            </div>
          );
        } else {
          return <div>{title}</div>;
        }
      },
    },
    {
      title: 'Count Of Instances',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      render: (count, record) => {
        if (record.title === 'Transaction Type') {
          // Calculate total count of active and inactive statuses
          const total = count['Loan Payment'] + count['Saving Deposit'] + count['Share deposit'];
          return (
            <div>
              {/* Display total count beside the collapse button */}
              {!expandStatusRow && (
                <span style={{ marginLeft: '5px' }}>{total}</span>
              )}
              {/* Render counts if expanded */}
              {expandStatusRow && (
                  <div>
                  <p>Loan Payment: {count['Loan Payment']}</p>
                  <p>Saving Deposits: {count['Saving Deposit']}</p>
                  <p>Share Deposits: {count['Share deposit']}</p>
                </div>
              )}
            </div>
          );
        } else {
          return <div>{count}</div>;
        }
      },
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Transactions Records</BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mb-lg">Transactions Records</h5>
      <Widget
        title={<h7><span className="fw-semi-bold">Set Filtering parameters</span></h7>}
      >
        <Row style={{ marginTop: '10px' }}>
          <Col md={6}>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <InputData
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                type="date"
                bsSize="sm"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <InputData
                id="endDate"
                name="endDate"
                placeholder="End date"
                type="date"
                bsSize="sm"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>

        {/* Row for Starting Amount and Ending Amount */}
        <Row style={{ marginTop: '2px' }}>
          <Col md={4}>
            <FormGroup>
              <Label for="category">Category</Label>
              <InputData
                id="category"
                name="category"
                placeholder="Category"
                type="select"
                bsSize="sm"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="select category">Select Category</option>
                <option value="Transaction Amount">Transaction Amount</option>
              </InputData>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="startingAmount">Starting Amount</Label>
              <InputData
                id="startingAmount"
                name="startingAmount"
                placeholder="Starting Amount"
                type="number"
                bsSize="sm"
                value={startingAmount}
                onChange={(e) => setStartingAmount(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="endingAmount">Ending Amount</Label>
              <InputData
                id="endingAmount"
                name="endingAmount"
                placeholder="Ending Amount"
                type="number"
                bsSize="sm"
                value={endingAmount}
                onChange={(e) => setEndingAmount(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Click type='button' size='sm' color='success' onClick={handleFilter}>Filter</Click>
          <div style={{ marginLeft: '10px' }}></div>
          <Click type='button' size='sm' color='success' onClick={handleRedo}>Reset</Click>
        </div>
      </Widget>
      <Widget>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm">
              CSV
            </Click>
            <Click color="success" size="sm">
              PDF
            </Click>
            <ReactToPrint
              trigger={() => (
                <Click color="success" disabled={printing} size="sm">
                  {printing ? <Spinner size="sm" style={{ color: 'white' }} /> : <i className="fa fa-print" aria-hidden="true"></i>}
                </Click>
              )}
              content={() => componentRef.current} // Specify the component to be printed
              onBeforeGetContent={() => setPrinting(true)}
              onAfterPrint={() => setPrinting(false)}
            />
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>
        {showSummaryTable && (
          <Widget
          title={<h7><span className="fw-semi-bold">Summary of the Filtered Data</span></h7>}
          >
            {/* Summary table JSX */}
            <div style={{ overflowX: 'auto', overflowY: 'auto' }} className='mt-3'>
            <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm">
              Export
            </Click>
          </ButtonGroup>
          </div>
            <Table columns={summaryColumns} dataSource={summaryData} bordered size="small" />
            </div>
          </Widget>
        )}
      </Widget>
    </div>
  );
};

export default TransactionsRecords;

