import React, { useState } from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
} from 'reactstrap';
import Widget from '../../components/Widget';
import s from './LoanCharts.module.scss';
import LoanWidgets from './loanWidgets/loanWidgets';
import AreaPayDis from './loanCharts/AreaPayDis';



const LoanCharts = () => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const formatDate = (str) => {
    return str.replace(/,.*/, "");
  };

  const toggleDropdown = () => {
    setIsDropdownOpened(prevState => !prevState);
  };

  return (
    <div className={s.root}>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Loan-Charts</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Loan visualizations</h4>
      <Row>
        
         <LoanWidgets/>

        {/* Area Chart */}
        <Col xs={12}>
          <Widget
           style={{ height: '300px'}} 
           title={<h5><span className="fw-semi-bold">Disbursements Versus Payments Trend</span></h5>}
          >
            <AreaPayDis />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default LoanCharts;


