import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Breadcrumb, BreadcrumbItem, Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc,doc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';

const SharesPayoutUser = ({ savingsId,refreshTable }) => {
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const componentRef = useRef(null);

  const fetchUserSharesPayouts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      
      // Get the user data from the Loans collection
      const sharesDoc = await getDoc(doc(db, 'Savings', savingsId));
      const userData = sharesDoc.exists() ? sharesDoc.data().userSavings : null;
  
      if (userData) {
        // Query allTransaction2 collection where transactionType is 'Loan Payment' 
        // and transactionUser is equal to the user data retrieved from Loans collection
        const querySnapshot = await getDocs(
          query(
            collection(db, 'PaymentData'),
            where('transaction', '==', 'Shares Payout'),
            where('userReference', '==', userData)
          )
        );
  
        // Map the fetched data and retrieve additional user details
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        const updatedData = await Promise.all(fetchedData.map(async (item) => {
          const userDoc = await getDoc(item.userReference);
          const userData = userDoc.exists() ? userDoc.data() : null;
          return {
            ...item,
            id: item.id,
            member: userData ? userData.display_name : '',
            voucher: item.voucher // Use user's display name for the "Member" column
          };
        }));
  
        // Sort the data based on transactionDate
        const sortedData = updatedData.sort((a, b) => b.PayoutDate.seconds - a.PayoutDate.seconds);
  
        setData(sortedData);
      } else {
        setData([]);
      }
  
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  

  useEffect(() => {
    if (savingsId) {
      fetchUserSharesPayouts();
    }
  }, [savingsId,refreshTable]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (paySlipUrl) => {
    window.open(paySlipUrl, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transactionType) => {
    return transactionType === "Shares Payout" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Shares Payout</span>
      </Badge>
    ) : null;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      ...getColumnSearchProps('member'),
      sorter: (a, b) => a.member - b.member,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Amount Transferred',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      ...getColumnSearchProps('Amount'),
      sorter: (a, b) => a.Amount - b.Amount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Payout Type',
      dataIndex: 'transaction',
      key: 'transaction',
      align: 'center',
      ...getColumnSearchProps('transaction'),
      sorter: (a, b) => a.transaction - b.transaction,
      sortDirections: ['descend', 'ascend'],
    //   render: renderLoanType,
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (text) => (
        <Space>
         <Click outline color="success" style={{ borderRadius: '10px' }} onClick={() => handleVoucherClick(text)}>
            <i className="fa fa-external-link" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
    {
      title: 'Payout Date',
      dataIndex: 'PayoutDate',
      key: 'PayoutDate',
      align: 'center',
      sorter: (a, b) => a.PayoutDate - b.PayoutDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
      <h5 className="mb-lg">List Of Previous Payout Disbursements</h5>
      <Widget>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>
      </Widget>
    </div>
  );
};

export default SharesPayoutUser;
