import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Badge,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody, ModalFooter,Form} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc,doc,updateDoc,serverTimestamp } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import Swal from 'sweetalert2';

const LoansReceipts = ({ loanId, refreshTable }) => {
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [declineReason, setDeclineReason] = useState(false);
  const[reasonInput, setReasonInput] = useState(" ");
  const [currentId, setCurrentId] = useState(null);

  const componentRef = useRef(null);

  const fetchUserLoanTransactions = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      
      // Get the user data from the Loans collection
      const loanDoc = await getDoc(doc(db, 'Loans', loanId));
      const userData = loanDoc.exists() ? loanDoc.data().user : null;
  
      if (userData) {
        // Query allTransaction2 collection where transactionType is 'Loan Payment' 
        // and transactionUser is equal to the user data retrieved from Loans collection
        const querySnapshot = await getDocs(
          query(
            collection(db, 'allTransaction2'),
            where('transactionType', '==', 'Loan Payment'),
            where('approvalStatus', '==', 'Pending'),
            where('transactionUser', '==', userData)
          )
        );
  
        // Map the fetched data and retrieve additional user details
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        const updatedData = await Promise.all(fetchedData.map(async (item) => {
          const userDoc = await getDoc(item.transactionUser);
          const userData = userDoc.exists() ? userDoc.data() : null;
          return {
            ...item,
            id: item.id,
            member: userData ? userData.display_name : '',
            paySlip: item.paySlip,
          };
        }));
  
        // Sort the data based on transactionDate
        const sortedData = updatedData.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);
  
        setData(sortedData);
      } else {
        setData([]);
      }
  
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  

  useEffect(() => {
    if (loanId) {
      fetchUserLoanTransactions();
    }
  }, [loanId,refreshTable]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleApproveClick = async (id) => {
    console.log("ID:", id);
    try {
      // Find the transaction with the specified ID in the local state
      const { db } = fetchFirebaseConfig();
  
      const transDocRef = doc(db, 'allTransaction2', id);
  
      // Fetch the existing transaction data
      const transDocSnapshot = await getDoc(transDocRef);
      if (transDocSnapshot.exists()) {
        const transactionData = transDocSnapshot.data();
  
        // Display the confirmation modal
        const result = await Swal.fire({
          icon: 'warning',
          title: 'Confirm this action',
          text: 'Are you sure you want to approve this transaction?',
          showCancelButton: true,
          confirmButtonColor: '#28a745',
          cancelButtonColor: '#dc3545',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        });
  
        // Check if the confirm button is clicked
        if (result.isConfirmed) {
          // Update the Firestore document with 'approvalStatus' and 'dateApproved'
          await updateDoc(transDocRef, {
            approvalStatus: 'Approved',
            dateApproved: serverTimestamp(),
          });
  
          // Fetch the loan document
          const loanDoc = await getDoc(doc(db, 'Loans', loanId));
          if (loanDoc.exists()) {
            const loanData = loanDoc.data();
  
            // Update amountPaid and amountRemaining
            const updatedAmountPaid = loanData.amountPaid + transactionData.transactionAmount;
            const updatedAmountRemaining = loanData.amountRemaining - transactionData.transactionAmount;
            const updatedLoanStatus = loanData.amountRemaining === 0 ? 'Paid' : 'Incomplete';
  
            // Update the loan document
            await updateDoc(doc(db, 'Loans', loanId), {
              amountPaid: updatedAmountPaid,
              amountRemaining: updatedAmountRemaining,
              loanStatus:updatedLoanStatus
            });
  
            // Display the success alert
            await Swal.fire({
              icon: 'success',
              title: 'Transaction Approved',
              text: 'The transaction has been successfully approved!',
              confirmButtonColor: '#28a745',
            });
  
            // Refresh the user's loan transactions
            fetchUserLoanTransactions();
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
   

  const toggleDeclineReason = () => {
    setDeclineReason(!declineReason);
  };

  const handleDeclineClick = async (id) => {
    // Display the confirmation modal before showing the decline reason modal
    Swal.fire({
      icon: 'warning',
      title: 'Confirm this action',
      text: 'Are you sure you want to decline this transaction?',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      // Check if the confirm button is clicked
      if (result.isConfirmed) {
        // Set the currentId state variable
        setCurrentId(id);
        // Set the decline reason modal to open
        setDeclineReason(true);
      }
    });
  };

  const handleDeclineSubmit = async () => {
    try {
      // Find the transaction with the specified ID in the local state
      const { db } = fetchFirebaseConfig();
      // Display the success alert when the decline reason is submitted
      toggleDeclineReason();

      const transDocRef = doc(db, 'allTransaction2', currentId);  
      // Fetch the existing transaction data
      const transDocSnapshot = await getDoc(transDocRef);
      if (transDocSnapshot.exists()) {
        // Update the Firestore document with 'approvalStatus', 'declineReason', and 'dateApproved'
        await updateDoc(transDocRef, {
          approvalStatus: 'Denied',
          declineReason: reasonInput, // Assuming reasonInput is defined elsewhere
          dateApproved: serverTimestamp(),
        });
  
        Swal.fire({
          icon: 'success',
          title: 'Transaction Declined Successfully',
          text: 'The transaction has been declined successfully!',
          confirmButtonColor: '#28a745',
        });

        fetchUserLoanTransactions();
        setReasonInput(" ")
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
  

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (paySlipUrl) => {
    window.open(paySlipUrl, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transactionType) => {
    return transactionType === "Loan Payment" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Loan Payment</span>
      </Badge>
    ) : null;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      ...getColumnSearchProps('member'),
      sorter: (a, b) => a.member - b.member,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Amount',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      ...getColumnSearchProps('transactionAmount'),
      sorter: (a, b) => a.transactionAmount - b.transactionAmount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      ...getColumnSearchProps('transactionType'),
      sorter: (a, b) => a.transactionType - b.transactionType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Receipt',
      dataIndex: 'paySlip',
      key: 'paySlip',
      align: 'center',
      render: (text) => (
        <Space>
         <Click outline color="success" style={{ borderRadius: '10px' }} onClick={() => handleVoucherClick(text)}>
            <i className="fa fa-external-link" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      align: 'center',
      ...getColumnSearchProps('approvalStatus'),
      sorter: (a, b) => a.approvalStatus - b.approvalStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      ...getColumnSearchProps('transactionDate'),
      sorter: (a, b) => a.transactionDate - b.transactionDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space>
          <Click  color='success' size='sm' onClick={() => handleApproveClick(record.id)}>
            APPROVE
          </Click>
          <Click  color='danger' size='sm' onClick={() => handleDeclineClick(record.id)}>
            DECLINE
          </Click>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h5 className="mb-lg">List Of Payment Receipts & PaySlips</h5>
      <Widget>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>

        <Modal isOpen={declineReason} toggle={toggleDeclineReason}>
          <ModalHeader toggle={toggleDeclineReason} style={{border:'none',marginLeft:'15px'}}>Reason Of Decline</ModalHeader>
          <ModalBody>
          <Widget>
          <Form className='mb-4'>
            <FormGroup>
              <Label for="name">Reason</Label>
              <InputData 
                type="textarea"
                id="reason"               
                value={reasonInput}
                onChange={(e) => setReasonInput(e.target.value)} />
            </FormGroup>
             <div style={{ display: 'flex', justifyContent: 'flex-start',marginTop:'12px'}}>
              <Click color="success" onClick={handleDeclineSubmit}>Submit</Click>{' '}
              <Click color="danger" onClick={toggleDeclineReason} style={{ marginLeft: '10px' }}>Cancel</Click>
              </div>
            </Form>
            </Widget> 
          </ModalBody> 
        </Modal>
      </Widget>
    </div>
  );
};

export default LoansReceipts;
