import { getDocs, collection, query, where } from "firebase/firestore";
import { fetchFirebaseConfig } from "../../firebase";

// Function to fetch users from Firestore based on company_id
export const fetchUsers = async (companyCode) => {
  const { db } = fetchFirebaseConfig();
  const usersCollection = collection(db, "users");
  
  // Create a query to filter users where company_id array contains companyCode
  const q = query(usersCollection, where("company_id", "array-contains", companyCode));
  const usersSnapshot = await getDocs(q);

  const users = [];
  usersSnapshot.forEach((doc) => {
    const userData = doc.data();
    users.push(userData);
  });

  return users;
}

