import React, { useState,useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  ButtonGroup
} from 'reactstrap';
import Widget from '../../components/Widget';
import s from './CompanyView.module.scss';
import AreaPayDis from '../loans/loanCharts/AreaPayDis';
import CompanyWidgets from './companyWidgets';
import { useHistory, useParams, Link } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';
import { Breadcrumb } from 'antd';


const CompanyView = () => {

  const history = useHistory();
  const { id } = useParams(); // Get id from URL
  const { db } = fetchFirebaseConfig();
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyDoc = doc(db, 'Companies', id);
        const companySnapshot = await getDoc(companyDoc);
        if (companySnapshot.exists()) {
          const companyData = companySnapshot.data();
          setCompanyName(companyData.CompanyName);
          setCompanyLogo(companyData.CompanyLogo);
        } else {
          console.error('Company not found');
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [db, id]);

  return (
    <div className={s.root}>
       <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/app/companies/companies-list">View Companies</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Company Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className={s.companyCardWrapper}>
        <div className={s.tinyCard}>
        {companyLogo ? (
            <img src={companyLogo} alt="Company Logo" className={s.companyLogo} />
          ) : (
            <i className="fa fa-building-o" aria-hidden="true"></i>
          )}
        </div>
        <h4 className={s.companyName}>{companyName}</h4>
      </div>
      <Row className="d-flex justify-content-end"> {/* Added flex utilities */}
        <Col xs={12} className="mb-3"> {/* Added margin bottom */}
          <div className="d-flex justify-content-end"> {/* Flex container */}
            <ButtonGroup size='sm'>
              <Button color='primary' style={{marginRight:'2px'}} onClick={() => history.push(`/app/company/company-view/company-profile/${id}`)}>
              <i class="fa fa-newspaper-o" aria-hidden="true"></i> Portfolio
              </Button>
              <Button color='primary' style={{marginRight:'2px'}} onClick={() => history.push(`/app/company/company-view/company-transactions/${id}`)}>
              <i class="fa fa-exchange" aria-hidden="true"></i> Transactions
              </Button>
              <Button color='primary' style={{marginRight:'2px'}} onClick={() => history.push(`/app/company/company-view/company-stakeholders/${id}`)}>
              <i class="fa fa-group" aria-hidden="true"></i> stakeholders
              </Button>
              <Button color='primary' style={{marginRight:'2px'}} onClick={() => history.push("/app/company/company-view/company-shares")}>
              <i class="fa fa-share-alt" aria-hidden="true"></i> shares
              </Button>
            </ButtonGroup>
          </div>
        </Col>

        <Col xs={12}>
          <CompanyWidgets id={id}/>
        </Col>

        {/* Area Chart */}
        <Col xs={12}>
          <Widget
            style={{ height: '300px'}} 
            title={<h5><span className="fw-semi-bold">Funding Monthly trend</span></h5>}
          >
            <AreaPayDis />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyView;



