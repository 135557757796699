import React, { useState,useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardImg,
  Button,
} from 'reactstrap';
import FinancialInfo from './financialInfo';
import {fetchFirebaseConfig} from '../../firebase';
import { useHistory, useParams, Link } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import { Breadcrumb } from 'antd';

export default function CompanyProfile() {

  const history = useHistory();
  const { id } = useParams(); // Get id from URL
  const { db } = fetchFirebaseConfig();
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyJoined, setCompanyJoined] = useState('');
  const [companyMobile, setCompanyMobile] = useState('');

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyDoc = doc(db, 'Companies', id);
        const companySnapshot = await getDoc(companyDoc);
        if (companySnapshot.exists()) {
          const companyData = companySnapshot.data();
          setCompanyName(companyData.CompanyName);
          setCompanyLogo(companyData.CompanyLogo);
          setCeoName(companyData.CompanyCeo);
          setCompanyEmail(companyData.CompanyEmail)
          setCompanyAddress(companyData.CompanyAddress)
          setCompanyJoined(companyData.CompanyJoinedDate)
          setCompanyMobile(companyData.CompanyMobile)
        } else {
          console.error('Company not found');
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [db, id]);
  
  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  return (
    <section>
      <Container className="py-5" style={{marginTop:'-45px'}}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/app/companies/company-view/${id}`}>View Stakeholders</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Company Portfolio</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Update {companyName} Profile</h4>
        <Row>
          <Col lg="4">
          <Card className="mb-4">
            <CardBody className="text-center">
            {companyLogo ? (
                <CardImg
                  src={companyLogo}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                /> 
              ) : (
                <i className="fa fa-building-o" aria-hidden="true" style={{fontSize:"4.5rem", color:'#c7c5c1'}}></i>
              )}            
              <CardText className=" mb-1 mt-2" style={{fontWeight:'bold'}}><h5>{companyName}</h5></CardText>
              <CardText className="text-muted mb-4"><h6><i className="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp;{ceoName}</h6></CardText>
              <div className="d-flex justify-content-center mb-2">
                <Button color="primary" size='sm' outline><i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Edit</Button>
                {/* <Button outline className="ms-1">Message</Button> */}
              </div>
            </CardBody>
            </Card>
              <Card className="mb-4 mb-lg-0">
                <CardBody className="p-0 d-flex flex-column justify-content-between">
                  <ul className="list-group list-group-flush rounded-3">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight:'bold' }}>email</span>
                      <span style={{ color: '#333333' }}>{companyEmail}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight:'bold' }}>Mobile</span>
                      <span style={{ color: '#333333' }}>{companyMobile}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <span style={{ color: '#333333', fontWeight:'bold' }}>address</span>
                      <span style={{ color: '#333333' }}>{companyAddress}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                        <span style={{ color: '#333333', fontWeight:'bold' }}>created on</span>
                        <span style={{ color: '#333333' }}>{renderDateRequested(companyJoined)}</span>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
              <Col lg="8">
              <Row>
              <Col md="12">
                 <Card className="mb-4 mb-md-0">
                <CardBody>
                  <FinancialInfo id={id}/>
                </CardBody>
                </Card>
              </Col>
            </Row>
        </Col>
        </Row>
      </Container>
    </section>
  );
}


















