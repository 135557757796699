import React, { useState, useEffect } from 'react';
import { Row, Col, Statistic } from 'antd';
import { collection, query, where, getDoc,doc } from "firebase/firestore";
import {fetchFirebaseConfig} from '../../firebase';
import CountUp from 'react-countup';
import { Progress } from 'antd';
import UserCard from '../../components/UserWidgets/userCard';

const FinancialInfo = ({ id }) => {

  const { db } = fetchFirebaseConfig();
  const [companyStaff, setCompanyStaff] = useState('');


  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyDoc = doc(db, 'Companies', id);
        const companySnapshot = await getDoc(companyDoc);
        if (companySnapshot.exists()) {
          const companyData = companySnapshot.data();
          setCompanyStaff(companyData.CompanyStaff);
        } else {
          console.error('Company not found');
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [db, id]);

  return (
    <div className="financial-summary">
      <h5>Company Portfolio</h5>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <UserCard
            title="Total Investments"
            value={<div style={{ fontWeight: 600 }}><CountUp end={0} duration={2} separator="," /></div>}
            icon={<i className="fa fa-bank icon-color" aria-hidden="true"></i>}
            color="#5790c9"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
          <UserCard
            title="Total Staff"
            value={<div style={{ fontWeight: 600 }}><CountUp end={companyStaff} duration={2} separator="," /></div>}
            icon={<i className="fa fa-group icon-color" aria-hidden="true"></i>}
            color="#e3206b"
            showTSH={true}
          />
        </Col>
        <Col span={12}>
        <div>
            <span style={{fontWeight: 600}}>
                <CountUp end={6500000} duration={2} separator="," />
            </span>
            <span style={{color: 'rgba(0, 0, 0, 0.5)'}}>&nbsp;Tsh</span>
        </div>
          <Progress className="my-2" percent={20}status="active" />
          <p>Shares Disbursed</p>
        </Col>
        <Col span={12}>
        <div>
            <span style={{fontWeight: 600}}>
                <CountUp end={450000} duration={2} separator="," />
            </span>
            <span style={{color: 'rgba(0, 0, 0, 0.5)'}}>&nbsp;Tsh</span>
        </div>
          <Progress className="my-2" percent={50} />
          <p>Funds Collected</p>
        </Col>
        {/* <Col span={12}>
        <div>
            <span style={{fontWeight: 600}}>
                <CountUp end={200000} duration={2} separator="," />
            </span>
            <span style={{color: 'rgba(0, 0, 0, 0.5)'}}>&nbsp;Tsh</span>
        </div>
          <Progress className="my-2" percent= {10} status="active" />
          <p>Total Mid-Term Loan</p>
        </Col> */}
      </Row>
    </div>
  );
};

export default FinancialInfo;
