import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import LinksGroup from './LinksGroup/LinksGroup';
import s from './Sidebar.module.scss';
import { UserAuth } from '../../context/AuthContext';
import { useState, useEffect } from 'react';
import fetchFirebaseConfig from '../../firebase';
import { getDocs, doc, getDoc, collection, onSnapshot, query, where } from "firebase/firestore";
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

const Sidebar = () => {
  const { user } = UserAuth();

  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible3a, setIsVisible3a] = useState(false);
  const [isVisible3b, setIsVisible3b] = useState(false);
  const [isVisible2a, setIsVisible2a] = useState(false);
  const [isVisible2b, setIsVisible2b] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible4a, setIsVisible4a] = useState(false);
  const [isVisible4b, setIsVisible4b] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  const [isVisible5a, setIsVisible5a] = useState(false);
  const [isVisible5b, setIsVisible5b] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible1(accessArray.includes(1));
            setIsVisible2(accessArray.includes(2));
            setIsVisible2a(accessArray.includes(2.1));
            setIsVisible2b(accessArray.includes(2.2));
            setIsVisible3(accessArray.includes(3));
            setIsVisible3a(accessArray.includes(3.1));
            setIsVisible3b(accessArray.includes(3.2));
            setIsVisible4(accessArray.includes(4));
            setIsVisible4a(accessArray.includes(4.1));
            setIsVisible4b(accessArray.includes(4.2));
            setIsVisible5(accessArray.includes(5));
            setIsVisible5a(accessArray.includes(5.1));
            setIsVisible5b(accessArray.includes(5.2));
          } else {
            setIsVisible1(false);
            setIsVisible2(false);
            setIsVisible2a(false);
            setIsVisible2b(false);
            setIsVisible3(false);
            setIsVisible3a(false);
            setIsVisible3b(false);
            setIsVisible4(false);
            setIsVisible4a(false);
            setIsVisible4b(false);
            setIsVisible5(false);
            setIsVisible5a(false);
            setIsVisible5b(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchVisibility();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  return (
  <nav className={s.root}>
    <header className={s.logo}>
      <Link to="/app/main">
      <img src="SharesTracker2.png" alt="Description of the image" style={{ width: '150px', height: '150px' }}/>
     </Link>
    </header>
     
    {/* <header className={s.companyName}>
      SHARESTRACKER
    </header> */}
  
    <div className={`${s.divider} ${s.navWrapper}`}>{/* Add wrapper div */}
      <ul className={s.nav} >
      {isVisible1 && (
        <LinksGroup
          header="Dashboard"
          headerLink="/app/main"
          icon={<i class="fa fa-home" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
        />
      )}
        {isVisible2 && (
         <LinksGroup
         header="Stakeholders"
         headerLink="/app/stakeholders"
         icon={<i class="fa fa-group" aria-hidden="true" style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}
         visible={isVisible2a || isVisible2b} // Show if either visible1 or visible2 is true
         childrenLinks={[
           isVisible2a && { // Show only if visible1 is true
             name: 'view stakeholders',
             link: "/app/stakeholders/all-stakeholders",
           },
           isVisible2b && { // Show only if visible2 is true
             name: 'Add stakeholder',
             link: "/app/stakeholders/register-stakeholders",
           },
         ].filter(Boolean)} // Remove falsy values from the array
         />
      )}
        {isVisible3 && (
         <LinksGroup
            header="Companies"
            headerLink="/app/companies"
            icon={<i class="fa fa-building" aria-hidden="true" style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}
            visible={isVisible3a || isVisible3b} // Show if either visible1 or visible2 is true
            childrenLinks={[
              isVisible3a && { // Show only if visible1 is true
                name: 'view companies',
                link: "/app/companies/companies-list",
              },
              isVisible3b && { // Show only if visible2 is true
                name: 'Add company',
                link: "/app/companies/register-companies",
              },
            ].filter(Boolean)} // Remove falsy values from the array
            />
           )}
         {isVisible4 && (
          <LinksGroup
            header="Requests"
            headerLink="/app/requests"
            icon={<i class="fa fa-commenting" aria-hidden="true" style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}
            visible={isVisible4a || isVisible4b} // Show if either visible1 or visible2 is true
            childrenLinks={[
              isVisible4a && { // Show only if visible1 is true
                name: 'Shares requests',
                link: "/app/requests/shares-requests",
              },
              isVisible4b && { // Show only if visible2 is true
                name: 'Fund requests',
                link: "/app/requests/funds-requests",
              },
            ].filter(Boolean)} // Remove falsy values from the array
            />
          )}
         {isVisible5 && (
          <LinksGroup
          header="Settings"
          headerLink="/app/settings"
          icon={<i class="fa fa-cog" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
          visible={isVisible5a || isVisible5b} // Show if either visible1 or visible2 is true
          childrenLinks={[
            // isVisible5a && { // Show only if visible1 is true
            //   name: 'shares settings',
            //   link: "/app/settings/shares-settings",
            // },
            isVisible5b && { // Show only if visible2 is true
              name: 'General Settings',
              link: "/app/settings/general-settings",
            },
          ].filter(Boolean)} // Remove falsy values from the array
          />
         )} 
        {/* {visible1 && (
         <LinksGroup
            header="Members"
            headerLink="/app/members"
            icon={<i class="fa fa-users" aria-hidden="true" style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}
            visible={visible2 || visible3} // Show if either visible1 or visible2 is true
            childrenLinks={[
              visible1 && { // Show only if visible1 is true
                name: 'members',
                link: "/app/members/members-list",
              },
              visible2 && { // Show only if visible2 is true
                name: 'Register member',
                link: "/app/members/register-member",
              },
            ].filter(Boolean)} // Remove falsy values from the array
            />
           )} */}
        {/* <LinksGroup
          header="Loans"
          headerLink="/app/loans"
          icon={<i class="fa fa-bank" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>} // Pass the icon component directly
          childrenLinks={[
            {
              name: 'Long-Term Loans',
              link: "/app/loans/long-term-loans",
            },
            {
              name: 'Mid-Term Loans',
              link: "/app/loans/mid-term-loans" ,
            },
            {
              name: 'Short-Term Loans',
              link: "/app/loans/short-term-loans" ,
            },
            {
              name: 'Disbursements',
              link: "/app/loans/loan-payouts" ,
            },
            {
              name: 'Payments',
              link: "/app/loans/loan-transactions" ,
            },
            {
              name: 'Categories',
              link: "/app/loans/loan-categories" ,
            },
            {
              name: 'Loans Charts',
              link: "/app/loans/loan-Charts" ,
            },
            {
              name: 'Management',
              link: "/app/loans/loan-settings" ,
            },
          ]}       
        /> */}
        {/* <LinksGroup
          header="Savings"
          headerLink="/app/savings"
          childrenLinks={[
            {
              name: 'Savings Accounts',
              link: "/app/savings/savings-accounts",
            },
            {
              name: 'Payments',
              link: "/app/savings/savings-transactions" ,
            },
            {
              name: 'Disbursements',
              link: "/app/savings/savings-payouts",
            },
            {
              name: 'Savings Charts',
              link: "/app/savings/savings-charts" ,
            },
          ]}
          icon={<i class="fa fa-balance-scale" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>} // Pass the icon component directly
        /> */}
        {/* <LinksGroup
          header="Shares"
          headerLink="/app/shares"
          childrenLinks={[
            {
              name: 'Shares Accounts',
              link: '/app/shares/shares-accounts',
            },
            {
              name: 'Payments',
              link: '/app/shares/shares-transactions',
            },
            {
              name: 'Disbursements',
              link: '/app/shares/shares-disbursements',
            },
            {
              name: 'Shares Charts',
              link: '/app/shares/shares-charts',
            },
          ]}
          icon={<i class="fa fa-suitcase" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>} // Pass the icon component directly
        /> */}
       {/* {visible && ( */}
        {/* <LinksGroup
          header="Transactions"
          headerLink="/app/transactions"
          childrenLinks={[
            {
              name: 'View Transactions',
              link: '/app/transactions/all-transactions',
            },
            {
              name: 'Categories',
              link: '/app/transactions/transaction-categories',
            },
          ]}
          icon={<i className="fa fa-credit-card" aria-hidden="true" style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}
        /> */}
          {/* <LinksGroup
          header="Accounting"
          headerLink="/app/accounting"
          childrenLinks={[
            {
              name: 'Account Charts',
              link: '/app/accounting/account-charts',
            },
            {
              name: 'Journals',
              link: '/app/accounting/account-journals',
            },
            {
              name: 'Balance Sheet',
              link: '/app/accounting/balance-sheet',
            },
            {
              name: 'General Legder',
              link: '/app/accounting/general-ledger',
            },
            {
              name: 'Profit & Loss',
              link: '/app/accounting/profit-loss',
            },
            {
              name: 'Trial Balance',
              link: '/app/accounting/trial-balance',
            },
            {
              name: 'Cash Flow',
              link: '/app/accounting/cash-flow',
            },
          ]}
          icon={<i class="fa fa-book" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>} // Pass the icon component directly
        /> */}
        {/* <LinksGroup
          header="Financial Reports"
          headerLink="/app/financial-reports"
          icon={<i class="fa fa-file-text" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
        />
         <LinksGroup
          header="Records"
          headerLink="/app/records"
          childrenLinks={[
            {
              name: 'Loan Records',
              link: '/app/records/loans-records',
            },
            {
              name: 'Savings Records',
              link: '/app/records/savings-records',
            },
            {
              name: 'Shares Records',
              link: '/app/records/shares-records',
            },
            {
              name: 'Transactions Records',
              link: '/app/records/transactions-records',
            },
          ]}
          icon={<i class="fa fa-folder-open" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>} // Pass the icon component directly
        />
         <LinksGroup
          header="Inquiries"
          headerLink="/app/Inquiries"
          icon={<i class="fa fa-question-circle" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
        />
        <LinksGroup
          header="Posts"
          headerLink="/app/posts"
          icon={<i class="fa fa-newspaper-o" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
        />
         <LinksGroup
          header="Permissions"
          headerLink="/app/roles-permissions"
          icon={<i class="fa fa-shield" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
        />
         <LinksGroup
          header="Settings"
          headerLink="/app/general-settings"
          icon={<i class="fa fa-cog" aria-hidden="true"  style={{ fontSize: '1.2em', color: '#c0c0c0' }}></i>}// Pass the icon component directly
        /> */}
      </ul>
    </div>
  </nav>
);
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));

