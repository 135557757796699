import React, { useState, useEffect } from 'react';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Card,List, Skeleton,Flex, Progress } from 'antd';
import { Row, Col, Form, FormGroup, Label, Input, Button, Breadcrumb, BreadcrumbItem,Badge,Collapse } from 'reactstrap';
import {fetchFirebaseConfig} from '../../firebase';
import { Avatar, Divider, Tooltip,Steps } from 'antd';
import {
    doc,
    getDoc,
    updateDoc,
    addDoc,
    collection,
    serverTimestamp,
    getDocs,
    where,
    query,
    onSnapshot
  } from 'firebase/firestore';
  import CountUp from 'react-countup';

  const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
  };

  const conicColors = {
    '50%': '#ffadad',
    '100%': '#ff7f7f',
  };

const LoanSummaryDetails = ({ id }) => {

  const [userDetails, setUserDetails] = useState(null);
  const [loansDetails, setLoanDetails] = useState(null);
  const [guarantor1Details, setGuarantor1Details] = useState(null);
  const [guarantor2Details, setGuarantor2Details] = useState(null);
  const [isOpenGuarantor, setIsOpenGuarantor] = useState(false);
  const [isOpenCommitee, setIsOpenCommitee] = useState(false);


  const toggleGuarantor = () => setIsOpenGuarantor(!isOpenGuarantor);

  const toggleCommitee = () => setIsOpenCommitee(!isOpenCommitee);

  const {db}=fetchFirebaseConfig();

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const { db } = fetchFirebaseConfig();
        const loanRef = doc(db, 'Loans', id);
        const loanSnapshot = await getDoc(loanRef);
  
        if (loanSnapshot.exists()) {
          const loanData = loanSnapshot.data();
          setLoanDetails(loanData);
  
          const userRef = loanData.user;
          const userSnapshot = await getDoc(userRef);
  
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);
  
            const updatedLoanData = {
              ...loanData,
              user: userData.display_name,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userid: userData.uid,
            };
  
            setLoanDetails(updatedLoanData);
  
            // Subscribe to changes in loan data
            onSnapshot(loanRef, (updatedLoanSnapshot) => {
              if (updatedLoanSnapshot.exists()) {
                const updatedLoanData = updatedLoanSnapshot.data();
                setLoanDetails(updatedLoanData);
              }
            });
          } else {
            setUserDetails(null);
          }
  
          // Fetch guarantor details
          const guarantor1Name = loanData.guaranteer1;
          const guarantor2Name = loanData.guaranteer2;
  
          const guarantor1Query = query(collection(db, 'users'), where('display_name', '==', guarantor1Name));
          const guarantor2Query = query(collection(db, 'users'), where('display_name', '==', guarantor2Name));
  
          const [guarantor1Snapshot, guarantor2Snapshot] = await Promise.all([
            getDocs(guarantor1Query),
            getDocs(guarantor2Query)
          ]);
  
          const guarantor1Data = guarantor1Snapshot.docs[0]?.data();
          const guarantor2Data = guarantor2Snapshot.docs[0]?.data();
  
          setGuarantor1Details(guarantor1Data);
          setGuarantor2Details(guarantor2Data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchLoanDetails();
  }, [db, id]);
  


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };


  const data = [
    {
      title: 'Approval status',
    },
  ]


  return (
    <div>
        <Widget>
            <div className="d-flex align-items-start justify-content-center flex-column" style={{ height: '100%' }}>
            <div style={{ borderBottom: `1px solid rgba(128, 128, 128, 0.05)`, width: '100%', marginBottom: '5px', paddingBottom: '5px'}}>
                <div className="justify-content-center align-items-center">
                  <h3 style={{textAlign: 'center', fontWeight:'bold'}}>Amount Requested</h3>
                  <h3 style={{textAlign: 'center', fontWeight:'bold'}}><CountUp start={0} end={loansDetails?.loanAmount1 || 0} duration={2} separator="," /></h3>
                </div>
              </div>
            <div className="d-flex flex-column align-items-start" style={{ paddingLeft: '15px' }}>
                  <div className="mr-4"> 
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Loan Term :&nbsp;</span> {loansDetails?.loanSchedual === 'Long Term Loan' ? ( <Badge color="success">Long-Term</Badge> ) : loansDetails?.loanSchedual === 'Mid Term Loan' ? ( <Badge color="info">Mid Term</Badge> ) : loansDetails?.loanState === 'Short Term Loan' ? ( <Badge color="primary">Short Term</Badge> ) : ( loansDetails?.loanSchedual ) || ''} </div>
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Date Requested :&nbsp;</span> <i className="fa fa-calendar" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; {renderDateRequested(loansDetails?.dateTaken || " ")} </div> 
                  <div className="mb-1"><span style={{ fontSize: '12px',fontWeight: '601' }}>Guarantors :&nbsp;</span>  
                  <Avatar.Group>
                  <Tooltip title={loansDetails?.guaranteer1 || ""} placement="top">
                    <Link to={`/app/members/members-list/user-details/${guarantor1Details?.uid}`}>
                    <Avatar  src={guarantor1Details?.photo_url || ""}  />
                    </Link>
                    </Tooltip>
                    <Tooltip title={loansDetails?.guaranteer2 || ""} placement="top">
                    <Link to={`/app/members/members-list/user-details/${guarantor2Details?.uid}`}>
                      <Avatar src={guarantor2Details?.photo_url || ""}/>                     
                    </Link>
                    </Tooltip>
                  </Avatar.Group>
                  </div>
                  </div> 
                </div> 
             </div>
            </Widget>
            <Widget>
            <div style={{marginTop:'-20px'}}>
            <Steps
              direction="vertical"
              size="small"
              current={
                loansDetails?.loanState === "Approved" || loansDetails?.loanState === "Denied" ? 3:
                loansDetails?.loanComiteeStatus === 1 ? 2 : // If loanComiteeStatus is 1, move to Admin's Loan Approval
                loansDetails?.guaranteersStatus === 1 ? 1 : 0 // Otherwise, check guaranteersStatus
              }
              items={[
                {
                  title: <i className="fa fa-users" aria-hidden="true"></i>,
                  description: (
                    <>
                      <List
                        itemLayout="horizontal"
                        style={{ marginTop: '-20px' }}
                        dataSource={data}
                        renderItem={(item, index) => (
                          <List.Item
                            actions={[<a key="list-loadmore-more" onClick={toggleGuarantor}>more</a>]}
                          >
                            <List.Item.Meta
                              avatar=""
                              title={<span style={{ fontSize: '12px' }}>Guarantor's Approval Progress</span>}
                            />
                          </List.Item>
                        )}
                      />
                      <Collapse isOpen={isOpenGuarantor}>
                        <Widget>
                          <Flex gap="small" wrap="wrap">
                          <Progress 
                              type="circle" 
                              size={55} 
                              percent={
                                loansDetails?.guaranteersStatus2 === 1 ? 100 : 
                                loansDetails?.guaranteersStatus2 === 0.5 ? 50 : 
                                loansDetails?.guaranteersStatus === 1 ? 100 :
                                loansDetails?.guaranteersStatus === 0.5 ? 50 :
                                loansDetails?.guaranteersStatus * 100
                              } 
                              strokeColor={
                                loansDetails?.guaranteersStatus2 === 1 || loansDetails?.guaranteersStatus2 === 0.5 ? conicColors :
                                twoColors
                              } 
                              status={
                                loansDetails?.guaranteersStatus2 === 1 || loansDetails?.guaranteersStatus2 === 0.5 ? "exception": ""
                              }
                            />
                          </Flex>
                        </Widget>
                      </Collapse>
                    </>
                  ),
                },
                {
                  title: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
                  description: (
                    <>
                      <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                          <List.Item
                            actions={[<a key="list-loadmore-more" onClick={toggleCommitee}>more</a>]}
                          >
                            <List.Item.Meta
                              avatar=""
                              title={<span style={{ fontSize: '12px' }}>Loan Commitee Approval Progress</span>}
                            />
                          </List.Item>
                        )}
                      />
                      <Collapse isOpen={isOpenCommitee}>
                        <Widget>
                          <Flex gap="small" wrap="wrap">
                          <Progress 
                              type="circle" 
                              size={55} 
                              percent={
                                loansDetails?.loanComiteeStatus2 === 1 ? 100 : 
                                loansDetails?.loanComiteeStatus2 === 0.5 ? 50 : 
                                loansDetails?.loanComiteeStatus === 1 ? 100 :
                                loansDetails?.loanComiteeStatus === 0.5 ? 50 :
                                loansDetails?.loanComiteeStatus * 100
                              } 
                              strokeColor={
                                loansDetails?.loanComiteeStatus2 === 1 || loansDetails?.loanComiteeStatus2 === 0.5 ? conicColors :
                                twoColors
                              } 
                              status={
                                loansDetails?.loanComiteeStatus2 === 1 || loansDetails?.loanComiteeStatus2 === 0.5 ? "exception": ""
                              }
                            />
                          </Flex>
                        </Widget>
                      </Collapse>
                    </>
                  ),
                },
                {
                  title: <i className="fa fa-user" aria-hidden="true"></i>,
                  description: (
                    <>
                      <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar=""
                              title={<span style={{ fontSize: '12px' }}>Admin's Loan Approval</span>}
                            />
                          </List.Item>
                        )}
                      />
                    </>
                  ),
                },
              ]}
            />
            </div>
      </Widget>
      </div>
  );
};

export default LoanSummaryDetails;
