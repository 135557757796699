import React, { useState,useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { useParams,useHistory} from "react-router-dom";
import { doc, getDoc,updateDoc,getDocs,collection } from "firebase/firestore";
import {fetchFirebaseConfig} from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast, ToastContainer } from 'react-toastify';
import Widget from '../../components/Widget/Widget';
//import 'react-toastify/dist/ReactToastify.css';


const EditUser = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [position, setPosition] = useState("Member");
  const [positionOptions, setPositionOptions] = useState([]);
  const [loanCommitee, setLoanCommmitee] = useState(false);
  const [gender, setGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [initialName, setInitialName] = useState('');
  const [interestRate, setInterestRate] = useState("");
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [profile, setProfile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [religion, setReligion] = useState("");
  const [refFirstName, setrefFirstName] = useState("");
  const [refMiddleName, setrefMiddleName] = useState("");
  const [refSirName, setrefSirName] = useState("");
  const [refgender, setrefGender] = useState("Male");
  const [refemail, setrefEmail] = useState("");
  const [refcontact, setrefContact] = useState("");
  const [heirFirstName, setheirFirstName] = useState("");
  const [heirMiddleName, setheirMiddleName] = useState("");
  const [heirSirName, setheirSirName] = useState("");
  const [heirgender, setheirGender] = useState("Male");
  const [heiremail, setheirEmail] = useState("");
  const [heircontact, setheirContact] = useState("");
  const [refrelationshipStatus, setrefrelationshipStatus] = useState("");
  const [ heirrelationshipStatus, setheirrelationshipStatus] = useState("");


  const { uid } = useParams(); // Get the UID from the URL

  const { db,storage } = fetchFirebaseConfig();

  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!uid) {
          console.error("UID is undefined");
          return;
        }

        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setInitialName(userData.display_name || '');
          setName(userData.display_name || '');
          setProfile(userData.photo_url|| '');
          setEmail(userData.email|| '');
          setPassword(userData.password|| '');
          setLoanCommmitee(userData.loanCommitee || false);
          setGender(userData.gender || 'Male');
          setPosition(userData.position || 'Member');
          setAddress(userData.location || '');
          setContact(userData.phone_number || '');
          setAccountName(userData.accountName || 'Mpesa');
          setAccountNo(userData.accountNo || '');
          setReligion(userData.religion || 'Christian');
          setrefFirstName(userData.refFirstName || '');
          setrefMiddleName(userData.refMiddleName || '');
          setrefSirName(userData.refSirName || '');
          setrefEmail(userData.refemail || '');
          setrefGender (userData.refgender || '');
          setrefContact(userData.refcontact || '');
          setheirFirstName(userData.heirFirstName || '');
          setheirMiddleName(userData.heirMiddleName || '');
          setheirSirName(userData.heirSirName|| '');
          setheirGender(userData.heirgender || '');
          setheirEmail(userData.heiremail || '');
          setheirContact(userData.heircontact || '');
          setrefrelationshipStatus(userData.refrelationshipStatus || '');
          setheirrelationshipStatus(userData. heirrelationshipStatus || '');
          setInterestRate(userData.interestRate ? userData.interestRate.toString() : '');
          if (selectedProfilePicture) {
            setPreviewUrl(URL.createObjectURL(selectedProfilePicture)); // Update preview URL
          }
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

      fetchUserData();
    }, [db, uid, selectedProfilePicture]);

    
    useEffect(() => {
      const fetchPermissions = async () => {
          try {
              const { db } = fetchFirebaseConfig();
              const permissionsCollection = collection(db, 'permissions');
              const permissionsSnapshot = await getDocs(permissionsCollection);
              const permissionsData = permissionsSnapshot.docs.map(doc => doc.data());
              const positions = permissionsData.map(permission => permission.Role);
              setPositionOptions(positions);
          } catch (error) {
              console.error('Error fetching permissions:', error);
          }
      };

      fetchPermissions();
  }, []);


      const handleEdit = async () => {

        setIsSubmitting(true);

        try {
          const userDocRef = doc(db, 'users', uid);
          await updateDoc(userDocRef, {
            display_name: name,
            loanCommitee,
            gender,
            position,
            location: address,
            phone_number: contact,
            interestRate: parseFloat(interestRate) || 0,
            accountName,
            accountNo,
            religion,
            refFirstName,
            refMiddleName,
            refSirName,
            refemail,
            refgender,
            refcontact,
            heirFirstName,
            heirMiddleName,
            heirSirName,
            heirgender,
            heiremail,
            heircontact,
          });
        
          // Upload the new profile picture if selected
          if (selectedProfilePicture) {
            const storageRef = ref(storage, `users/${uid}`);
            await uploadBytes(storageRef, selectedProfilePicture);
            const newPhotoUrl = await getDownloadURL(storageRef);    
        
            // Update the user document with the new profile picture URL
            await updateDoc(userDocRef, { photo_url: newPhotoUrl });
          }

          setIsSubmitting(false);
          
        //   toast.success('member info updated successfully!');
        
          setTimeout(() => {
            history.push("/app/members/members-list");
          }, 4000);         
        } catch (error) {
        //   toast.error('Error while updating user info');
        }
        
      };

  return (
        <div>
        <Breadcrumb>
            <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
            <BreadcrumbItem active>Edit Details</BreadcrumbItem>
        </Breadcrumb>
        <h4 className="mb-lg">Edit Member Details</h4>
            <Widget
                title={<h5>Update {initialName}&apos;s information</h5>}
                >
                <Form>
                  <p className="text-medium-emphasis">Edit member details</p>
                  {/* Profile Picture */}
                  <div style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-center">
                  {previewUrl ? (
                    <img
                      className="profileImg"
                      src={previewUrl}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                      alt="Profile Preview"
                    />
                  ) : profile ? (
                    <img
                      className="profileImg"
                      src={profile}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                    />
                  ) : (
                    <i className="fa fa-user-circle" style={{ fontSize: '7em', color: '#c0c0c0' }}></i>
                  )}
                </div>
                  <FormGroup className="mb-3">
                  <Label>Update image</Label>
                    <Input
                        type="file"
                        id="profileInput"
                        accept="image/*"
                        style={{fontSize:'13px'}}
                        onChange={(e) => {
                          setSelectedProfilePicture(e.target.files[0]);
                          setPreviewUrl(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                  </FormGroup>
                  {/* Name/Display Name */}
                  <FormGroup className="mb-3">
                  <Label>Name</Label>
                    <Input
                      placeholder="Username"
                      autoComplete="name"
                      value={name}
                      size="sm"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  {/* Email */}
                  <FormGroup className="mb-3">
                  <Label>Email</Label>
                    <Input
                      placeholder="Email"
                      autoComplete="email"
                      value={email}
                      size="sm"
                      disabled={true}
                    />
                  </FormGroup>

                  {/* Password */}
                  <FormGroup className="mb-3">
                  <Label>Password</Label>
                    <Input
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      value={password}
                      size="sm"
                      disabled={true}
                    />
                  </FormGroup>

                    {/* Religion */}
                   <FormGroup className="mb-3">
                   <Label>Religion</Label>
                   <Input
                      onChange={(e) => setReligion(e.target.value)} 
                      value={religion}
                      size="sm"
                      type='select'
                    >
                    <option value="Mpesa">Christian</option>
                    <option value="Tigopesa">Muslim</option>
                    <option value="Mpesa">Others</option>
                    </Input>
                  </FormGroup>

                  {/* Gender */}
                  <FormGroup className="mb-3">
                  <Label>Gender</Label>
                    <Input size="sm" type='select' onChange={(e) => setGender(e.target.value)} value={gender}>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Input>
                  </FormGroup>

                  {/* Loan commitee */}
                  <FormGroup className="mb-3">
                  <Label>Loan Committee</Label>
                    <Input
                      onChange={(e) => setLoanCommmitee(e.target.value === 'true')}
                      value={loanCommitee.toString()}
                      size="sm"
                      type='select'
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Input>
                  </FormGroup>

                  {/* Contact/Phone Number */}
                  <FormGroup className="mb-3">
                  <Label>First name</Label>
                    <Input
                      placeholder="Contact"
                      value={contact}
                      size="sm"
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </FormGroup>

                  {/* Position */}
                  <FormGroup className="mb-3">
                  <Label>Access permission</Label>
                    <Input
                            onChange={(e) => setPosition(e.target.value)}
                            value={position}
                            size="sm"
                            type='select'
                            >
                          <option value="Member">Member</option>
                           {positionOptions.map((option, index) => (
                           <option key={index} value={option}>{option}</option>
                            ))}
                     </Input>
                  </FormGroup>

                  {/* Address/Location */}
                  <FormGroup className="mb-3">
                  <Label>Address</Label>
                    <Input
                      placeholder="Address"
                      value={address}
                      size="sm"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormGroup>

                  {/* Interest */}
                  <FormGroup className="mb-3">
                    <Label>Account no#</Label>
                    <Input
                      placeholder="Account number"
                      value={accountNo}
                      size="sm"
                      onChange={(e) => setAccountNo(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Account name</Label>
                    <Input
                     onChange={(e) => setAccountName(e.target.value)} 
                      value={accountName}
                      size="sm"
                      type='select'
                    >
                    <option value="Mpesa">Mpesa</option>
                    <option value="Tigopesa">Tigopesa</option>
                    <option value="AirtelMoney">AirtelMoney</option>
                    <option value="Halopesa">Halopesa</option>
                    <option value="Tpesa">Tpesa</option>
                    <option value="NMB">NMB</option>
                    <option value="CRDB">CRDB</option>
                    <option value="NBC">NBC</option>
                    </Input>
                  </FormGroup>

                  <p style={{marginBottom:'25px',marginTop:'25px'}}><h5>Kin details</h5></p>

                    <Row className="mb-3">
                      <Col>
                        <FormGroup>
                        <Label>First name</Label>
                          <Input
                            placeholder="First Name"
                            autoComplete="given-name"
                            onChange={(e) => setrefFirstName(e.target.value)}
                            value={refFirstName}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Middle name</Label>
                          <Input
                            placeholder="Middle Name"
                            autoComplete="additional-name"
                            onChange={(e) => setrefMiddleName(e.target.value)}
                            value={refMiddleName}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Sir name</Label>
                          <Input
                            placeholder="Sir Name"
                            autoComplete="family-name"
                            onChange={(e) => setrefSirName(e.target.value)}
                            value={refSirName}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* Password, Email, Mobile No */}
                    <Row className="mb-3">
                      <Col>
                      <FormGroup>
                      <Label>Gender</Label>
                          <Input size="sm" type='select' onChange={(e) => setrefGender(e.target.value)} value={refgender}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Email</Label>
                          <Input
                            placeholder="Email"
                            autoComplete="email"
                            onChange={(e) => setrefEmail(e.target.value)}
                            value={refemail}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Contact</Label>
                          <Input
                            placeholder="Mobile No" 
                            onChange={(e) => setrefContact(e.target.value)}
                            value={refcontact}
                            size="sm" />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>RelationShip</Label>
                          <Input
                            placeholder="Relationship status"
                            autoComplete="given-name"
                            onChange={(e) => setrefrelationshipStatus(e.target.value)}
                            value={refrelationshipStatus}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <p style={{marginBottom:'25px',marginTop:'25px'}}><h5>Heir details</h5></p>

                    {/* Referee data */}
                    <Row className="mb-3">
                      <Col>
                        <FormGroup>
                        <Label>First name</Label>
                          <Input
                            placeholder="Heir Name"
                            autoComplete="given-name"
                            onChange={(e) => setheirFirstName(e.target.value)}
                            value={heirFirstName}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Middle name</Label>
                          <Input
                            placeholder="Middle Name"
                            autoComplete="additional-name"
                            onChange={(e) => setheirMiddleName(e.target.value)}
                            value={heirMiddleName}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Sir name</Label>
                          <Input
                            placeholder="Sir Name"
                            autoComplete="family-name"
                            onChange={(e) => setheirSirName(e.target.value)}
                            value={heirSirName}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* Password, Email, Mobile No */}
                    <Row className="mb-3">
                      <Col>
                      <FormGroup>
                      <Label>Gender</Label>
                          <Input size="sm" onChange={(e) => setheirGender(e.target.value)} value={heirgender} type='select'>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Email</Label>
                          <Input
                            placeholder="Email"
                            autoComplete="email"
                            onChange={(e) => setheirEmail(e.target.value)}
                            value={heiremail}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>Conatacts</Label>
                          <Input
                            placeholder="Mobile No" 
                            onChange={(e) => setheirContact(e.target.value)}
                            value={heircontact}
                            size="sm"
                            />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                        <Label>RelationShip</Label>
                          <Input
                            placeholder="Relationship status"
                            autoComplete="given-name"
                            onChange={(e) => setheirrelationshipStatus(e.target.value)}
                            value={heirrelationshipStatus}
                            size="sm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                  <Button color="success" onClick={handleEdit}>
                   Edit
                 </Button>
                </div>
                  </Form>
                </Widget>
        </div>
    );
  };

export default EditUser;
