
import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { useState,useEffect } from 'react';
import { collection,doc,addDoc,updateDoc,serverTimestamp,getDoc,onSnapshot } from 'firebase/firestore';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Typography, Table,Spin} from "antd";
import { useRef } from "react";
import html2canvas from 'html2canvas';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Widget from '../../components/Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';
import Swal from 'sweetalert2';
import ReactToPrint from 'react-to-print';

const SavingsFormPayout = ({ savingsId }) => {
  const [editedAmountPaid, setEditedAmountPaid] = useState(" ");
  const [savingsDetails, setSavingsDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { Title, Text } = Typography;

  const toggle = (e) => setModal(!modal);

  const componentRef = useRef(null);

  const receipt = (e) =>{
    e.preventDefault();
    setModal(!modal);
  }

  useEffect(() => {
    const fetchSavingsDetails = async () => {
      try {
        const { db } = fetchFirebaseConfig();
        const savingsRef = doc(db, 'Savings', savingsId);
        const savingsSnapshot = await getDoc(savingsRef);

        if (savingsSnapshot.exists()) {
          const savingsData = savingsSnapshot.data();
          setSavingsDetails(savingsData);

          const userRef = savingsData.userSavings;
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);

            setSavingsDetails((prevSavingsDetails) => ({
              ...prevSavingsDetails,
              user: userData.display_name,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userInterest: userData.interestRate,
              userid: userData.uid,
              userLoanlimit: userData.loanLimit
            }));

            onSnapshot(savingsRef, (updatedSavingsSnapshot) => {
              if (updatedSavingsSnapshot.exists()) {
                const updatedSavingsData = updatedSavingsSnapshot.data();
                setSavingsDetails(updatedSavingsData);
              }
            });
          } else {
            setUserDetails(null);
          }

          onSnapshot(savingsRef, (updatedSavingsSnapshot) => {
            if (updatedSavingsSnapshot.exists()) {
              const updatedSavingsData = updatedSavingsSnapshot.data();
              setSavingsDetails(updatedSavingsData);
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSavingsDetails();
  }, [savingsId]);


  const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const { db, storage } = fetchFirebaseConfig();
    const savingsRef = doc(db, 'Savings', savingsId);

    // Fetch the loan document to get the loanId field
    const savingsSnapshot = await getDoc(savingsRef);
    const savingsData = savingsSnapshot.data();
    const loanIdFieldValue = savingsData.savingsId;

    const updatedAmountPaid = parseInt(editedAmountPaid);
    const updatedTotalSavings = savingsData.totalSavings - updatedAmountPaid;
    const updatedLoanLimit = updatedTotalSavings * 3;

    // Use SweetAlert to confirm the action
    const result = await Swal.fire({
      title: 'Confirm',
      text: `Disburse a payout of ${updatedAmountPaid.toLocaleString()} to ${userDetails.display_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {

      setLoading(true);
      // Fetch the user's document
      const userRef = savingsData.userSavings;
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      // Await the updateDoc operation
      await updateDoc(savingsRef, {
        totalSavings: updatedTotalSavings,
      });

      // Update the loanLimit in the user's document
      await updateDoc(userRef, { loanLimit: updatedLoanLimit });

      // Fetch the updated user details after the updateDoc is complete
      const updatedUserSnapshot = await getDoc(userRef);
      const updatedUserData = updatedUserSnapshot.data();

      // Update the userDetails state with the updated user data
      setUserDetails(updatedUserData);

      // Fetch the updated loan details after the updateDoc is complete
      const updatedSavingsSnapshot = await getDoc(savingsRef);
      const updatedSavingsData = updatedSavingsSnapshot.data();

      // Update the loanStatus based on the updatedAmountRemaining
      const updatedSavingsStatus = updatedSavingsData.totalSavings !== 0 ? 'active' : 'Inactive';

      // Update the loan document with the new loanStatus
      await updateDoc(savingsRef, { status: updatedSavingsStatus });

      const element = document.getElementById('receipt');

      const canvas = await html2canvas(element, { scale: 2, useCORS: true });

      const imgData = canvas.toDataURL('image/png');

      const blob = await fetch(imgData).then((res) => res.blob());

      const storageRef = ref(storage, `Payslip/${savingsId}/receipt.png`);

      await uploadBytes(storageRef, blob);

      const downloadURL = await getDownloadURL(storageRef);

      toggle();

      const transactionData = {
        approvalStatus: 'Approved',
        Amount: updatedAmountPaid,
        PayoutDate: serverTimestamp(),
        transaction: 'Savings Payout',
        Recepient:userDetails?.display_name,
        voucher: downloadURL,
        userReference: savingsDetails.userSavings,
      };

      // Add the transaction data to the collection
      await addDoc(collection(db, 'PaymentData'), transactionData);

      setEditedAmountPaid(" ")
      // Show success message
      setLoading(false);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Payment Successful',
        text: 'The transaction has been successfully completed.',
        confirmButtonColor: '#28a745',
      });

    }
  } catch (error) {
    console.error(error);
  }
};


const columns = [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    align: 'center',
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: 'center',
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    align: 'center',
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
    align: 'center',
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: 'center',
  },
];

const dataSource = [
  {
    key: "1",
    index: 1,
    description: "Savings Payout",
    quantity: 1,
    unitPrice: `${numberWithCommas(editedAmountPaid)}`,
    amount: `${numberWithCommas(editedAmountPaid)}`,
    align: 'center',
  },
];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const randomNumber1 = Math.floor(Math.random() * 1000);
const randomNumber2 = Math.floor(Math.random() * 1000);
  

  return (
    <div>
      <h5 className="mb-lg">Submit Savings payouts</h5>
      <Row>
       <Col xs={6}>
          <Widget>
          <Form onSubmit={receipt}>
            <FormGroup>
                <Label for="PaidAmount">Amount</Label>
                <Input
                id="PaidAmount"
                name="loanLimit"
                placeholder="Payout Amount"
                type='number'
                required
                value={editedAmountPaid}
                onChange={(e) => setEditedAmountPaid(e.target.value)}
                />
            </FormGroup>
             <Button color='success' type='submit'>
              Submit
             </Button>
            </Form>
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle} style={{border:'none'}}>Receipt</ModalHeader>
                <ModalBody>
                      <Spin spinning={loading} size="large">
                        <div>
                        <div>
                        <div style={{ display: "flex" }}>
                        <ReactToPrint
                            trigger={() => (
                              <Button color="primary" outline size="sm" style={{ marginRight: "8px" }}><i class="fa fa-print" aria-hidden="true"></i>&nbsp;Print</Button>
                                )}
                                content={() => componentRef.current} // Specify the component to be printed
                              />
                          <Button color="primary" size="sm" outline style={{ marginRight: "8px" }}><i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;Export</Button>
                        </div>
                        <div style={{ padding: "24px" }} ref={componentRef} id="receipt">
                          <Widget>
                            <div style={{ marginBottom: "24px", display: "flex", alignItems: "center",}}>
                            <Title level={5} style={{ color: "#7e8d9f" }}>Receipt &gt; &gt; <strong>ID: #DSP {randomNumber1}-{randomNumber2}</strong></Title>
                            </div>
                            <hr />
                            <div style={{ marginBottom: "24px", textAlign: "center" }}>
                              <Typography style={{ marginBottom: "-35px"}}>
                                <Title level={4} style={{textAlign: 'center', color: "#7e8d9f"}}>DIGITAL SACCOS</Title>
                              </Typography>
                            <img src="DSP.png" alt="Description of the image" style={{ width: '120px', height: '120px' }}/>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
                              <div style={{ flex: 1}}>
                                <ul style={{ listStyle: "none", padding: 0 }}>
                                  <li>From:<Text strong style={{ color: "#5d9fc5" }}>&nbsp;{userDetails?.display_name}</Text></li>
                                  <li><i class="fa fa-map-marker" aria-hidden="true" style={{ color: "#84B0CA" }}></i>&nbsp;{userDetails?.address},Tanzania</li>
                                  <li><Text strong><i class="fa fa-phone" aria-hidden="true" style={{ color: "#84B0CA" }}></i>&nbsp;{userDetails?.phone_number}</Text></li>
                                </ul>
                              </div>
                              <div style={{ flex: 1,  display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <ul style={{ listStyle: "none", padding: 0 }}>
                                  <li><Text type="secondary"><strong>Reference-ID:</strong>{userDetails?.refNo}</Text></li>
                                  <li><Text type="secondary"><strong>Creation Date:</strong> {new Date().toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit'
                                  })}</Text></li>
                                  <li><Text type="secondary"><strong>Status:</strong> <span>Approved</span></Text></li>
                                </ul>
                              </div>
                            </div>
                            <div style={{ marginBottom: "24px" }}>
                              <Table dataSource={dataSource} columns={columns} pagination={false} bordered size='small'/>
                            </div>
                            <div style={{ marginBottom: "24px", display: "flex", justifyContent: "space-between" }}>
                              <p style={{ color: "black" }}>Total Amount<span style={{ fontSize: "25px", marginLeft: "8px", fontWeight:'bold', color:'#7a7977' }}>&nbsp;TSH&nbsp;{numberWithCommas(editedAmountPaid)}</span></p>
                            </div>
                            <hr />
                            <div style={{ marginBottom: "24px", display: "flex", justifyContent: "space-between" }}>
                              <p>company email</p>
                            </div>
                          </Widget>
                        </div>
                      </div> 
                    </div>
                  </Spin>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={handleSubmit}  disabled={loading}>
                    Submit
                  </Button>
                  <Button color="danger" style={{marginLeft:'15px'}} outline onClick={toggle} disabled={loading}>
                    Discard
                  </Button>
                </ModalFooter>
               </Modal>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default SavingsFormPayout;

