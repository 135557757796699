import React, { useState, useEffect,useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem,Form,Col,Row, ButtonGroup } from 'reactstrap';
import { Button, Space, Table, Spin } from 'antd'; // Imported Spin for loading indicator
import { collection, addDoc, getDocs, doc, deleteDoc,where,query } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';

const ChartsOfAcc = () => {
  const [tablesData, setTablesData] = useState({
    Assets: [],
    Liabilities: [],
    Equity: [],
    Income: [],
    Expenses: [],
  });

  const { db } = fetchFirebaseConfig();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [editName, setEditName] = useState('');
  const [newRow, setNewRow] = useState({
    Name: '',
    Type: '',
    Balance: '',
    Date: '',
    Status: '',
    Action: '',
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState('Assets');
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row data
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const toggleAddAccountModal = () => {
    setShowAddAccountModal(!showAddAccountModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleEditRow = (rowData) => {
    setSelectedRow(rowData); // Set selected row data
    toggleEditModal(); // Open edit modal
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const tables = ['Assets', 'Liabilities', 'Equity', 'Income', 'Expenses'];
      const data = {};

      for (const table of tables) {
        const collectionSnapshot = await getDocs(collection(db, table));
        data[table] = collectionSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      }

      console.log('Fetched data:', data);

      // Set an empty array if no data is available for a table
      for (const table of tables) {
        if (!data[table]) {
          data[table] = [];
        }
      }

      setTablesData(data);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false); // Set loading to false if there's an error
    }
  };

  useEffect(() => {
    fetchData();
  }, [db]);

  const handleAddRow = async () => {
    if (selectedTable && tablesData[selectedTable]) {
      try {
        const docRef = await addDoc(collection(db, selectedTable), {
          account_name: newRow.Name,
          account_type: newRow.Type,
          balance: parseFloat(newRow.Balance || 0), // Set to 0 if not provided
          date: newRow.Date,
          isNewRow: true,
        });

        const newDocId = docRef.id;

        console.log('Document written with ID: ', docRef.id);

        const collectionSnapshot = await getDocs(collection(db, selectedTable));
        const updatedData = collectionSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setTablesData((prevTablesData) => ({
          ...prevTablesData,
          [selectedTable]: updatedData,
        }));
      } catch (error) {
        console.error('Error adding document: ', error);
      }

      toggleAddAccountModal();
    }
  };

  const handleDeleteRow = async (tableType, docId) => {
    try {
      // Ensure docId is not empty or undefined
      if (!docId) {
        console.error('Document ID is empty or undefined.');
        return;
      } 
  
      // Delete the document from the selected Firebase collection
      await deleteDoc(doc(collection(db, tableType), docId));
  
      console.log('Document deleted with ID: ', docId);
  
      // Fetch the updated data from the database
      const collectionSnapshot = await getDocs(collection(db, tableType));
      const updatedData = collectionSnapshot.docs.map((doc) => doc.data());
  
      // Update local state with the fetched data
      setTablesData((prevTablesData) => ({
        ...prevTablesData,
        [tableType]: updatedData,
      }));
  
      alert('Your account has been deleted.');
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };
  

  const handleTableChange = (tableName) => {
    setSelectedTable(tableName);
  };

  const handleFilter = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const tables = ['Assets', 'Liabilities', 'Equity', 'Income', 'Expenses'];
      const data = {};

      for (const table of tables) {
      const collectionSnapshot = await getDocs(query(collection(db, table), 
        where('date', '>=', startDate),
        where('date', '<=', endDate)
      ));
      data[table] = collectionSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      }

      console.log('Fetched data:', data);

      // Set an empty array if no data is available for a table
      for (const table of tables) {
        if (!data[table]) {
          data[table] = [];
        }
      }

      setTablesData(data);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false); // Set loading to false if there's an error
    }
  };

  const handleRedo = () => {
    fetchData();
    setEndDate('');
    setStartDate('');
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      // ...getColumnSearchProps('account_name'),
      sorter: (a, b) => a.account_name.localeCompare(b.account_name),
    },
    {
      title: 'Account Type',
      dataIndex: 'account_type',
      key: 'account_type',
      align: 'center',
      // ...getColumnSearchProps('account_type'),
      sorter: (a, b) => a.account_type.localeCompare(b.account_type),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      align: 'center',
      render: (text) => formatNumber(text),
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space>
          <Click color='success' outline style={{ borderRadius: '10px' }} onClick={() => handleEditRow(record)}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </Click>
          <Click color='danger' outline style={{ borderRadius: '10px' }} onClick={() => handleDeleteRow(selectedTable, record.id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
  ];

  return (
   <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Account Charts</BreadcrumbItem>
      </Breadcrumb>
     <h5 className="mb-lg">Charts Of Accounts</h5>
     <Widget
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <Input
                        id="exampleFirstName"
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <Input
                        id="endDate"
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Click type='submit' size='sm' color='success' onClick={handleFilter}>Search</Click>
                  <div style={{ marginLeft: '10px' }}></div>
                  <Click size='sm' color='success' onClick={handleRedo}>Reset</Click>
                </div>
           </Widget>
     <Widget>
      <div className="mb-3" style={{ display: 'flex', justifyContent: 'flex-start' }}>
       <Click color='success' size='sm' onClick={toggleAddAccountModal}>Add Account</Click> {/* Button to trigger Add Account Modal */}
      </div>
        <div className="mb-3" style={{ display: 'flex', justifyContent: 'flex-start' }}>    
        <ButtonGroup className="mb-3" style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {Object.keys(tablesData).map((tableName) => (
            <Click
              key={tableName}
              color='primary'
              size='sm'
              onClick={() => handleTableChange(tableName)}
              style={{ marginRight: '2px' }}
            >
              {tableName}
            </Click>
          ))}
        </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={tablesData[selectedTable]}  loading={loading} size="small" />
        </div>
        {/* Add Account Modal */}
        <Modal isOpen={showAddAccountModal} toggle={toggleAddAccountModal}>
          <ModalHeader toggle={toggleAddAccountModal} style={{border:'none',marginLeft:'15px'}}>Add Account</ModalHeader>
          <ModalBody>
          <Widget>
          <Form className='mb-4'>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" id="name" value={newRow.Name} onChange={(e) => setNewRow({ ...newRow, Name: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="name">Category</Label>
              <Input type="select" id="name" 
                value={selectedTable}
                onChange={(e) => setSelectedTable(e.target.value)}>
                <option value="" disabled>Select a table</option>
                  {Object.keys(tablesData).map((tableType) => (
                    <option key={tableType} value={tableType}>{tableType}</option>
                  ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input type="select" id="type" value={newRow.Type} onChange={(e) => setNewRow({ ...newRow, Type: e.target.value })} >
              <option value="" disabled>Select an account type</option>
                  {selectedTable === 'Liabilities' && (
                    <>
                      <option value="current-liabilities">Current Liabilities</option>
                      <option value="long-term-liabilities">Long Term Liabilities</option>
                      <option value="share-capital">Share Capital</option>
                      <option value="retained-earnings">Retained Earnings</option>
                    </>
                  )}
                  {selectedTable === 'Equity' && (
                    <option value="owners-equity">Owners Equity</option>
                  )}
                  {selectedTable === 'Income' && (
                    <>
                      <option value="sales-revenue">Sales Revenue</option>
                      <option value="other-revenue">Other Revenue</option>
                    </>
                  )}
                  {selectedTable === 'Expenses' && (
                    <>
                      <option value="payroll-expenses">Payroll Expenses</option>
                      <option value="general-and-admin-expenses">General and Administrative Expenses</option>
                    </>
                  )}
                  
                  {selectedTable === 'Assets' && (
                    <>
                      <option value="current-assets">Current Assets</option>
                      <option value="non-current-assets">Non-current Assets</option>
                      <option value="fixed-assets">Fixed Assets</option>
                      <option value="inventory">Inventory</option>
                    </>
                  )}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="balance">Starting Balance</Label>
              <Input type="number" id="balance" value={newRow.Balance} onChange={(e) => setNewRow({ ...newRow, Balance: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="date">Date</Label>
              <Input type="date" id="date" value={newRow.Date} onChange={(e) => setNewRow({ ...newRow, Date: e.target.value })} />
            </FormGroup>       
              <div style={{ display: 'flex', justifyContent: 'flex-start',marginTop:'12px'}}>
              <Click color="success" onClick={handleAddRow}>Add</Click>{' '}
              <Click color="danger" onClick={toggleAddAccountModal} style={{ marginLeft: '10px' }}>Cancel</Click>
              </div>
            </Form>
            </Widget> 
          </ModalBody> 
        </Modal>
        {/* Edit Account Modal */}
        <Modal isOpen={showEditModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit Account</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="editName">Name</Label>
              <Input type="text" id="editName" value={editName} onChange={(e) => setEditName(e.target.value)} />
            </FormGroup>
            {/* Add more form components to edit other fields */}
          </ModalBody>
          <ModalFooter>
            <Click color="success" onClick={handleEditRow}>Save</Click>{' '}
            <Click color="danger" onClick={toggleEditModal}>Cancel</Click>
          </ModalFooter>
        </Modal>
      </Widget>
  </div>
  );
};

export default ChartsOfAcc;
