import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  // apiKey: "AIzaSyAdqUKukCLOp9t7poG8DU94nZp7gmZFWj8",
  // authDomain: "digitalsaccos.firebaseapp.com",
  // databaseURL: "https://digitalsaccos-default-rtdb.firebaseio.com",
  // projectId: "digitalsaccos",
  // storageBucket: "digitalsaccos.appspot.com",
  // messagingSenderId: "826317847172",
  // appId: "1:826317847172:web:f1f89c100f3e8bbc0e20d1"

  apiKey: "AIzaSyCzi-2MdwX6RS3RYFdHvpGjM5gGIADTbGk",
  authDomain: "lti-sharestracker.firebaseapp.com",
  projectId: "lti-sharestracker",
  storageBucket: "lti-sharestracker.appspot.com",
  messagingSenderId: "411464812719",
  appId: "1:411464812719:web:da5d88f68373b82b38ac5a",
  measurementId: "G-034505Z6S3"
};

export const fetchFirebaseConfig2 = () => {
  // Initialize Firebase
  const app2 = firebase.initializeApp(firebaseConfig,"signupuser");
  //const app = !firebase.getApps().length ? initializeApp(firebaseConfig,"Secondary") : firebase.getApp();
  //const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app2);
  const auth2 = getAuth(app2);
  const db = getFirestore(app2);
  const storage = getStorage(app2);

  return { app2, auth2, db, storage,functions };
};

export default fetchFirebaseConfig2;
