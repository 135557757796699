import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Breadcrumb, BreadcrumbItem, Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';

const SharesRecords = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState('select category');  // State for category input
  const [startingAmount, setStartingAmount] = useState('');     // State for starting amount input
  const [endingAmount, setEndingAmount] = useState(''); 
  const [showSummaryTable, setShowSummaryTable] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [expandStatusRow, setExpandStatusRow] = useState(false);

  const componentRef = useRef(null);

  const fetchSavingsAccounts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(collection(db, 'Savings'));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.userSavings);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          member: userData ? userData.display_name : '',
          loanLimit: userData ? userData.loanLimit : '',
        };
      }));

      // Sort the data based on the PayoutDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.created_at.seconds - a.created_at.seconds);

      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

    useEffect(() => {
    fetchSavingsAccounts();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };


  const renderAccountStatus = (status) => {
    return status === "active" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Active</span>
      </Badge>
    ) : status === "inactive" ? (
      <Badge color="danger" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Inactive</span>
      </Badge>
    ) : (
      <Badge color="primary" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>processing</span>
      </Badge>
    );
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      let queryRef = collection(db, 'Savings');
  
      // Apply date filter
      if (startDate && endDate) {
        queryRef = query(
          queryRef,
          where('created_at', '>=', new Date(startDate)),
          where('created_at', '<=', new Date(endDate))
        );
      }
  
      const querySnapshot = await getDocs(queryRef);
      const fetchedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        // Fetch the 'loanLimit' field along with other fields
        loanLimit: doc.data().loanLimit, // Adjust the path to match your data structure
      }));
  
      // Fetch user data for each document
      const updatedData = await Promise.all(
        fetchedData.map(async item => {
          const userDoc = await getDoc(item.userSavings);
          const userData = userDoc.exists() ? userDoc.data() : null;
          return {
            ...item,
            id: item.id,
            member: userData ? userData.display_name : '',
            loanLimit: userData ? userData.loanLimit : '',
          };
        })
      );
  
      // Apply category filter if startingAmount and endingAmount are not empty for the selected category
      if (
        (category === 'Available Savings' && startingAmount !== '' && endingAmount !== '') ||
        (category === 'Loan Limit' && startingAmount?.trim() !== '' && endingAmount?.trim() !== '')
      ) {
        const filteredData = updatedData.filter(item => {
          if (category === 'Available Savings') {
            return item.totalSavings >= parseInt(startingAmount) && item.totalSavings <= parseInt(endingAmount);
          } else if (category === 'Loan Limit') {
            return item.loanLimit >= parseInt(startingAmount) && item.loanLimit <= parseInt(endingAmount);
          }
          return true;
        });
  
        // Calculate counts for each column
        const summaryData = columns.map(column => {
          if (column.dataIndex === 'status') {
            const activeCount = filteredData.filter(item => item.status === 'active').length;
            const inactiveCount = filteredData.filter(item => item.status === 'inactive').length;
            return {
              title: column.title,
              count: { active: activeCount, inactive: inactiveCount }, // Separate counts for active and inactive
            };
          } else {
            return {
              title: column.title,
              count: filteredData.reduce((acc, curr) => {
                if (curr[column.dataIndex]) {
                  acc++;
                }
                return acc;
              }, 0),
            };
          }
        });
  
        // Set summary table data
        setSummaryData(summaryData);
  
        setData(filteredData);
      } else {
        // If no category filter is selected or no input is provided, set the data to the updated data
  
        // Calculate counts for each column
        const summaryData = columns.map(column => {
          if (column.dataIndex === 'status') {
            const activeCount = updatedData.filter(item => item.status === 'active').length;
            const inactiveCount = updatedData.filter(item => item.status === 'inactive').length;
            return {
              title: column.title,
              count: { active: activeCount, inactive: inactiveCount }, // Separate counts for active and inactive
            };
          } else {
            return {
              title: column.title,
              count: updatedData.reduce((acc, curr) => {
                if (curr[column.dataIndex]) {
                  acc++;
                }
                return acc;
              }, 0),
            };
          }
        });
  
        // Set summary table data
        setSummaryData(summaryData);
  
        setData(updatedData);
      }
  
      setShowSummaryTable(true);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
     

  const handleRedo = () => {
    fetchSavingsAccounts();
    setEndDate('');
    setStartDate('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      ...getColumnSearchProps('member'),
      sorter: (a, b) => a.member - b.member,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Available Shares',
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      ...getColumnSearchProps('shares'),
      sorter: (a, b) => a.shares - b.shares,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Shares Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      ...getColumnSearchProps('Amount'),
      sorter: (a, b) => a.Amount - b.Amount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Account Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      ...getColumnSearchProps('status'),
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render:renderAccountStatus,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      sorter: (a, b) => a.created_at - b.created_at,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];


  const summaryColumns = [
    {
      title: 'Data Instances',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => {
        if (record.title === 'Account Status') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{title}</span>
              <div onClick={() => setExpandStatusRow(!expandStatusRow)} style={{ marginLeft: '10px' }}>
                {/* Add a tiny trigger button */}
                <Click size="sm" color='success' outline style={{borderRadius:'5px'}}>
                  {expandStatusRow ? <i class="fa fa-minus-square-o" aria-hidden="true"></i> : <i class="fa fa-plus-square-o" aria-hidden="true"></i>}
                </Click>
              </div>
            </div>
          );
        } else {
          return <div>{title}</div>;
        }
      },
    },
    {
      title: 'Count Of Instances',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      render: (count, record) => {
        if (record.title === 'Account Status') {
          // Calculate total count of active and inactive statuses
          const total = count.active + count.inactive;
          return (
            <div>
              {/* Display total count beside the collapse button */}
              {!expandStatusRow && (
                <span style={{ marginLeft: '5px' }}>{total}</span>
              )}
              {/* Render counts if expanded */}
              {expandStatusRow && (
                <div>
                  <p>Active: {count.active}</p>
                  <p>Inactive: {count.inactive}</p>
                </div>
              )}
            </div>
          );
        } else {
          return <div>{count}</div>;
        }
      },
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Shares Records</BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mb-lg">Saccos Shares Accounts Records</h5>
      <Widget
        title={<h7><span className="fw-semi-bold">Set Filtering parameters</span></h7>}
      >
        <Row style={{ marginTop: '10px' }}>
          <Col md={6}>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <InputData
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                type="date"
                bsSize="sm"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <InputData
                id="endDate"
                name="endDate"
                placeholder="End date"
                type="date"
                bsSize="sm"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>

        {/* Row for Starting Amount and Ending Amount */}
        <Row style={{ marginTop: '2px' }}>
        <Col md={4}>
            <FormGroup>
              <Label for="category">Category</Label>
              <InputData
                id="category"
                name="category"
                placeholder="Category"
                type="select"
                bsSize="sm"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="select category">Select Category</option>
                <option value="Available Shares">Available Shares</option>
                <option value="Shares Amount">Shares Amount</option>
              </InputData>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="startingAmount">Starting Amount</Label>
              <InputData
                id="startingAmount"
                name="startingAmount"
                placeholder="Starting Amount"
                type="number"
                bsSize="sm"
                value={startingAmount}
                onChange={(e) => setStartingAmount(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="endingAmount">Ending Amount</Label>
              <InputData
                id="endingAmount"
                name="endingAmount"
                placeholder="Ending Amount"
                type="number"
                bsSize="sm"
                value={endingAmount}
                onChange={(e) => setEndingAmount(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Click type='button' size='sm' color='success' onClick={handleFilter}>Filter</Click>
          <div style={{ marginLeft: '10px' }}></div>
          <Click type='button' size='sm' color='success' onClick={handleRedo}>Reset</Click>
        </div>
      </Widget>
      <Widget>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm">
              CSV
            </Click>
            <Click color="success" size="sm">
              PDF
            </Click>
            <ReactToPrint
              trigger={() => (
                <Click color="success" disabled={printing} size="sm">
                  {printing ? <Spinner size="sm" style={{ color: 'white' }} /> : <i className="fa fa-print" aria-hidden="true"></i>}
                </Click>
              )}
              content={() => componentRef.current} // Specify the component to be printed
              onBeforeGetContent={() => setPrinting(true)}
              onAfterPrint={() => setPrinting(false)}
            />
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data}  loading={loadingData} size="small" />
        </div>
        {showSummaryTable && (
          <Widget
          title={<h7><span className="fw-semi-bold">Summary of the Filtered Data</span></h7>}
          >
            {/* Summary table JSX */}
            <div style={{ overflowX: 'auto', overflowY: 'auto' }} className='mt-3'>
            <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm">
              Export
            </Click>
          </ButtonGroup>
          </div>
            <Table columns={summaryColumns} dataSource={summaryData} bordered size="small" />
            </div>
          </Widget>
        )}
      </Widget>
    </div>
  );
};

export default SharesRecords;