import React, { useRef, useState,useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem, Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData,Form} from 'reactstrap';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Button as Click, ButtonGroup,Modal, ModalHeader, ModalBody, ModalFooter,Collapse} from 'reactstrap';
import { collection, setDoc,addDoc,doc,query,getDocs,updateDoc,serverTimestamp,getDoc} from "firebase/firestore";
import {fetchFirebaseConfig} from '../../firebase';
import Widget from '../../components/Widget/Widget';
import { nanoid } from 'nanoid';

const LoanCategories = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalCategory, setModalCategory] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // State to track checkbox status
  const [selectedSector, setSelectedSector] = useState("");
  const [subSectorOptions, setSubSectorOptions] = useState([]);
  const [loanInterest, setLoanInterest] = useState("");
  const [loanType, setLoanType] = useState("");
  const [loanSector, setLoanSector] = useState("");
  const [loanSubSector, setLoanSubSector] = useState("");
  const [selectedSector1, setSelectedSector1] = useState("");
  const [subSectorOptions1, setSubSectorOptions1] = useState([]);
  const [loanInterest1, setLoanInterest1] = useState("");
  const [loanType1, setLoanType1] = useState("");
  const [loanSector1, setLoanSector1] = useState("");
  const [loanSubSector1, setLoanSubSector1] = useState("");
  const [loanPeriod, setLoanPeriod] = useState("");
  const [loanDuration, setLoanDuration] = useState("");
  const [loanPeriod1, setLoanPeriod1] = useState("");
  const [loanDuration1, setLoanDuration1] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const searchInput = useRef(null);
  

  const toggle = () => setModalCategory(!modalCategory);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const fetchLoanCategories = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansTypeCollectionRef = collection(db, 'LoansType');
      const snapshot = await getDocs(loansTypeCollectionRef);
  
      const categories = [];
      let count = 1;
  
      for (const docRef of snapshot.docs) {
        const loanTypeData = docRef.data();
        const loanTypeId = docRef.id;
  
        // Fetch data from LoanTypeCategory subcollection
        const loanTypeCategoryRef = doc(db, 'LoansType', loanTypeId, 'LoanTypeCategory', loanTypeId);
        const loanTypeCategorySnapshot = await getDoc(loanTypeCategoryRef);
        const loanTypeCategoryData = loanTypeCategorySnapshot.exists() ? loanTypeCategorySnapshot.data() : {};
  
        // Fetch data from LoanSector collection
        const loanSectorRef = doc(db, 'LoanSector', loanTypeId);
        const loanSectorSnapshot = await getDoc(loanSectorRef);
        const loanSectorData = loanSectorSnapshot.exists() ? loanSectorSnapshot.data() : {};
  
        // Fetch data from LoanSubSector subcollection
        const loanSubSectorRef = doc(db, 'LoanSector', loanTypeId, 'LoanSubSector', loanTypeId);
        const loanSubSectorSnapshot = await getDoc(loanSubSectorRef);
        const loanSubSectorData = loanSubSectorSnapshot.exists() ? loanSubSectorSnapshot.data() : {};
  
        categories.push({
          key: count,
          id: count,
          name: loanTypeData?.name || "",
          sector: loanSectorData?.name || "",
          subSector: loanSubSectorData?.name || "",
          interest: loanTypeCategoryData?.interest || "",
          term: loanTypeCategoryData?.Term || "",
          duration: loanTypeCategoryData?.PeriodInWeeks || "",
        });
        count++;
      }
  
      setData(categories);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching loan categories:', error);
      setError(error.message);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchLoanCategories();
  }, []);


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      sorter: (a, b) => a.sector.localeCompare(b.sector),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Sub-Sector',
      dataIndex: 'subSector',
      key: 'subSector',
      sorter: (a, b) => a.subSector.localeCompare(b.subSector),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      sorter: (a, b) => a.interest - b.interest,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Term',
      dataIndex: 'term',
      key: 'term',
      sorter: (a, b) => a.term.localeCompare(b.term),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: () => (
        <Space>
        <Click  color='success' outline style={{borderRadius:'10px'}}>
        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </Click>
        <Click  color='danger' outline style={{borderRadius:'10px'}}>
        <i className="fa fa-trash" aria-hidden="true"></i>
        </Click>
      </Space>
      ),
    },
  ];



  const handleSectorChange = (event) => {
    const sector = event.target.value;
    setSelectedSector(sector);

    // Set sub-sector options based on the selected sector
    if (sector === "Manufacturing") {
      setSubSectorOptions([
        "select sub-sector",
        "Food processing",
        "Animal Feed processing",
        "Carpentry",
        "Textile and Leather products",
        "Others",
      ]);
    } else if (sector === "Services") {
      setSubSectorOptions([
        "select sub-sector",
        "Education & health",
        "Financial & Bussiness",
        "Transport & Communication",
        "Housing & Finance",
        "Micro Insurance",
        "Micro Leasing",
        "Others",
      ]);
    } else if (sector === "Trade and Commerce") {
      setSubSectorOptions([
        "select sub-sector",
        "Wholetail & Retail Stores",
        "Medical Store",
        "Hotel & Restaurants",
        "Electricity & Gas",
        "Others",
      ]);
    } else if (sector === "Agriculture") {
      setSubSectorOptions([
        "select sub-sector",
        "Crop production",
        "Animal production",
        "Forestry",
        "Fishing",
        "Hunting",
        "Others",
      ]);
    } else if (sector === "Personal Loans") {
      setSubSectorOptions([
        "select sub-sector",
        "Consumer",
        "Others",
      ]);
    } else {
      // Reset sub-sector options for other sectors
      setSubSectorOptions([]);
    }

    // Update the loanSector state based on the selected sector
    setLoanSector(sector);
    };

      // Event handler for period change
        const handlePeriodChange = (event) => {
        const period = event.target.value;
        setLoanPeriod(period);
        };
          
        // Event handler for duration change
        const handleDurationChange = (event) => {
          const duration = event.target.value;
        setLoanDuration(duration);
        };
    
        // Event handler for period change
      const handlePeriodCustom = (event) => {
      const period1 = event.target.value;
      setLoanPeriod1(period1);
      };
          
      // Event handler for duration change
      const handleDurationCustom = (event) => {
      const duration1 = event.target.value;
      setLoanDuration1(duration1);
      };
  
        const {db}=fetchFirebaseConfig();    
        const loanTypeCollectionRef = collection(db, 'LoansType');
        const loanSectorCollectionRef = collection(db, 'LoanSector');
        const loanTypeDocId = "xElg8zL4QNVGxVksF6aP";
  
     
        const handleAddCategory = async () => {
          // Check if any of the required fields are empty
          if (!loanType || !loanInterest || !loanSector || !loanSubSector || !loanDuration || !loanPeriod) {
            console.error("Please fill in all required fields.");
            return; // Exit the function if any field is empty
          }
        
          try {
            const randomId1 = nanoid(20);
            const randomId3 = nanoid(20);
        
            // Create a document in the LoanSubSector subcollection directly
            const loanTypeData = {
              name: loanType,
              created_At: serverTimestamp()
            };
            const loanTypeDocRef = await addDoc(loanTypeCollectionRef, loanTypeData);
        
            // Create a document in the LoanTypeCategory subcollection
            const loanTypeCategoryData = {
              LoanTypeName: loanType,
              PeriodInWeeks: parseFloat(loanDuration),
              Term: loanPeriod,
              interest: parseFloat(loanInterest),
              LoanTypeRef: loanTypeDocRef,
              created_At: serverTimestamp()
            };
            await setDoc(doc(loanTypeDocRef, 'LoanTypeCategory', randomId1), loanTypeCategoryData);
        
            // Create a document in the LoanSector collection
            const loanSectorData = {
              LoanTypeName: loanType,
              LoanTypeRef: loanTypeDocRef,
              name: loanSector,
              created_At: serverTimestamp(),
            };
            const loanSectorDocRef = await addDoc(loanSectorCollectionRef, loanSectorData);
        
            // Create a document in the LoanSubSector subcollection
            const loanSubSectorData = {
              LoanSectorRef: loanSectorDocRef,
              sectorName: loanSector,
              name: loanSubSector,
              created_At: serverTimestamp()
            };
            await setDoc(doc(loanSectorDocRef, 'LoanSubSector', randomId3), loanSubSectorData);
        
            console.log('Loan created successfully');

            setLoanType("");
            setLoanSector("")
            setLoanSubSector("")
            setLoanPeriod('')
            setLoanDuration('')
            toggle();

          } catch (error) {
            console.error('Error creating loan: ', error);
          }
        };
        

      const handleAddCustomizedCategory = async () => {
        try {
          const randomId1 = nanoid(20);
          const randomId3 = nanoid(20);
      
          // Get reference to the loan type document with ID "xElg8zL4QNVGxVksF6aP"
          const loanTypeDocRef = doc(db, 'LoansType', loanTypeDocId);
      
          // Create a document in the LoanTypeCategory subcollection of the specified loan type document
          const loanTypeCategoryData = {
            LoanTypeName: 'Personal Loan',
            PeriodInWeeks: parseFloat(loanDuration1),
            Term: loanPeriod1,
            interest: parseFloat(loanInterest1),
            LoanTypeRef: loanTypeDocRef,
          };
          await setDoc(doc(loanTypeDocRef, 'LoanTypeCategory', randomId1), loanTypeCategoryData);
      
          // Create a document in the LoanSector collection
          const loanSectorData = {
            LoanTypeName: 'Personal Loan',
            LoanTypeRef: loanTypeDocRef,
            name: loanSector1,
          };
          const loanSectorDocRef = await addDoc(loanSectorCollectionRef, loanSectorData);
      
          // Create a document in the LoanSubSector subcollection directly
          const loanSubSectorData = {
            LoanSectorRef: loanSectorDocRef,
            sectorName: loanSector1,
            name: loanSubSector1,
          };
          await setDoc(doc(loanSectorDocRef, 'LoanSubSector', randomId3), loanSubSectorData);
  
          // toast.success('Loan created successfully!');
           
          setTimeout(() => {
            // navigate('/loan-categories');
          }, 4000); 
      
          console.log('Loan created successfully');
        } catch (error) {
          // toast.error('Error while creating Loan!');
          console.error('Error creating loan: ', error);
        }
      };
  

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Loan-categories</BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mb-lg">List of Loan Categories</h5>
      <Widget>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Click color="success" size="sm">
              <i className="fa fa-refresh" aria-hidden="true"></i> Refresh
            </Click>
            <Click color="success" size="sm" onClick={toggle}>
              <i className="fa fa-plus" aria-hidden="true"></i> Add category
            </Click>
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={data} bordered loading={loading} size="small" />
        </div>
        <Modal isOpen={modalCategory} toggle={toggle}>
          <ModalHeader toggle={toggle} style={{border:'none',marginLeft:'15px'}}>Add new Loan Category</ModalHeader>
          <ModalBody>   
          <Widget>     
            <Form className='mb-4'>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanType">Loan Type<span style={{ color: 'red' }}>*</span></Label>
                    <InputData bsSize="sm" type="select" name="loanType" id="Loan-type" onChange={(e) => setLoanType(e.target.value)} value={loanType} required>
                        <option value=" ">Select Loan Type</option>
                       <option value="Personal Loan">Personal Loan</option>
                        <option value="Bussiness Group Loan">Bussiness Group Loan</option>
                        <option value="Small Group Loans">Small Group Loans</option>
                        <option value="Bussiness Individual Loans">Bussiness Individual Loans</option>
                        <option value="Agriculture Loans">Agriculture Loans</option>
                        <option value="Housing Microfinance Loans">Housing Microfinance Loans</option>
                        <option value="Hire purchase Loans">Hire purchase Loans</option>
                        <option value="Loans to Other Microfinanance Providers">Loans to Other Microfinanance Providers</option>
                        <option value="Micro Insurance Loans">Micro Insurance Loans</option>
                        <option value="Education Loans">Education Loans</option>
                        <option value="Salaried Loans">Salaried Loans</option>
                        <option value="Government Employees">Government Employees</option>
                        <option value="Non-Government Employees">Non-Government Employees</option>
                        <option value="Emergence Loans">Emergence Loans</option>
                        <option value="Other Micro Loans">Other Micro Loans</option>
                    </InputData>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanSector">Loan Sector<span style={{ color: 'red' }}>*</span></Label>
                    <InputData bsSize="sm" type="select" name="loanSector" id="loan-sector" onChange={handleSectorChange} value={selectedSector} required> 
                        <option value=" ">Select Loan Sector</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Services">Services</option>
                        <option value="Trade and Commerce">Trade and Commerce</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Personal Loans">Personal Loans</option>
                     </InputData>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanSubSector">Loan Sub Sector<span style={{ color: 'red' }}>*</span></Label>
                    <InputData bsSize="sm" type="select" name="loanSubSector" id="loan-sub-sector" onChange={(e) => setLoanSubSector(e.target.value)} value={loanSubSector} required>
                        {subSectorOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                        ))}
                     </InputData>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="duration">Duration<span style={{ color: 'red' }}>*</span></Label>
                    <InputData bsSize="sm" type="number" name="duration" id="duration" placeholder="duration in weeks" onChange={handleDurationChange} value={loanDuration} required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="period">Period<span style={{ color: 'red' }}>*</span></Label>
                    <InputData id="examplePeriod" name="period"  type="select" bsSize="sm" onChange={handlePeriodChange} value={loanPeriod}  required>
                      <option value=" ">Select Period</option>
                      <option value="Short Term">Short-Term</option>
                      <option value="Mid Term">Mid-Term</option>
                      <option value="Long Term">Long-Term</option>
                    </InputData>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="interest">Interest<span style={{ color: 'red' }}>*</span></Label>
                    <InputData bsSize="sm" type="number" name="interest" id="interest" onChange={(e) => setLoanInterest(e.target.value)} value={loanInterest} required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start',marginTop:'12px'}}>
                    <Click color="success"  size="sm" onClick={handleAddCategory}  disabled={!loanType || !loanInterest || !loanSector || !loanSubSector || !loanDuration || !loanPeriod}>
                      Create
                    </Click>
                    <Click color="danger" onClick={toggle} size="sm" style={{ marginLeft: '10px' }}>
                      Cancel
                    </Click>
                  </div>
                </Col>
                </Row>
              </Form>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
              <FormGroup check>
                <InputData
                  id="checkbox2"
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  style={{ backgroundColor: isChecked ? '#28d456' : 'inherit', borderColor:'#ECECEC' }} // Apply color directly here
                />
                {' '}
                <Label check>Create customized Loan</Label>
              </FormGroup>
              </div>
              <Collapse isOpen={isChecked} className='mt-2'>
                <Form onSubmit={handleAddCustomizedCategory}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="loanType">Loan Type<span style={{ color: 'red' }}>*</span></Label>
                        <InputData bsSize="sm" type="text" name="loanType" id="Loan-type" onChange={(e) => setLoanType1(e.target.value)} vlaue={loanType1} required/>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="loanSector">Loan Sector<span style={{ color: 'red' }}>*</span></Label>
                        <InputData bsSize="sm" type="text" name="loanSector" id="loan-sector" onChange={(e) => setLoanSector1(e.target.value)} value={loanSector1} required/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="loanSubSector">Loan Sub Sector<span style={{ color: 'red' }}>*</span></Label>
                        <InputData bsSize="sm" type="text" name="loanSubSector" id="loan-sub-sector"    onChange={(e) => setLoanSubSector1(e.target.value)}   value={loanSubSector1} required />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="duration">Duration<span style={{ color: 'red' }} required>*</span></Label>
                        <InputData bsSize="sm" type="number" name="duration" id="duration" placeholder="duration in weeks"  onChange={handleDurationCustom}  value={loanDuration1} required/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="period">Period<span style={{ color: 'red' }} required>*</span></Label>
                        <InputData id="examplePeriod" name="period" type="select" size="sm"  onChange={handlePeriodCustom} value={loanPeriod1} required>
                          <option value=" ">Select Period</option>
                          <option value="Male">Short-Term</option>
                          <option value="Female">Mid-Term</option>
                          <option value="Female">Long-Term</option>
                        </InputData>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="interest">Interest<span style={{ color: 'red' }}>*</span></Label>
                        <InputData bsSize="sm" type="number" name="interest" id="interest" required/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col md={12}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start',marginTop:'12px'}}>
                      <Click color="success" type="submit"  size="sm">
                        Create
                      </Click>
                      <Click color="danger" onClick={toggle} size="sm" style={{ marginLeft: '10px' }}>
                        Cancel
                      </Click>
                    </div>
                  </Col>
                </Row>
                 </Form>
              </Collapse>
            </Widget>
          </ModalBody>
        </Modal>
      </Widget>
    </div>
  );
};

export default LoanCategories;
