import React,{useState,useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import PropTypes from 'prop-types';
import {fetchFirebaseConfig} from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const Register = ({onCompanyAdd}) => {
  const { db, storage } = fetchFirebaseConfig();

  const [ceoName, setCeoName] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [companyStaff, setCompanyStaff] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyMobile, setCompanyMobile] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyShares, setCompanyShares] = useState(0);
  const [companyAddress, setCompanyAddress] = useState("");
  const [amountPerShares, setAmountPerShares] = useState(0);


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setCompanyLogo(file);
  };

  const handleAddCompany = async () => {
    try {
      // Upload image to Firebase Storage
      const storageRef = ref(storage, `CompanyLogo/${companyLogo.name}`);
      await uploadBytes(storageRef, companyLogo);

      // Get download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageRef);

      // Add branch document to Firestore
      const CompanyData = collection(db, 'Companies');
      await addDoc(CompanyData, {
        company_id: companyCode,
        CompanyName: companyName,
        CompanyLogo: downloadURL, // Store download URL in Firestore document
        CompanyEmail:companyEmail,
        CompanyMobile:companyMobile,
        CompanyAddress:companyAddress,
        CompanyCeo:ceoName,
        CompanyJoinedDate:serverTimestamp(),
        CompanyStaff:companyStaff,
        CompanySharesAvailable:parseInt(companyShares),
        AmountPerShares:parseInt(amountPerShares),
        TotalShares:parseInt(companyShares)
      });

      // Clear input fields
      setCeoName('');
      setCompanyCode('');
      setCompanyAddress('');
      setCompanyEmail('');
      setCeoName('');
      setCompanyMobile('');
      setCompanyName('');
      setCompanyStaff('');
      setCompanyLogo(null);
      setAmountPerShares('');
      setCompanyShares('')

      // Call onBranchAdd callback if provided
      if (typeof onCompanyAdd === 'function') {
        onCompanyAdd();
      }
    } catch (error) {
      console.error('Error adding branch:', error);
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Register Company</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg"><i class="fa fa-plus" aria-hidden="true" style={{fontSize:'2rem'}}></i> Create a new company</h4>
      <Row>
       <Col xs={8}>
          <Widget>
          <Form>
              <FormGroup>
                  <Label for="CompanyName"> Company name</Label>
                  <Input
                  type='text'
                  id="CompanyName"
                  name="CompanyName"
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  />
              </FormGroup>
                <FormGroup>
                    <Label for="CompanyCode">code</Label>
                    <Input
                    type='text'
                    id="CompanyCode"
                    name="CompanyCode"
                    placeholder="Company code"
                    value={companyCode}
                    onChange={(e) => setCompanyCode(e.target.value)}
                    />
                </FormGroup>
               <FormGroup>
                <Label for="CompanyEmail">email</Label>
                <Input
                type='email'
                id="CompanyEmail"
                name="CompanyEmail"
                placeholder="Company email"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
                />
                </FormGroup>
               <FormGroup>
                <Label for="CompanyName">mobile</Label>
                <Input
                type='number'
                id="CompanyMobile"
                name="CompanyMobile"
                placeholder="Company mobile"
                value={companyMobile}
                onChange={(e) => setCompanyMobile(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="CeoName">CEO name</Label>
                <Input
                type='text'
                id="CeoName"
                name="CeoName"
                placeholder="Ceo Name"
                value={ceoName}
                onChange={(e) => setCeoName(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="CompanyName">Address</Label>
                <Input
                type='text'
                id="CompanyAddress"
                name="CompanyAddress"
                placeholder="Company Address"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanCommitee">Total Staff</Label>
                <Input
                id="Shares Amount"
                name="Shares Amount"
                placeholder="Shares Amount"
                type='number'
                value={companyStaff}
                onChange={(e) => setCompanyStaff(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
            <Label for="TotalShares">Total Shares</Label>
                <Input
                type='number'
                id="TotalShares"
                name="TotalShares"
                placeholder="Total Shares"
                value={companyShares}
                onChange={(e) => setCompanyShares(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
            <Label for="AmountPerShare">Amount/Share</Label>
                <Input
                type='number'
                id="AmountPerShare"
                name="AmountPerShare"
                placeholder="Amount per Share"
                value={amountPerShares}
                onChange={(e) => setAmountPerShares(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
             <Label for="exampleUploadImage">Upload logo</Label>
                 <Input
                  id="exampleUploadImage"
                  bsSize="sm"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  />
              </FormGroup>
            </Form>
            <Button color='primary' onClick={handleAddCompany}>
             Submit
            </Button>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default Register;

