import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  FormGroup,
  Label,
  Collapse,
} from "reactstrap";
import { collection, addDoc, serverTimestamp } from "@firebase/firestore";
import {fetchFirebaseConfig} from "../../firebase";
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const checkboxNumber = {
  Members: 1,
  Roles: 2,
  Loans: 3,
  Savings: 4,
  Transactions: 5,
  Shares: 6,
  Accounting: 7,
  Reports: 8,
  Records: 9,
  Inquiries: 10,
  Notifications: 11,
  Deposits: 12,
  System: 13
};

const subCheckboxNumber = {
  Members:{
    "Register members": 1.1, 
    "manage members": 1.2
  },
  Roles:{
    "Manage Roles":1.1
  },  
  Loans: {
    "View Long Term Loans": 3.1,
    "View Loan payouts": 3.2,
    "View Loan transactions": 3.3,
    "View Loan categories": 3.4,
    "Manage Loans": 3.5,
    "View Short Term Loans": 3.6,
    "View Chap Chap Loans": 3.7,
    "View uploaded Loans":3.8
  },
  Savings: {
    "View savings Accounts": 4.1,
    "View savings transactions": 4.2,
    "View savings payouts": 4.3
  },
  Transactions: {
    "View transactions": 5.1,
    "View transaction categories": 5.2
  },
  Shares: {
    "View member shares": 6.1,
    "View shares transactions": 6.2,
    "Manage Shares": 6.3
  },
  Accounting: {
    "View charts of Account": 7.1,
    "View account journals": 7.2,
    "View balance sheet": 7.3,
    "View ledger summary": 7.4,
    "View profit & loss": 7.5,
    "View Trial balance": 7.6,
    "View Cash Flow": 7.7,
    "View Change In Equity":7.8
  },
  Reports:{
    "Generate reports": 8.1
  },
  Records: {
    "View Loan Records": 9.1,
    "View Savings Records": 9.2,
    "View Shares Records": 9.5,
    "View Deposits Records": 9.6,
    "View transaction Records": 9.3,
    "Upload Records": 9.4
  },
  Inquiries:{
    "View Inquiries": 10.1
  },
  Notifications: {
    "View Notifications": 11.1
  },
  Deposits: {
    "View member deposits": 12.1,
    "View deposits transactions": 12.2,
  },
  System: {
    "View system settings": 13.1,
  },
};

const AddRole = () => {
  const [roleName, setRoleName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    Members: { checked: false, collapse: false, subCheckboxes: { "Register members": false, "manage members": false } },
    Roles: { checked: false, collapse: false, subCheckboxes: { "Manage Roles": false } },
    Loans: { checked: false, collapse: false, subCheckboxes: { "View Long Term Loans": false, "View Loan payouts": false, "View Loan transactions": false, "View Loan categories": false, "Manage Loans": false,"View Short Term Loans": false,"View Chap Chap Loans": false,"View uploaded Loans":false } },
    Savings: { checked: false, collapse: false, subCheckboxes: { "View savings Accounts": false, "View savings transactions": false, "View savings payouts": false } },
    Transactions: { checked: false, collapse: false, subCheckboxes: { "View transactions": false, "View transaction categories": false } },
    Shares: { checked: false, collapse: false, subCheckboxes: { "View member shares": false, "View shares transactions": false , "Manage Shares": false } },
    Accounting: { checked: false, collapse: false, subCheckboxes: { "View charts of Account": false, "View account journals": false, "View balance sheet": false, "View ledger summary": false, "View profit & loss": false, "View Trial balance": false,  "View Cash Flow": false, "View Change In Equity":false } },
    Reports: { checked: false, collapse: false, subCheckboxes: { "Generate reports": false } },
    Records: { checked: false, collapse: false, subCheckboxes: { "View Loan Records": false, "View Savings Records": false,"View Shares Records": false,"View Deposits Records":false, "View transaction Records": false, "Upload Records": false } },
    Inquiries: { checked: false, collapse: false, subCheckboxes: { "View Inquiries": false } },
    Notifications: { checked: false, collapse: false, subCheckboxes: { "View Notifications": false } },
    Deposits: { checked: false, collapse: false, subCheckboxes: { "View member deposits": false, "View deposits transactions":false} },
    System: { checked: false, collapse: false, subCheckboxes: { "View system settings": false,} },
  });

  const history = useHistory();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedCheckboxes = {
      ...checkboxes,
      [name]: {
        ...checkboxes[name],
        checked,
        subCheckboxes: Object.keys(checkboxes[name].subCheckboxes).reduce((acc, subCheckboxName) => {
          acc[subCheckboxName] = checked;
          return acc;
        }, {})
      }
    };
    setCheckboxes(updatedCheckboxes);
  };

  const handleSubCheckboxChange = (checkboxName, subCheckboxName, checked) => {
    const updatedSubCheckboxes = {
      ...checkboxes[checkboxName].subCheckboxes,
      [subCheckboxName]: checked
    };
    const allSubCheckboxesUnchecked = Object.values(updatedSubCheckboxes).every((value) => !value);
    const updatedCheckboxes = {
      ...checkboxes,
      [checkboxName]: {
        ...checkboxes[checkboxName],
        checked: !allSubCheckboxesUnchecked,
        subCheckboxes: updatedSubCheckboxes
      }
    };
    setCheckboxes(updatedCheckboxes);
  };

  const handleAddRole = () => {
    setIsSubmitting(true);
  
    const permissions = [];
    for (const checkboxName in checkboxes) {
      if (checkboxes[checkboxName].checked) {
        // Extract the number associated with the checkbox
        const mainNumber = checkboxNumber[checkboxName];
        permissions.push(mainNumber);
  
        // Add subcheckbox numbers to permissions if selected
        for (const subCheckboxName in checkboxes[checkboxName].subCheckboxes) {
          if (checkboxes[checkboxName].subCheckboxes[subCheckboxName]) {
            // Extract the subcheckbox number associated with the checkbox
            const subNumber = subCheckboxNumber[checkboxName][subCheckboxName];
            permissions.push(subNumber);
          }
        }
      }
    }
  
    // Now you can create the document with roleName and permissions
    const newRole = {
      Role: roleName,
      acess: permissions,
      updated_At: serverTimestamp() // or use serverTimestamp
    };
  
    // Reference the permissions collection
    const { db } = fetchFirebaseConfig();
    const permissionsCollection = collection(db, 'permissions');
  
    // Add the new role document to the permissions collection
    addDoc(permissionsCollection, newRole)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  
    setIsSubmitting(false); 
  
  };
  

  return (
    <Card className="mx-4">
      <CardBody className="p-4">
        <Form>
          <h4>Roles & Permissions</h4>
          <p className="text-medium-emphasis">Add a new role</p>
          <div style={{ marginBottom: "15px" }} className="text-center d-flex align-items-center justify-content-center">
            <InputGroup className="mb-3">
              <InputGroupText><span style={{ fontSize: "13px" }}>Role Name</span></InputGroupText>
              <Input
                placeholder="Role name"
                autoComplete="name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                size="sm"
                type="text"
              />
            </InputGroup>
          </div>

          {Object.entries(checkboxes).map(([checkboxName, checkbox], index) => (
            (index % 3 === 0) && <Row key={`row-${index}`} className="mb-3">
              {[0, 1, 2].map((offset) => {
                  const currentCheckboxIndex = index + offset;
                  const [currentCheckboxName, currentCheckbox] = Object.entries(checkboxes)[currentCheckboxIndex] || [];
                  if (!currentCheckbox) return null;
                  return (
                      <Col sm={4} key={currentCheckboxName}>
                          <Card className="mb-3" style={{ maxWidth: '400px', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                              <CardBody>
                                  <Row className="mb-3">
                                      <Col sm={12}>
                                          <FormGroup check>
                                              <Label check>
                                                  <Input
                                                      type="checkbox"
                                                      id={currentCheckboxName}
                                                      name={currentCheckboxName}
                                                      checked={currentCheckbox.checked}
                                                      onChange={handleCheckboxChange}
                                                  />{" "}
                                                  {currentCheckboxName}
                                              </Label>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                                  <Collapse isOpen={currentCheckbox.checked}>
                                      {Object.entries(currentCheckbox.subCheckboxes).map(([subCheckboxName, subCheckboxChecked], subIndex) => (
                                          <Row key={subCheckboxName} className="mb-1">
                                              <Col sm={12}>
                                                  <FormGroup check>
                                                      <Label check>
                                                          <Input
                                                              type="checkbox"
                                                              id={`${currentCheckboxName}_${subIndex + 1}`}
                                                              name={subCheckboxName}
                                                              checked={subCheckboxChecked}
                                                              onChange={(e) => handleSubCheckboxChange(currentCheckboxName, subCheckboxName, e.target.checked)}           
                                                          />{" "}
                                                         <span style={{color:'#7f8280'}}>{subCheckboxName}</span>
                                                      </Label>
                                                  </FormGroup>
                                              </Col>
                                          </Row>
                                      ))}
                                  </Collapse>
                              </CardBody>
                          </Card>
                      </Col>
                  );
              })}
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
            <Button color="success" onClick={handleAddRole} disabled={isSubmitting || !roleName}>
             Create
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default AddRole;