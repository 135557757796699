import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import StatsCard from '../../components/StatsCard/statsCard';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';

const CompanyWidgets = ({ id }) => {
  const { db } = fetchFirebaseConfig();
  const [stakeholdersCount, setStakeholdersCount] = useState(0);
  const [availableShares, setCompanyAvailableShares] = useState(0);
  const [totalShares, setCompanyTotalShares] = useState(0);
  const [sharesValue, setSharesValue] = useState(0);
  const [subscribedShares, setSubscribedShares] = useState(0);

  useEffect(() => {
    const fetchStakeholdersCount = async () => {
      try {
        const companyDoc = doc(db, 'Companies', id);
        const companySnapshot = await getDoc(companyDoc);
  
        if (companySnapshot.exists()) {
          const companyData = companySnapshot.data();
          const companyCode = companyData.CompanyCode;
          const companyAvailabeShares = companyData.CompanySharesAvailable;
          const companyTotalShares = companyData.TotalShares;
          const companySharesValue = companyData.AmountPerShares;
          const subscribedShares = companyTotalShares - companyAvailabeShares
  
          console.log("Company Code:", companyCode);
  
          const usersCollection = collection(db, 'users');
          const q = query(usersCollection, where('company_id', '==', companyCode));
          const querySnapshot = await getDocs(q);
          
          const count = querySnapshot.docs.length; // Get count of documents
          console.log("Stakeholders Count:", count);
          setStakeholdersCount(count);
          setCompanyAvailableShares(companyAvailabeShares)
          setCompanyTotalShares(companyTotalShares)
          setSharesValue(companySharesValue)
          setSubscribedShares(subscribedShares)
  
        } else {
          console.error('Company not found');
        }
      } catch (error) {
        console.error('Error fetching stakeholders count:', error);
      }
    };
  
    fetchStakeholdersCount();
  }, [db, id]);
  

  return (
    <Row>
      <Col sm={12} md={4}>
        <StatsCard
          title="Total Stakeholders"
          value={<div style={{ fontWeight: 600 }}>{<CountUp end={stakeholdersCount} duration={2} separator="," />}</div>}
          icon={<i className="fa fa-group icon-color" aria-hidden="true"></i>} 
          color="#28d456"  // Example icon, replace with your desired icon
            // Show TSH label
        />
      </Col>
      <Col sm={12} md={4}>
        <StatsCard
          title="Total Funds"
          value={<div style={{ fontWeight: 600 }}>{<CountUp end={65000000} duration={2} separator="," />}</div>}
          icon={<i className="fa fa fa-money" aria-hidden="true"></i>} 
          color="#e3206b"// Example icon, replace with your desired icon
          showTSH={true}  // Show TSH label
        />
      </Col>
      <Col sm={12} md={4}>
        <StatsCard
          title="Total Shares"
          value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalShares} duration={2} separator="," />}</div>}
          icon={<i className="fa fa fa-share-alt" aria-hidden="true"></i>} 
          color="#4b3e75"  // Example icon, replace with your desired icon
        />
      </Col>
      <Col sm={12} md={4}>
        <StatsCard
          title="Available Shares"
          value={<div style={{ fontWeight: 600 }}>{<CountUp end={availableShares} duration={2} separator="," />}</div>}
          icon={<i className="fa fa fa-share-alt" aria-hidden="true"></i>} 
          color="#4b3e75"
        />
      </Col>
      <Col sm={12} md={4}>
        <StatsCard
          title="Share value"
          value={<div style={{ fontWeight: 600 }}>{<CountUp end={sharesValue} duration={2} separator="," />}</div>}
          icon={<i className="fa fa fa-tag" aria-hidden="true"></i>} 
          color="#e35f27"  // Example icon, replace with your desired icon
          showTSH={true}  // Show TSH label
        />
      </Col>
      <Col sm={12} md={4}>
        <StatsCard
          title="Subscribed Shares"
          value={<div style={{ fontWeight: 600 }}>{<CountUp end={subscribedShares} duration={2} separator="," />}</div>}
          icon={<i className="fa fa fa-share-alt" aria-hidden="true"></i>} 
          color="#4b3e75"  // Example icon, replace with your desired icon
        />
      </Col>
    </Row>
  );
}

export default CompanyWidgets;
