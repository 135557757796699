import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import s from './CompanyCard.module.scss';
import { Badge } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

const CompanyCard = ({
  title,
  value,
  shares,
  startDate,
  code,
  color,
  showTSH,
  showButtons,
  deleteButtonColor,
  editButtonColor,
  imageUrl,
  linkTo,
  editLink,
  Delete,
  className, // Define className here
  children, // Define children here
}) => {

  const history = useHistory();

  return (
    <section className={cx(s.widget, className)}>
      <div className={s.iconContainer}>
        {imageUrl && <img src={imageUrl} alt="Logo" />}
        <div className={s.titleContainer}>
          <Link to={linkTo} className={s.titleLink}>
            {title}
          </Link>
        </div>
      </div>
      <div className={s.valueContainer}>
        {showTSH && <span className={s.tshLabel}>TSH</span>}
        {value}
      </div>
      <div className={s.sharesContainer}>
        <Badge color={color} pill className={s.badge}>
          Available Shares: {shares}
        </Badge>
      </div>
      <div className={s.floatingCard}>
        <div className={s.dateContainer}>
          <div className={cx(s.dateLabel, s.createdOn)}>CREATED ON</div>
          <span className={s.startDate}>{startDate}</span>
        </div>
        <div className={s.dateContainer}>
          <div className={cx(s.dateLabel, s.enddate)}>CODE</div>
          <span className={s.endDate}>{code}</span>
        </div>
        {showButtons && (
          <div className={s.buttonsContainer}>
            <Link to={editLink}>
            <i
              className="fa fa-pencil-square-o"
              aria-hidden="true"
              style={{ color: editButtonColor }}
            ></i>
            </Link>
            <i className="fa fa-trash-o" aria-hidden="true" style={{ color: deleteButtonColor }}   onClick={Delete}
            ></i>
          </div>
        )}
      </div>
      <div className={s.content}>{children}</div>
    </section>
  );
};

CompanyCard.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
  shares: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  showTSH: PropTypes.bool,
  showButtons: PropTypes.bool,
  deleteButtonColor: PropTypes.string,
  editButtonColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  linkTo: PropTypes.string,
  editLink: PropTypes.string,
  Delete: PropTypes.string,
  code: PropTypes.string,
};

CompanyCard.defaultProps = {
  title: null,
  value: null,
  shares: 0,
  startDate: null,
  endDate: null,
  imageUrl: null,
  className: '',
  color: '#28d456',
  showTSH: false,
  showButtons: false,
  deleteButtonColor: '#e3716d',
  editButtonColor: '#3754a5',
  children: [],
  linkTo: '',
  editLink:'',
  Delete:'',
  code: '',
};

export default CompanyCard;
