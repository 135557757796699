import React, { useState, useEffect } from 'react';
// import { Tabs } from 'antd';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Breadcrumb } from 'antd';
import TransactionFormPayment from './transactionsFormPayment';
import {fetchFirebaseConfig} from '../../firebase';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import TransactionFormPayout from './transactionFormPayout';

const TransactionsPaymentTabs = () => {

  const { id } = useParams(); // Get the UID from the URL

  const [activeTab, setActiveTab] = useState('payments');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    payments: <TransactionFormPayment/>,
    payouts:<TransactionFormPayout />
  };
  const [name, setName] = useState("");
  const [loansData, setLoanData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loansDetails, setLoanDetails] = useState(null);

  const {db}=fetchFirebaseConfig();


  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'payments' || key === 'payouts') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };


  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/app/companies/company-view/${id}`}>View Stakeholders</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Payment & Requests Tab</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Transactions Panel</h5>
            <Widget> {/* Add margin-left for separation */}
              <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
              <NavItem>
                  <NavLink
                    className={activeTab === 'payments' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('payments')}
                    style={activeTab === 'payments' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                    <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Payments</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'payouts' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('payouts')}
                    style={activeTab === 'payouts' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                  <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Payouts & Disbursements</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="payments">
                  <Widget>
                    <div className="align-items-center">{tabContent.payments}</div>
                  </Widget>
                </TabPane>
                <TabPane tabId="payouts">
                  <Widget>
                    <div className="align-items-center" >{tabContent.payouts}</div>
                  </Widget>
                </TabPane>
              </TabContent>
            </Widget>
          </div>
      );
    };

export default TransactionsPaymentTabs;
