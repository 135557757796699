import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import CompanyCard from './companyCard';
import Widget from '../../components/Widget/Widget';
import { collection, getDocs, getDoc,  doc, deleteDoc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import { useHistory, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2'; // Import Swal library

const ViewCompanyWidgets = () => {
  const { db } = fetchFirebaseConfig();
  const [company, setCompany] = useState([]);
  const [userPosition, setUserPosition] = useState('');
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const companyData = collection(db, 'Companies');
        const snapshot = await getDocs(companyData);
        const companiesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCompany(companiesData);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  }, [db]);


  const fetchUserPosition = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      const userDocRef = doc(collection(db, 'users'), currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (!userDocSnapshot.exists()) {
        throw new Error('User document not found');
      }
      const userData = userDocSnapshot.data();
      const position = userData.position;
      setUserPosition(position);
    } catch (error) {
      console.error('Error fetching user position:', error);
    }
  };

  useEffect(() => {
    fetchUserPosition();
  }, [db]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuthenticated(true);
        fetchUserPosition();
      } else {
        setUserAuthenticated(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  

  const handleDelete = async (id) => {
    try {
      if (!id) {
        console.error('Document ID is empty or undefined.');
        return;
      }
  
      // Display the confirmation modal
      Swal.fire({
        icon: 'warning',
        title: 'Confirm Deletion',
        text: 'Are you sure you want to delete this Company?',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then(async (result) => {
        // Check if the confirm button is clicked
        if (result.isConfirmed) {
          // Delete the document from Firestore
          await deleteDoc(doc(collection(db, 'Companies'), id));
  
          // Display success message
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Company deleted successfully!',
            confirmButtonColor: '#28a745',
          });
  
          console.log('Entry deleted successfully.');
        }
      });
    } catch (error) {
      console.error('Error deleting document: ', error);
      // Display error message if deletion fails
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the entry.',
        confirmButtonColor: '#dc3545',
      });
    }
  };

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>View Companies</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">
        <i class="fa fa-building" aria-hidden="true" style={{ fontSize: '2rem' }}></i> Companies
      </h4>
      <Widget>
        <Row>
          {company.map((branch, index) => (
            <Col sm={12} md={4}>
              <CompanyCard
                title={branch.CompanyName}
                value={`${branch.AmountPerShares.toLocaleString()} /share`}
                shares={branch.CompanySharesAvailable}
                imageUrl={branch.CompanyLogo}
                color={branch.CompanySharesAvailable === 0 ? 'danger' : 'info'}
                startDate={renderDateRequested(branch.CompanyJoinedDate)}
                code={branch.company_id}
                showTSH={true}
                linkTo={`/app/companies/company-view/${branch.id}`}
                editLink={`/app/company/company-view/edit-company/${branch.id}`}
                Delete={() => handleDelete(branch.id)}
                showButtons={userPosition !== 'Stakeholder'} // Conditionally set showButtons based on user position
              />
            </Col>
          ))}
        </Row>
      </Widget>
    </div>
  );
};

export default ViewCompanyWidgets;
