import React, { useState, useEffect } from 'react';
// import { Tabs } from 'antd';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Row, Col, Form, FormGroup, Label, Input, Button, Breadcrumb, BreadcrumbItem,Badge } from 'reactstrap';
import SharesReceipts from './sharesReceipts';
import SharesDepositsUser from './sharesDepositUser';
import SharesFormPayment from './sharesFormPayment';
import SharesFormPayout from './sharesFormPayout';
import SharesPayoutUser from './sharesPayoutUser';
import {fetchFirebaseConfig} from '../../firebase';
import {
    doc,
    getDoc,
    updateDoc,
    addDoc,
    collection,
    serverTimestamp,
    getDocs,
    where,
    query,
    onSnapshot
  } from 'firebase/firestore';
  import CountUp from 'react-countup';
  import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

// const { TabPane } = Tabs;

const SharesPaymentTabs = () => {

  const { id } = useParams(); // Get the UID from the URL

  const [activeTab, setActiveTab] = useState('receipts');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    receipts: <SharesReceipts savingsId={id} refreshTable={refreshDepositsTable}/>,
    payments: <SharesFormPayment savingsId={id} />,
    payouts: <SharesFormPayout savingsId={id}/>,
    paymentsRecords: <SharesDepositsUser savingsId={id} refreshTable={refreshDepositsTable}/>,
    payoutsRecords: <SharesPayoutUser savingsId={id} refreshTable={refreshDepositsTable}/>
  };
  const [name, setName] = useState("");
  const [savingsData, setSavingsData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [savingsDetails, setSavingsDetails] = useState(null);

  const {db}=fetchFirebaseConfig();

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'paymentsRecords' || key === 'receipts'|| key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  useEffect(() => {
    const fetchSavingsData = async () => {
      try {
        const savingsRef = doc(db, 'Savings', id);
        const savingsSnapshot = await getDoc(savingsRef);
  
        if (savingsSnapshot.exists()) {
          const savingsData = savingsSnapshot.data();
          setSavingsData(savingsData);
  
          const userRef = savingsData.userSavings;
          const userSnapshot = await getDoc(userRef);
  
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);
  
            setSavingsData((prevLoanDetails) => ({
              ...prevLoanDetails,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userInterest: userData.interestRate,
              userid: userData.uid,
            }));
  
            // Apply onSnapshot to listen for real-time changes in savings data
            const savingsUnsubscribe = onSnapshot(savingsRef, (updatedSavingSnapshot) => {
              if (updatedSavingSnapshot.exists()) {
                const updatedSavingData = updatedSavingSnapshot.data();
                setSavingsDetails(updatedSavingData);
              }
            });
  
            // Apply onSnapshot to listen for real-time changes in user data
            const userUnsubscribe = onSnapshot(userRef, (updatedUserSnapshot) => {
              if (updatedUserSnapshot.exists()) {
                const updatedUserData = updatedUserSnapshot.data();
                setUserDetails(updatedUserData);
              }
            });
  
            // Clean up the listeners when the component unmounts
            return () => {
              savingsUnsubscribe();
              userUnsubscribe();
            };
          } else {
            setUserDetails(null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchSavingsData();
  }, [db, id]);
  


  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Shares Payments-Tab</BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mb-lg">{userDetails?.display_name}'s  Shares Panel</h5>
        {/* Profile Picture Widget */}
        <Widget>
            <div className="d-flex align-items-start justify-content-center flex-column" style={{ height: '100%' }}>
            <div style={{ borderBottom: `1px solid rgba(128, 128, 128, 0.03)`, width: '100%', marginBottom: '5px', paddingBottom: '5px', backgroundColor:'#fffff5' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div style={{ marginLeft: '10px',marginRight:'10px' }}>
                    {userDetails?.photo_url? (
                      <img src={userDetails?.photo_url} alt=" " style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                    ) : (
                      <i className="fa fa-user-circle" style={{ fontSize: '3.5em', color: '#c0c0c0' }}></i>
                    )}
                  </div>
                  <div>
                    <h6 className="mt-1" style={{fontWeight:'bold'}}>{userDetails?.display_name}</h6>
                    <span className="mt-1" style={{fontSize:"11px"}}>{userDetails?.refNo}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start" style={{paddingLeft:'15px'}}>
                <div className="mb-1"><span style={{fontSize:'12px',fontWeight: '601'}}>Total Shares Amount :&nbsp;</span><i className="fa fa-handshake-o" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; <CountUp start={0} end={savingsDetails?.Amount || 0} duration={2} separator="," /></div>
                <div className="mb-1"><span style={{fontSize:'12px',fontWeight: '601'}}>Number Of Shares :&nbsp;</span><CountUp start={0} end={savingsDetails?.shares || 0} duration={2} separator="," /></div>
                <div className="mb-4">
                  {savingsDetails?.status === 'active' ? (
                    <Badge color="success">Active</Badge>
                  ) : savingsDetails?.status === 'inactive' ? (
                    <Badge color="danger">Inactive</Badge>
                  ) : (
                    savingsDetails?.status
                  ) || ''}
                </div>
                <Link to={`/app/members/members-list/user-details/${userDetails?.uid}`}>
                <Button color="success" size='sm' outline> <i className="fa fa-user" aria-hidden="true"></i> Profile</Button>
              </Link>
              </div>
            </div>
          </Widget>

         <Widget> {/* Add margin-left for separation */}
            <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
              <NavItem>
                <NavLink
                  className={activeTab === 'receipts' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('receipts')}
                  style={activeTab === 'receipts' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Receipts & Payslips</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === 'payments' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('payments')}
                  style={activeTab === 'payments' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                  <span style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Payments</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === 'payouts' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('payouts')}
                  style={activeTab === 'payouts' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                <span style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Payouts</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === 'paymentsRecords' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('paymentsRecords')}
                  style={activeTab === 'paymentsRecords' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                <span style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Previous Payments</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === 'payoutsRecords' ? 'active green-tab' : ''}
                  onClick={() => handleTabChange('payoutsRecords')}
                  style={activeTab === 'payoutsRecords' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                >
                <span style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Previous Disbursements</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="receipts">
                <Widget>
                  <div className="align-items-center">{tabContent.receipts}</div>
                </Widget>
              </TabPane>
              <TabPane tabId="payments">
                <Widget>
                  <div className="align-items-center" >{tabContent.payments}</div>
                </Widget>
              </TabPane>
              <TabPane tabId="payouts">
                <Widget>
                  <div className="align-items-center">{tabContent.payouts}</div>
                </Widget>
              </TabPane>
              <TabPane tabId="paymentsRecords">
                <Widget>
                  <div className="align-items-center" >{tabContent.paymentsRecords}</div>
                </Widget>
              </TabPane>
              <TabPane tabId="payoutsRecords">
                <Widget>
                  <div className="align-items-center">{tabContent.payoutsRecords}</div>
                </Widget>
              </TabPane>
            </TabContent>
          </Widget>
      </div>
  );
};

export default SharesPaymentTabs;
