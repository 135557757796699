import React, { useState, useEffect } from 'react';
// import { Tabs } from 'antd';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Button, Breadcrumb, BreadcrumbItem,Badge,Collapse } from 'reactstrap';
import LoansReceipts from './loanReceipts';
import LoansFormPayment from './loanFormPayment';
import LoansPreviousTransactions from './loanPayoutUser';
import {fetchFirebaseConfig} from '../../firebase';
import {
    doc,
    getDoc,
    onSnapshot
  } from 'firebase/firestore';
  import CountUp from 'react-countup';
  import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

// const { TabPane } = Tabs;

const LoansPaymentTabs = () => {

  const { id } = useParams(); // Get the UID from the URL

  const [activeTab, setActiveTab] = useState('receipts');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    receipts: <LoansReceipts loanId={id} refreshTable={refreshDepositsTable}/>,
    payments: <LoansFormPayment loanId={id} />,
    payoutsRecords: <LoansPreviousTransactions loanId={id} refreshTable={refreshDepositsTable}/>
  };
  const [name, setName] = useState("");
  const [loansData, setLoanData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loansDetails, setLoanDetails] = useState(null);

  const {db}=fetchFirebaseConfig();

    // State to manage collapse
    const [collapseOpen, setCollapseOpen] = useState(false);

    // Function to toggle collapse state
    const toggleCollapse = () => setCollapseOpen(!collapseOpen);

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'payments' || key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const { db } = fetchFirebaseConfig();
        const loanRef = doc(db, 'Loans', id);
        const loanSnapshot = await getDoc(loanRef);

        if (loanSnapshot.exists()) {
          const loanData = loanSnapshot.data();
          setLoanDetails(loanData);

          const userRef = loanData.user;
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);

            setLoanDetails((prevLoanDetails) => ({
              ...prevLoanDetails,
              user: userData.display_name,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userInterest: userData.interestRate,
              userid: userData.uid,
            }));

            onSnapshot(loanRef, (updatedLoanSnapshot) => {
              if (updatedLoanSnapshot.exists()) {
                const updatedLoanData = updatedLoanSnapshot.data();
                setLoanDetails(updatedLoanData);
              }
            });
          } else {
            setUserDetails(null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchLoanDetails();
  }, [db, id]);

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Loans Payments-Tab</BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mb-lg">{userDetails?.display_name}'s  Loans Panel</h5>
        {/* Profile Picture Widget */}
        <Widget>
            <div className="d-flex align-items-start justify-content-center flex-column" style={{ height: '100%' }}>
              <div style={{ borderBottom: `1px solid rgba(128, 128, 128, 0.03)`, width: '100%', marginBottom: '5px', paddingBottom: '5px', backgroundColor:'#fffff5' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div style={{ marginLeft: '10px',marginRight:'10px' }}>
                    {userDetails?.photo_url? (
                      <img src={userDetails?.photo_url} alt=" " style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                    ) : (
                      <i className="fa fa-user-circle" style={{ fontSize: '3.5em', color: '#c0c0c0' }}></i>
                    )}
                  </div>
                  <div>
                    <h6 className="mt-1" style={{fontWeight:'bold'}}>{userDetails?.display_name}</h6>
                    <span className="mt-1" style={{fontSize:"11px"}}>{userDetails?.refNo}</span>
                  </div>
                </div>
              </div>
            <div className="d-flex flex-column align-items-start" style={{ paddingLeft: '15px' }}>
              <div className="mr-4"> <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Loan Granted :&nbsp;</span> <i className="fa fa-bank" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; <CountUp start={0} end={loansDetails?.loanAmount1 || 0} duration={2} separator="," /> </div> 
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601'}}>Paid Amount :&nbsp;</span> <i className="fa fa-money" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; <CountUp start={0} end={loansDetails?.amountPaid || 0} duration={2} separator="," /> </div> 
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Approval Status :&nbsp;</span> {loansDetails?.loanState === 'Approved' ? ( <Badge color="success">Approved</Badge> ) : loansDetails?.loanState === 'pending' ? ( <Badge color="info">Pending</Badge> ) : loansDetails?.loanState === 'Denied' ? ( <Badge color="danger">Denied</Badge> ) : ( loansDetails?.loanState ) || ''} </div>
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Date Requested :&nbsp;</span> <i className="fa fa-calendar" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; {renderDateRequested(loansDetails?.dateTaken || " ")}</div> 
                  </div> 
                  <Collapse isOpen={collapseOpen}>
                  <div className='mt-3'>
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Returning Amount :&nbsp;</span> <i className="fa fa-money" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; <CountUp start={0} end={loansDetails?.amountTobeReturned || 0} duration={2} separator="," /> </div> 
                  <div className="mr-4"> <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Amount Pending :&nbsp;</span> <i className="fa fa-money" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; <CountUp start={0} end={loansDetails?.amountRemaining || 0} duration={2} separator="," /> </div>
                  <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Payment Status :&nbsp;</span> {loansDetails?.loanStatus === 'Paid' ? ( <Badge color="success">Paid</Badge> ) : loansDetails?.loanStatus === 'Not paid' ? ( <Badge color="danger">Not Paid</Badge> ) : loansDetails?.loanStatus === 'Incomplete' ? ( <Badge color="info">Incomplete</Badge> ) : ( loansDetails?.loanStatus ) || ''} </div>
                    <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Approved Date :&nbsp;</span> <i className="fa fa-calendar" aria-hidden="true" style={{ color: '#c0c0c0' }}></i>&nbsp; {renderDateRequested(loansDetails?.dateApproved || " ")} </div> 
                    <Link to={`/app/members/members-list/user-details/${userDetails?.uid}`}> 
                    <Button color="success" size='sm' outline className="mt-4"> <i className="fa fa-user" aria-hidden="true"></i> Profile </Button> 
                    </Link>
                  </div>
                  </div> 
                </Collapse> {/* Button to toggle collapse */}
                <div style={{ marginTop: '0.5rem' }}>
                  <span onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                  <Button  onClick={toggleCollapse} style={{ marginTop: '1rem',fontSize:'8px',backgroundColor:'#dcdce0',border:'none' }} size='sm'>
                    {collapseOpen ? <span style={{fontSize:'10px'}}><i class="fa fa-minus" aria-hidden="true"></i>&nbsp; show less</span> :  <span style={{fontSize:'10px'}}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;show more</span> }
                  </Button>
                  </span>
                </div>
                </div> 
             </div>
            </Widget>

            <Widget> {/* Add margin-left for separation */}
              <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <NavItem>
                  <NavLink
                    className={activeTab === 'receipts' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('receipts')}
                    style={activeTab === 'receipts' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                  <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Receipts & Payslips</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'payments' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('payments')}
                    style={activeTab === 'payments' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                    <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Payments</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'payoutsRecords' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('payoutsRecords')}
                    style={activeTab === 'payoutsRecords' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                  <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Previous Payments</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="receipts">
                  <Widget>
                    <div className="align-items-center">{tabContent.receipts}</div>
                  </Widget>
                </TabPane>
                <TabPane tabId="payments">
                  <Widget>
                    <div className="align-items-center" >{tabContent.payments}</div>
                  </Widget>
                </TabPane>
                <TabPane tabId="payoutsRecords">
                  <Widget>
                    <div className="align-items-center">{tabContent.payoutsRecords}</div>
                  </Widget>
                </TabPane>
              </TabContent>
            </Widget>
          </div>
      );
    };

export default LoansPaymentTabs;
